import { defineStore } from "pinia";
import { notify } from "@kyvg/vue3-notification";

import { computed, ref, watch } from "vue";
import _ from "lodash";
import moment from "moment";
import { useAxios } from "./axios.ts";

export const useCopyTradingStore = defineStore("copyTrading", () => {
  /////////////////
  // ZEITANGABEN //
  /////////////////

  /**
   * Heutiges Datum
   * @type {moment.Moment}
   */
  const WEEK_CURRENT = moment();

  /**
   * Heute in einer Woche
   * @type {moment.Moment}
   */
  const WEEK_NEXT = moment().add(1, "week");

  /**
   * Start der nächsten Woche
   * @type {moment.Moment}
   */
  const WEEK_START_OF_NEXT = moment().add(1, "week").startOf("week");

  /**
   * Beginn dieses Monats
   * @type {moment.Moment}
   */
  const MONTH_START = moment().startOf("month");

  /////////////////
  // LADE-STATUS //
  /////////////////

  /*
   * Befindet sich das Copy-Trading im Lade-Zustand
   * @type {Ref<UnwrapRef<boolean>>}
   */
  const isLoading = ref(true);

  ///////////////
  // INSTANZEN //
  ///////////////

  // Alle geladenen Instanzen
  const instances = ref({});

  // Die ID der aktuell geladenen Instanz
  const instanceId = ref(null);

  // Die aktuelle Instanz
  const instance = computed(() => instances.value[instanceId.value] ?? null);

  // Ist wahr, wenn mehr als eine Instanz verfügbar ist
  const hasMoreThanOneInstance = computed(() => Object.keys(instances.value).length > 1);

  // Autobet-Status der aktuellen Instanz
  const autobet = computed(() => instances.value[instanceId.value]?.autobet ?? false);
  watch(autobet, (newValue) => updateAutobet(newValue));

  // Verfügbare Coache
  const coaches = ref({});

  // Wetten, der aktuellen Instanz, seit Anfang des Monats
  const betsThisMonth = computed(() =>
    _.filter(instances.value[instanceId.value]?.bets, (bet) => {
      return MONTH_START <= moment(bet.start);
    })
  );

  /**
   * Abgegebene Wetten, in der aktuellen Instanz, für diese Woche
   * @type {ComputedRef<string[]>}
   */
  const betsCurrentWeek = computed(() =>
    _.filter(instances.value[instanceId.value]?.bets, (bet) => {
      return WEEK_CURRENT >= moment(bet.start) && WEEK_CURRENT <= moment(bet.end);
    })
  );

  /**
   * Abgegebene Wetten, in der aktuellen Instanz, für die Folgewoche
   * @type {ComputedRef<string[]>}
   */
  const betsNextWeek = computed(() =>
    _.filter(instances.value[instanceId.value]?.bets, (bet) => {
      return WEEK_NEXT >= moment(bet.start) && WEEK_NEXT <= moment(bet.end);
    })
  );

  /**
   * Startet das Trading-System
   */
  async function populate() {
    isLoading.value = true;

    instanceId.value = null;
    instances.value = {};

    const { get } = useAxios();

    get("/api/copy-trading").then((res) => {
      // Instanzen laden
      res.data.data.instances.forEach((instance) => {
        patchInstance(instance.product_instance_id, instance);

        if (instanceId.value == null) {
          instanceId.value = instance.product_instance_id;
        }
      });

      coaches.value = res.data.data.coaches;
      isLoading.value = false;
    });
  }

  /**
   * Updated oder speichert die lokal gespeicherte Instanz
   * @param instanceId
   * @param instance
   */
  function patchInstance(instanceId, instance) {
    instances.value[instanceId] = instance;
  }

  function updateAutobet(isActive) {
    const { post } = useAxios();
    isLoading.value = true;
    post("/api/copy-trading/instance/" + instanceId.value + "/autobet", {
      active: isActive
    }).then(() => {
      // Ladestatus deaktivieren
      isLoading.value = false;
    });
  }

  function patchBet(bet) {
    // Prüfe, ob die Wette bereits existiert
    const betIndex = _.findIndex(instances.value[instanceId.value].bets, (e) => {
      return e.id === bet.id;
    });

    // Speichern oder updaten
    if (betIndex === -1) {
      instances.value[instanceId.value].bets.push(bet);
    } else {
      instances.value[instanceId.value].bets[betIndex] = bet;
    }
  }

  async function createBet(coachId) {
    const { post } = useAxios();

    // Befehl absenden
    const res = await post("/api/copy-trading/instance/" + instanceId.value + "/bet", {
      coachId: coachId,
      wager: 0
    });

    console.log("createBet", res.data.data);

    patchBet(res.data.data);
  }

  async function deleteBet(coachId) {
    // Befehl absenden
    const { post } = useAxios();

    await post("/api/copy-trading/instance/" + instanceId.value + "/bet/delete", {
      coachId: coachId
    }).then(async (res) => {
      const betId = res.data.id;

      const index = _.findIndex(instances.value[instanceId.value].bets, (test) => {
        return test?.id === betId;
      });

      console.log(betId, index, instances.value[instanceId.value].bets);

      if (index !== -1) instances.value[instanceId.value].bets.splice(index, 1);

      console.log(betId, index, instances.value[instanceId.value].bets);

      notify({
        title: "Einsatz gelöscht",
        type: "success",
        text: "Der Einsatz wurde erfolgreich gelöscht"
      });
    });
  }

  async function updateWager(coachId, newWager) {
    const { post } = useAxios();

    // Befehl absenden
    const res = await post("/api/copy-trading/instance/" + instanceId.value + "/bet", {
      coachId: coachId,
      wager: newWager
    });

    notify({
      title: "Copy-Trading",
      text: "Der Einsatz wurde erfolgreich gespeichert.",
      type: "success"
    });

    patchBet(res.data.data);
  }

  function previewWager(betId, newWager) {
    if (instances.value[instanceId.value].bets != null) {
      const betIndex = _.findIndex(instances.value[instanceId.value].bets, (bet) => {
        return bet.id === betId;
      });

      instances.value[instanceId.value].bets[betIndex].wager = newWager;
    }
  }

  return {
    isLoading,

    coaches,
    instances,
    instance,
    instanceId,
    hasMoreThanOneInstance,

    betsCurrentWeek,
    betsNextWeek,
    betsThisMonth,

    autobet,

    populate,
    createBet,
    deleteBet,
    updateWager,
    previewWager,

    WEEK_CURRENT,
    WEEK_NEXT,
    MONTH_START,
    WEEK_START_OF_NEXT
  };
});
