export enum Level {
  ERROR,
  WARNING,
  INFO,
  DEBUG
}

export function debugIf(shouldLog: boolean, message: string, ...args) {
  if (shouldLog) log(message, Level.DEBUG, false, ...args);
}

export function debug(message: string, ...args) {
  log(message, Level.DEBUG, false, ...args);
}

export function info(message: string, ...args) {
  log(message, Level.INFO, false, ...args);
}

export function warning(message: string, ...args) {
  log(message, Level.WARNING, false, ...args);
}
export function error(message: string, ...args) {
  log(message, Level.ERROR, false, ...args);
}

// @ts-ignore
function log(message: string, level: Level, hideSource = false, ...args) {
  const composedMessage = `${message}`;

  switch (level) {
    case Level.ERROR:
      if (args.length > 0) {
        console.error(composedMessage, ...args);
      } else {
        console.error(composedMessage);
      }
      return;
    case Level.WARNING:
      if (args.length > 0) {
        console.warn(composedMessage, ...args);
      } else {
        console.warn(composedMessage);
      }
      return;
    case Level.INFO:
      if (args.length > 0) {
        console.info(composedMessage, ...args);
      } else {
        console.info(composedMessage);
      }
      return;
    case Level.DEBUG:
      if (args.length > 0) {
        console.debug(composedMessage, ...args);
      } else {
        console.debug(composedMessage);
      }
      return;
  }
}
