<template>
  <HtMenuPopupTitle :icon="UserIcon" name="Trading-Account"></HtMenuPopupTitle>
  <div
    :class="[
      auth.userHasPermission('tradingPlatform.trading.accounts.details')
        ? 'grid-cols-2'
        : 'grid-cols-1',
      'grid divide-slate-200 dark:divide-slate-700 divide-x w-max'
    ]"
  >
    <div class="mt-2">
      <MegaMenuTitle>Account wählen</MegaMenuTitle>

      <!-- Kontowechsel möglich -->
      <template v-if="auth.userHasPermission('tradingPlatform.trading.accounts.change')">
        <p class="px-2 py-0 text-base dark:text-slate-300 w-72">
          Wähle das Konto, welches du zum Traden verwenden möchtest.
        </p>

        <TradingAccountSelector class="p-2" />

        <p class="px-2 py-2 text-xs text-slate-500 dark:text-slate-400 w-72">
          Hinweis: Deine offenen Positionen werden beim Kontowechsel nicht automatisch geschlossen.
        </p>
      </template>

      <!-- Kontowechsel nicht möglich -->
      <template v-else>
        <p class="px-2 py-2 w-72 text-base">
          Öffne das "Account-Informationen" Fenster, um weitere Infos über den aktiven Account zu
          erhalten.
        </p>
      </template>
    </div>

    <div
      v-if="auth.userHasPermission('tradingPlatform.trading.accounts.details')"
      class="flex flex-col w-80 pl-6 pr-2 mt-2"
    >
      <MegaMenuTitle class="">Accountinformationen</MegaMenuTitle>

      <div
        v-if="accountBalances.current !== null"
        class="relative grow flex flex-col max-h-60 px-2"
      >
        <HtMenuPopupTradingInformationEntry
          name="Balance"
          :value="formatDollars(accountBalances.current.equityTotal, true)"
          description="?"
        />

        <HtMenuPopupTradingInformationEntry
          name="Buying-Power"
          :value="formatDollars(accountBalances.currentBuyingPower, true)"
          description="?"
        />
        <HtMenuPopupTradingInformationEntry
          name="Daily Loss Limit"
          :value="formatDollars(accounts.current?.TradeSecure?.settings?.daily_loss_limit, true)"
          description=""
        />
        <HtMenuPopupTradingInformationEntry
          name="Open PnL"
          :value="formatDollars(accountBalances.current.openPL, true)"
          :value-class-names="getColorForNumberGreenOrRed(accountBalances.current.openPL)"
          description=""
        />
        <HtMenuPopupTradingInformationEntry
          name="Closed PnL"
          :value="formatDollars(accountBalances.current.closePL, true)"
          :value-class-names="getColorForNumberGreenOrRed(accountBalances.current.closePL)"
          description=""
        />
        <div class="text-xs text-slate-500 mt-1 px-0">Aktualisiert alle 30 Sekunden.</div>
      </div>
      <div v-else>
        <ListLoader class="px-2 w-full" width="250" />
      </div>
    </div>
  </div>
</template>

<script>
import MegaMenuTitle from "../../ui/HtMenuPopupSectionTitle.vue";
import TradingAccountSelector from "../../../views/workspace/apps/Trading/AccountSelector.vue";
import { useEtnaAccountBalances } from "../../../stores/etna-account-balances.ts";
import { ListLoader } from "vue-content-loader";
import { useAuth } from "../../../stores/auth.ts";
import HtMenuPopupTitle from "../../ui/HtMenuPopupTitle.vue";
import HtMenuPopupTradingInformationEntry from "./HtMenuPopupTradingInformationEntry.vue";
import {
  formatDollars,
  getColorForNumberGreenOrRed
} from "../../../shared/composables/formatter.ts";
import { UserIcon } from "@heroicons/vue/24/outline";
import { useEtnaAccounts } from "../../../stores/etna-accounts.ts";

export default {
  components: {
    HtMenuPopupTradingInformationEntry,
    HtMenuPopupTitle,
    ListLoader,
    TradingAccountSelector,
    MegaMenuTitle
  },

  setup() {
    const accountBalances = useEtnaAccountBalances();
    const accounts = useEtnaAccounts();
    const auth = useAuth();

    return {
      accountBalances,
      auth,
      accounts
    };
  },
  methods: { getColorForNumberGreenOrRed, UserIcon, formatDollars }
};
</script>

<style scoped></style>
