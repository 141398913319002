import { AxiosInstance, AxiosResponse } from "axios";

import { ISecurityService, RawSecurity } from "../types/Trading.ts";

export class SecurityService implements ISecurityService {
  constructor(private readonly httpClient: AxiosInstance) {}

  public async getSecurityInfo(symbol: string): Promise<AxiosResponse<RawSecurity>> {
    const endpoint = `equities/${symbol}`;
    return this.httpClient.get(endpoint);
  }
}
