import { defineStore } from "pinia";
import { ref } from "vue";
import { useAxios } from "./axios.ts";
import { Module, useStatus } from "./status.ts";

export const useSettings = defineStore(
  "settings",
  () => {
    /** Status-Store */
    const status = useStatus();

    /** Einstellungen, die mit dem Server synchronisiert werden */
    const synchronizedSettings = ref({});

    /** Einstellungen, die nur lokal gespeichert werden */
    const localSettings = ref({
      /** Zeigt die Websocket-Nachrichten von TradingView an */
      logTradingViewWebsocketMessages: false,

      /** Gibt an, ob die Nachrichten vom Worker geloggt werden sollen */
      logWorkerMessages: false,

      /** Aktiviert den Debug-Modus für Pusher */
      enablePusherDebugMode: false,

      /** Aktiviert den Debug-Modus für TradingView */
      enableTradingViewDebugMode: false,

      /** Aktiviert das Logging von Permission-Checks */
      logPermissionChecks: false,

      /** Aktiviert das Logging von Chart-Trading-Events */
      logChartTradingEvents: false
    });

    async function loadSettings() {
      // Log-Ausgabe
      console.log("[Settings] 🔧️ Lade Einstellungen vom Server…");

      // Status-Flag setzen
      status.setLoading(Module.userSettings);

      // Per Axios vom Server laden
      const { get } = useAxios();
      await get("/api/trading/settings")
        .then((response) => {
          synchronizedSettings.value = response.data;

          // Status-Flag setzen
          status.setReady(Module.userSettings);

          // Log-Ausgabe
          console.log("[Settings] 🔧️ Einstellungen geladen");
        })
        .catch((error) => {
          // Status-Flag setzen
          status.setError(Module.userSettings, error.message);

          // Log-Ausgabe
          console.warn("[Settings] 🔧️ Fehler aufgetreten", error);
        });
    }

    function set(key: string, value: string) {
      // Log-Ausgabe
      console.log(
        "[Settings] 🔧️ Veränderung der Einstellung speichern (" + key + " = " + value + ")"
      );

      // Änderung lokal speichern
      synchronizedSettings.value[key] = value;

      // Änderung zum Server übermitteln
      const { post } = useAxios();
      post("/api/trading/settings", {
        key: key,
        value: value
      })
        .then(() => {
          console.log(
            "[Settings] 🔧️ Veränderung der Einstellung " + key + " wurde serverseitig gespeichert."
          );
        })
        .catch(() => {
          console.warn(
            "[Settings] 🔧️ Veränderung der Einstellung " +
              key +
              " konnte nicht serverseitig gespeichert werden."
          );
        });
    }

    // Einstellung abfragen
    function get(key: string) {
      return synchronizedSettings.value[key] ?? null;
    }

    return {
      loadSettings,
      user: synchronizedSettings,
      synchronized: synchronizedSettings,
      local: localSettings,
      set,
      get
    };
  },
  { persist: true }
);
