import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { uniq } from "lodash";
import { useEtnaTrading } from "./etna-trading.ts";
import { OrderService } from "./../shared/services/OrderService";
import { createOrder } from "./../shared/factories/Trading/OrderFactory.ts";
import { useStockdata } from "./stockdata";
import emitter from "./../shared/services/EventService";
import { showNotificationForOrder } from "./../shared/services/OrderNotificationService";
import { Order, RawOrder } from "../shared/types/Trading.ts";
import { Module, useStatus } from "./status.ts";

export const useEtnaOrders = defineStore("etna-orders", () => {
  const trading = useEtnaTrading();
  const stockData = useStockdata();

  /** Globaler App-Status */
  const status = useStatus();

  // Alle lokal geladenen Orders
  const orders = ref<Record<string, Order>>({});
  const isStoreInit = ref(false);
  const uniqOrderSymbols = computed<string[]>(() =>
    uniq<string>(Object.values(orders.value).map(({ symbol }) => symbol))
  );

  watch(uniqOrderSymbols, (newState, oldState) => {
    const newSub = newState.filter((x) => !oldState.includes(x));
    const newUnsub = oldState.filter((x) => !newState.includes(x));

    newSub.forEach((symbol) => {
      stockData.subscribe({
        ticker: symbol, // Current ticker of window
        fields: ["realtime"], // Fields to subscribe
        source: "trading.orders" // UUID of window to make subscription unique
      });
    });

    newUnsub.forEach((symbol) => {
      stockData.unsubscribe({
        ticker: symbol, // Current ticker of window
        fields: ["realtime"], // Fields to subscribe
        source: "trading.orders" // UUID of window to make subscription unique
      });
    });
  });

  /**
   * Funktion die beim Start aufgerufen wird, um alle Informationen zu sammeln und im lokalen Store zu speichern
   * @param {number} accountId ID des abzufragenden Accounts
   * @returns {Promise<void>}
   */
  async function boot(accountId: number): Promise<void> {
    // Order vom Server laden
    await loadOrders(accountId);
    // Websocket abonnieren
    await trading.subscribeTo("Order", accountId);
    isStoreInit.value = true;
  }

  /**
   * Funktion die beim Beenden aufgerufen wird, um Subscriptions zu beenden und den lokalen Store zu leeren
   * @param {number} accountId ID des zu bereinigen Accounts
   * @returns {Promise<void>}
   */
  async function shutdown(accountId: number): Promise<void> {
    // Websocket de-abonnieren
    await trading.unsubscribeFrom("Order", accountId);

    // Lokale Orders löschen
    orders.value = {};
  }

  function handleMessage(rawOrder: RawOrder): void {
    // Deep parse erzwingen, damit auch die Legs der RawOrder geparst werden
    if ((rawOrder.Legs as unknown as string) !== "") {
      rawOrder.Legs = JSON.parse(rawOrder.Legs as unknown as string) as RawOrder[];
    }

    patchOrder(createOrder(rawOrder));
  }

  /**
   * Lädt alle Orders des Accounts vom Server
   */
  const loadOrders = async (accountId: number): Promise<void> => {
    // Status-Flag setzen
    status.setLoading(Module.tradingOrders);

    const orderService = new OrderService(trading.getAxios());

    try {
      const {
        data: { Result }
      } = await orderService.getOrders(accountId);

      Result.forEach((rawOrder) => {
        patchOrder(createOrder(rawOrder), false);
      });

      // Status-Flag setzen
      status.setReady(Module.tradingOrders);
    } catch (e) {
      console.error(e, "while executing loadOrders");

      // Status-Flag setzen
      status.setError(Module.tradingOrders, e.message);
    }
  };

  /**
   * Updated oder speichert die lokal gespeicherte Order
   * @param order
   */
  function patchOrder(order: Order, showNotification = true): void {
    const storeOrderId = order.id;
    const existingOrder = orders.value[storeOrderId];

    // Emit the order patch event
    emitter.emit(`trading.order.updated`, order);

    // Send order to TradingNotificator
    if (showNotification) {
      showNotificationForOrder(order);
    }

    // The check below is necessary to display the latest created order as the very first one
    if (existingOrder) orders.value[storeOrderId] = order;
    else orders.value = { [storeOrderId]: order, ...orders.value };

    // Add legs to the store to be able to display them in the order details
    if (order.legs !== undefined) {
      order.legs.forEach((leg) => {
        patchOrder(leg, showNotification);
      });
    }
  }

  return {
    boot,
    shutdown,
    handleMessage,
    uniqOrderSymbols,
    orders,
    isStoreInit
  };
});
