<template>
  <form class="inline-flex items-center">
    <input
      :id="inputid != null ? inputid : 'checkbox_' + uuid"
      :checked="modelValue"
      class="focus:ring-blue-500 h-4 w-4 text-blue-600 ring-offset-transparent dark:ring-offset-slate-800 dark:bg-slate-600 dark:border-slate-500 checked:dark:bg-blue-600 checked:dark:border-blue-700 border-slate-300 rounded"
      type="checkbox"
      :disabled="disabled"
      @click="handleClick"
    />

    <label v-if="!hideLabel" :for="inputid != null ? inputid : 'checkbox_' + uuid" class="pl-2">
      <slot />
    </label>
  </form>
</template>

<script>
import { generateUuid } from "./../../shared/composables/uuid";

export default {
  name: "HtCheckbox",

  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    inputid: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ["update:modelValue"],

  data() {
    return {
      uuid: null
    };
  },

  created() {
    this.uuid = generateUuid();
  },

  methods: {
    handleClick(event) {
      this.$emit("update:modelValue", event.target.checked);
    }
  }
};
</script>

<style scoped></style>
