<template>
  <div
    v-if="!canLoadJs"
    class="absolute z-50 top-0 left-0 w-screen h-screen bg-slate-800 flex items-center justify-center"
  >
    <section
      class="bg-white text-center border border-slate-700 flex flex-col gap-4 w-96 p-8 rounded-md shadow"
    >
      <div class="text-4xl font-medium">Werbeblocker gefunden</div>
      <div class="text-base">
        Unser System hat festgestellt, dass in Ihrem Browser ein Werbeblocker / Ad-Blocker aktiviert
        ist. Da dieser die Webseite fortlaufend nach Werbung durchsucht wird die Leistung der
        Plattform signifikant gemindert.
      </div>
      <div>Aus diesem Grund ist es notwendig, dass Sie den Werbeblocker deaktivieren.</div>
      <div class="text-base">
        <b>Hinweis:</b>
        Auf der Plattform wird keine Werbung angezeigt.
      </div>
      <div
        class="flex flex-row gap-2 text-slate-700 text-center text-sm items-center justify-center"
      >
        <div class="flex flex-col gap-1 items-center">
          <img
            width="48"
            height="48"
            alt="Adblock Plus"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgZmlsbD0ibm9uZSI+CiAgPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzc1MzhfMjY3MTU4KSI+CiAgICA8cGF0aCBmaWxsPSIjZmZmIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS13aWR0aD0iLjA0IiBkPSJtMTUuNjQgNDQuNDMtMTItMTEuOS0uMDctMTYuODkgMTEuOS0xMiAxNi44OS0uMDcgMTIgMTEuOS4wNyAxNi44OS0xMS45IDEyLTE2Ljg5LjA3WiIvPgogICAgPHBhdGggZmlsbD0icmVkIiBkPSJNMTYuNTQgNDIuMzIgNS42NyAzMS41NWwtLjA3LTE1LjNMMTYuMzggNS4zNmwxNS4zLS4wNyAxMC44NyAxMC43OC4wNyAxNS4zLTEwLjc3IDEwLjg4LTE1LjMxLjA2WiIvPgogICAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE1LjYyIDI5LjA5SDExLjRMMTAuNzIgMzJIOGwzLjg5LTE2aDMuMjNMMTkgMzJoLTIuNzJsLS42Ni0yLjkxWm0tMy41Ni0yLjk3aDIuODhsLTEuNDMtNi4zNy0xLjQ1IDYuMzdabTEyLjUzLTMuOTNjLjQzIDAgLjc1LS4xNC45Ny0uNDIuMjItLjI5LjM0LS43MS4zNC0xLjI3IDAtLjU1LS4xMi0uOTctLjM0LTEuMjYtLjIyLS4yOS0uNTQtLjQzLS45Ny0uNDNoLTEuNXYzLjM4aDEuNVptLjEgN2MuNTQgMCAuOTUtLjE3IDEuMjItLjUyLjI4LS4zNS40MS0uODguNDEtMS41OSAwLS43LS4xMy0xLjIxLS40LTEuNTYtLjI4LS4zNC0uNjktLjUyLTEuMjMtLjUyaC0xLjZ2NC4yaDEuNlptMi41MS01Ljc1YTIuNyAyLjcgMCAwIDEgMS4zNSAxLjQyYy4zMi43LjQ4IDEuNTUuNDggMi41NSAwIDEuNTUtLjM0IDIuNy0xLjAzIDMuNDUtLjY4Ljc2LTEuNzIgMS4xNC0zLjEyIDEuMTRoLTQuNVYxNmg0LjA3YzEuNDYgMCAyLjUyLjM0IDMuMTcgMSAuNjYuNjguOTggMS43NS45OCAzLjIzIDAgLjc4LS4xMiAxLjQ0LS4zNiAyLS4yNC41NC0uNTguOTQtMS4wNCAxLjJaTTMxLjM2IDE2aDQuNWEzLjcgMy43IDAgMCAxIDMuMDcgMS4zNmMuNzEuOSAxLjA3IDIuMTkgMS4wNyAzLjg2IDAgMS42OC0uMzYgMi45Ny0xLjA3IDMuODdhMy43IDMuNyAwIDAgMS0zLjA4IDEuMzZoLTEuNzhWMzJoLTIuNzFWMTZabTIuNyAyLjk5djQuNDdoMS41Yy41MyAwIC45My0uMiAxLjIyLS41OS4yOS0uMzguNDMtLjk0LjQzLTEuNjUgMC0uNzItLjE0LTEuMjctLjQzLTEuNjUtLjI5LS4zOS0uNy0uNTgtMS4yMi0uNThoLTEuNVoiLz4KICA8L2c+CiAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImNsaXAwXzc1MzhfMjY3MTU4Ij4KICAgICAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGg0OHY0OEgweiIvPgogICAgPC9jbGlwUGF0aD4KICA8L2RlZnM+Cjwvc3ZnPgo="
          />
          <span>Adblock Plus</span>
        </div>
        <div class="flex flex-col gap-1 items-center">
          <img
            width="48"
            height="48"
            alt="AdGuard"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OSIgaGVpZ2h0PSI0OCIgZmlsbD0ibm9uZSI+CiAgPHBhdGggZmlsbD0iIzY4QkM3MSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMjQuNSAzLjVjLTYuMjUgMC0xMy44IDEuNDctMjAgNC43IDAgNy0uMDkgMjQuNCAyMCAzNi4zIDIwLjA5LTExLjkgMjAtMjkuMyAyMC0zNi4zLTYuMi0zLjIzLTEzLjc1LTQuNy0yMC00LjdaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz4KICA8cGF0aCBmaWxsPSIjNjdCMjc5IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNC40OCA0NC40OUM0LjQgMzIuNTkgNC41IDE1LjE5IDQuNSA4LjJjNi4yLTMuMjQgMTMuNzMtNC43IDE5Ljk4LTQuN3Y0MC45OFoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0ibTIzLjc3IDMwLjg2IDEyLjEtMTYuM2MtLjktLjcxLTEuNjctLjIxLTIuMS4xOGgtLjAxTDIzLjY3IDI1LjIzbC0zLjgtNC41N2MtMS44LTIuMS00LjI3LS41LTQuODUtLjA4bDguNzUgMTAuMjhaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+"
          />
          <span>AdGuard</span>
        </div>
        <div class="flex flex-col gap-1 items-center">
          <img
            width="48"
            height="48"
            alt="AdBlock"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgZmlsbD0ibm9uZSI+CiAgPHBhdGggZmlsbD0iI0Y0MEQxMiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJtMTQuODQgNC44OC05Ljk2IDkuOTZBMyAzIDAgMCAwIDQgMTYuOTZ2MTQuMDhhMyAzIDAgMCAwIC44OCAyLjEybDkuOTYgOS45NmEzIDMgMCAwIDAgMi4xMi44OGgxNC4wOGEzIDMgMCAwIDAgMi4xMi0uODhsOS45Ni05Ljk2YTMgMyAwIDAgMCAuODgtMi4xMlYxNi45NmEzIDMgMCAwIDAtLjg4LTIuMTJsLTkuOTYtOS45NkEzIDMgMCAwIDAgMzEuMDQgNEgxNi45NmEzIDMgMCAwIDAtMi4xMi44OFoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIzLjUyIDM4LjVjNi42MyAwIDguMi02LjA4IDguMi02LjA4bDMuOC0xMC45M3MuMzUtMS4xMy4yOS0xLjE0Yy0zLjU1LS42Ny00LjMgMS44My00LjMgMS44M3MtMS4zNiAzLjU0LTEuNTcgMy41NGMtLjIxIDAtLjI0LS4yNy0uMjQtLjI3di0xMi43cy4wNy0xLjY0LTEuNjEtMS42NGMtMS42OSAwLTEuNiAxLjY1LTEuNiAxLjY1djkuMzhzLjAyLjM4LS4zLjM4Yy0uMjcgMC0uMjYtLjM3LS4yNi0uMzd2LTExLjNTMjYuMDMgOSAyNC4zMyA5Yy0xLjcgMC0xLjYgMS44Ni0xLjYgMS44NkwyMi43IDIycy4wMi4zNS0uMjYuMzVjLS4yNiAwLS4yNS0uMzQtLjI1LS4zNHYtOS4zNnMuMS0xLjktMS42NC0xLjljLTEuNyAwLTEuNiAxLjk0LTEuNiAxLjk0bC0uMDIgOS44N3MuMDMuMy0uMjQuM2MtLjI4IDAtLjI4LS4zLS4yOC0uM2wtLjAxLTUuOTdzLjAxLTEuNjUtMS40Mi0xLjY1Yy0xLjUyIDAtMS40OSAxLjY1LTEuNDkgMS42NVYyOS41Yy4wMSAwLS4zOCA5IDguMDIgOVoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4="
          />
          <span>AdBlock</span>
        </div>
        <div class="flex flex-col gap-1 items-center">
          <img
            width="48"
            height="48"
            alt="uBlock Origin"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgZmlsbD0ibm9uZSI+CiAgPHBhdGggZmlsbD0ibWFyb29uIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIuNSIgZD0iTTQxLjUgOWMwIDI1IDAgMjUtMTcuNSAzNUM2LjUgMzQgNi41IDM0IDYuNSA5IDE0IDkgMTYuNSA5IDI0IDRjNy41IDUgMTAgNSAxNy41IDVoMFoiLz4KICA8cGF0aCBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCAyMi43OWEzLjc1IDMuNzUgMCAxIDAgMC0uMDd2LjA3Wm0wLTUuMDRhNi4yNSA2LjI1IDAgMSAxLS43IDkuMzlDMjIuNTMgMjguNCAyMS4xMiAyOSAxOSAyOWMtMy40NCAwLTUtMS41Ni01LTV2LTcuNWgyLjVWMjRjMCAyLjE5LjMxIDIuNSAyLjUgMi41czIuNS0uMzEgMi41LTIuNXYtNy41SDI0djEuMjVaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+"
          />
          <span>uBlock Origin</span>
        </div>
      </div>
      <ol class="flex flex-col gap-2 text-base">
        <li>
          1. Klicken Sie auf das
          <b>AdBlock-Symbol</b>
          in der
          <b>oberen rechten Ecke</b>
          deines Browsers
        </li>
        <li>
          2. Klicken Sie auf den
          <b>farbigen Regler</b>
          , der Ihnen anzeigt, dass Werbung auf tradesecure.app geblockt wird
        </li>
        <li>
          3. Aktualisieren Sie die Seite mit einem Klick auf
          <b>Aktualisieren</b>
          oder über Ihren Browser
        </li>
      </ol>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const canLoadJs = ref(true);

const urlToTest = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";

fetch(new Request(urlToTest)).catch(() => (canLoadJs.value = false));
</script>
