<template>
  <AbstractDialog ref="abstractDialog" :show-submit="true" submit-text="Layout erzeugen">
    <template #title>Neues Layout erzeugen</template>
    <template #content>
      <p class="mb-2">Bitte gib den Namen für dein neues Layout an:</p>
      <input
        v-model="name"
        class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full text-base border-slate-300 rounded-md"
        placeholder="Mein Layout"
        required
        type="text"
      />
    </template>
  </AbstractDialog>
</template>

<script>
import AbstractDialog from "../../AbstractDialog.vue";

export default {
  components: {
    AbstractDialog
  },

  data() {
    return {
      name
    };
  }
};
</script>
