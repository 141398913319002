<template>
  <div class="w-72 relative">
    <Listbox v-model="tradingStore.accountId">
      <div class="relative mt-1">
        <ListboxButton
          class="relative h-10 text-base w-full cursor-default rounded-md border border-slate-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
        >
          <!-- Laden -->
          <div v-if="tradingStore.isLoading" class="truncate inline-flex gap-2">
            <SpinningLoader class="h-4 w-4 m-auto" />
            <span>Lädt…</span>
          </div>

          <!-- Keine Account -->
          <div
            v-else-if="!tradingStore.isEnabled || tradingStore.account == null"
            class="truncate inline-flex gap-2"
          >
            <NoSymbolIcon class="h-4 w-4 text-slate-400 m-auto" aria-hidden="true" />
            <span>Kein Trading-Account verfügbar</span>
          </div>

          <!-- Aktiver Account -->
          <div v-else class="block truncate">
            aktiver Account:
            <span class="font-medium">{{ tradingStore.account.Alias ?? "Standard" }}</span>
          </div>

          <!-- Dropdown-Icon -->
          <span
            v-if="tradingStore.isEnabled"
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <ChevronDownIcon class="h-5 w-5 text-slate-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            v-if="tradingStore.isEnabled"
            class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base"
          >
            <ListboxOption
              v-for="account in accountsStore.accounts"
              v-slot="{ active, selected }"
              :key="account.Id"
              :value="account.Id"
              as="template"
            >
              <li
                :class="[
                  active ? 'bg-blue-100 text-blue-900' : 'text-slate-900',
                  'relative cursor-default select-none py-2 pl-10 pr-4'
                ]"
              >
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">
                  {{ account.Alias ?? "Standard" }}
                  <br />
                  <span class="text-sm">
                    {{ account.ClearingAccount }}
                  </span>
                </span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script>
import { useEtnaTrading } from "../../../../stores/etna-trading.ts";
import { useEtnaAccounts } from "../../../../stores/etna-accounts.ts";

import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";

import { CheckIcon, ChevronDownIcon, NoSymbolIcon } from "@heroicons/vue/24/outline";
import SpinningLoader from "./../../../../components/ui/HtSpinningLoader.vue";

export default {
  name: "TradingAccountSelector",

  components: {
    SpinningLoader,

    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,

    CheckIcon,
    ChevronDownIcon,
    NoSymbolIcon
  },

  setup() {
    const tradingStore = useEtnaTrading();
    const accountsStore = useEtnaAccounts();

    return {
      tradingStore,
      accountsStore
    };
  }
};
</script>
