import mitt from "mitt";
import { isDebug } from "./../composables/debug";

const emitter = mitt();

if (isDebug) {
  emitter.on("*", (type, e) => {
    //  Don't log Stockdata-related events
    const typeString = String(type);
    if (typeString.includes("stockdata") || typeString.includes("plOpen")) return;

    // Log-Output for Event
    console.debug(`[Emitter] 🔔 Event ${String(type)}`, e);
  });
}

export default emitter;
