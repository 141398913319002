import {
  PolygonSnapshotPath,
  PolygonSnapshotTickerExtendedWithLocalCalculation,
  PolygonSymbol
} from "./Polygon.ts";
import { ScannerType } from "./Scanner.ts";

export enum StockdataWorkerMethod {
  AUTH_CHANGE,
  SCREENER_CHANGE_ORDER,
  SCREENER_CHANGE_FILTER,
  SNAPSHOT_UPDATE,
  SUBSCRIBE,
  UNSUBSCRIBE,
  UNSUBSCRIBE_BY_SOURCE,
  SCANNER_RESET_COUNTER
}

export enum StockdataWorkerEvent {
  SCANNER_UPDATED,
  SCANNER_COUNTER_RESET_DONE,
  SNAPSHOT_UPDATED,
  SCREENER_UPDATED,
  SNAPSHOT_READY,
  STOCKDATA_REALTIME_FMV_UNSUBSCRIBED
}

export type StockdataWorkerMethodCall<T = any> = {
  method: StockdataWorkerMethod;
  payload: T;
};

export type StockdataWorkerEventResponse<T = any> = {
  event: StockdataWorkerEvent;
  payload: T;
};

export type StockdataWorkerScannerResults = {
  [key in ScannerType]: PolygonSnapshotTickerExtendedWithLocalCalculation[];
};
export type StockdataWorkerScreenerSettings = {
  order: any;
  filter: any;
};
export type StockdataWorkerSubscriptionList = {
  [key: PolygonSymbol]: {
    fieldList: PolygonSnapshotPath[];
    sources: { [key in PolygonSnapshotPath]: string[] };
  };
};
