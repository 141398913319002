<template>
  <div :class="['dark:bg-black bg-slate-200', flex, size]">
    <!-- Logo -->
    <div :class="['mt-2 flex item-center justify-center', padding]">
      <img
        v-if="!isMobile"
        alt="TradeSecure Logo"
        class="max-w-12 max-h-12"
        src="/images/tradesecure_icon.svg"
      />
    </div>

    <!-- Menüpunkte -->
    <nav :class="['grow gap-2', flex, padding]">
      <!-- Login -->
      <HtMenuEntry
        v-if="!auth.isAuthenticated()"
        :visibility="PlatformVisibility.MOBILE_AND_DESKTOP"
        :icon="KeyIcon"
        :to="{ name: 'login' }"
        name="Login"
      ></HtMenuEntry>

      <HtMenuEntry
        v-if="auth.userHasPermission('tradingPlatform.access')"
        :visibility="PlatformVisibility.DESKTOP_ONLY"
        :icon="SquaresPlusIcon"
        :to="{ name: 'workspace' }"
        name="Öffnen"
      >
        <HtMenuPopupOpenApp />
      </HtMenuEntry>

      <HtMenuEntry
        v-if="auth.userHasPermission('tradingPlatform.access')"
        :visibility="PlatformVisibility.DESKTOP_ONLY"
        :icon="RectangleStackIcon"
        :to="{ name: 'workspace' }"
        name="Layout"
      >
        <HtMenuPopupLayout />
      </HtMenuEntry>

      <HtMenuEntry
        v-if="
          auth.userHasPermission('tradingPlatform.access') &&
          Object.keys(tradingAccounts.accounts).length > 0
        "
        :visibility="PlatformVisibility.DESKTOP_ONLY"
        :icon="UserIcon"
        :to="{ name: 'workspace' }"
        name="Account"
      >
        <HtMenuPopupTrading />
      </HtMenuEntry>

      <HtMenuEntry
        v-if="auth.userHasPermission('tradingPlatform.access')"
        :visibility="PlatformVisibility.DESKTOP_ONLY"
        :icon="LifebuoyIcon"
        :to="{ name: 'workspace' }"
        name="Hilfe"
      >
        <HtMenuPopupHelp />
      </HtMenuEntry>

      <!-- Status-Seite -->
      <HtMenuLinkEntry
        :icon="MegaphoneIcon"
        url="https://status.tradesecure.app"
        :visibility="PlatformVisibility.DESKTOP_ONLY"
        name="Status"
      ></HtMenuLinkEntry>
    </nav>

    <div v-if="!isMobile" :class="['flex flex-col gap-2 mb-2 items-center', padding]">
      <!-- Darkmode Switch -->
      <DarkmodeSwitch class="" />

      <!-- Version -->
      <div class="text-xs text-slate-400 dark:text-slate-600 text-center w-16">
        {{ version }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isLandscape, isMobile } from "./../../shared/composables/mobile";
import { computed } from "vue";

import HtMenuEntry from "./../../components/navigation/HtMenuEntry.vue";
import PlatformVisibility from "../../shared/types/PlatformVisibility.ts";
import DarkmodeSwitch from "./../../components/ui/HtDarkmodeSwitch.vue";
import { useAuth } from "./../../stores/auth";
import HtMenuPopupOpenApp from "./popup/HtMenuPopupOpenApp.vue";
import HtMenuPopupLayout from "./popup/HtMenuPopupLayout.vue";
import HtMenuPopupTrading from "./popup/HtMenuPopupTrading.vue";
import HtMenuPopupHelp from "./popup/HtMenuPopupHelp.vue";
import HtMenuLinkEntry from "./../../components/navigation/HtMenuLinkEntry.vue";
import {
  KeyIcon,
  LifebuoyIcon,
  MegaphoneIcon,
  RectangleStackIcon,
  SquaresPlusIcon,
  UserIcon
} from "@heroicons/vue/24/outline";
import { useEtnaAccounts } from "../../stores/etna-accounts.js";

const auth = useAuth();
const tradingAccounts = useEtnaAccounts();

const sizeForRow = "w-full h-16 order-last";
const sizeForCol = "w-[4.5rem] h-full order-first";

const flexForRow = "flex flex-row justify-center";
const flewForCol = "flex flex-col justify-center";

const paddingForRow = "my-1";
const paddingForCol = "mx-2";

const size = computed(() =>
  isMobile ? (isLandscape.value ? sizeForCol : sizeForRow) : sizeForCol
);
const flex = computed(() =>
  isMobile ? (isLandscape.value ? flewForCol : flexForRow) : flewForCol
);
const padding = computed(() =>
  isMobile ? (isLandscape.value ? paddingForCol : paddingForRow) : paddingForCol
);

const version = computed(() => GIT_VERSION?.split("-")[0] ?? "");
</script>

<style scoped></style>
