<template>
  <notifications
    :max="6"
    :duration="5000"
    classes="notification"
    width="400px"
    class="m-8 mb-7"
    position="bottom left"
    :close-on-click="true"
  >
    <template #body="props">
      <div
        class="bg-white dark:bg-slate-800 hover:dark:bg-slate-700 rounded-lg border border-slate-200 dark:border-slate-700 hover:dark:border-slate-600 text-base py-4 px-4 flex gap-2 mb-1.5"
      >
        <div>
          <InformationCircleIcon
            v-if="props.item.type === 'info'"
            class="h-6 w-6 text-blue-400 dark:text-blue-600"
          />
          <CheckCircleIcon
            v-if="props.item.type === 'success'"
            class="h-6 w-6 text-green-400 dark:text-green-600"
          />
          <ExclamationTriangleIcon
            v-if="props.item.type === 'warn'"
            class="h-6 w-6 text-orange-400 dark:text-orange-600"
          />
          <ExclamationCircleIcon
            v-if="props.item.type === 'error'"
            class="h-6 w-6 text-red-400 dark:text-red-600"
          />
        </div>
        <div class="w-full">
          <div class="text-slate-900 dark:text-slate-100 pb-1 font-medium">
            {{ props.item.title }}
          </div>
          <div class="text-slate-500 dark:text-slate-300 break-words pr-2">
            {{ props.item.text }}
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon
} from "@heroicons/vue/24/outline";
import { Notifications } from "@kyvg/vue3-notification";

export default {
  name: "NotificationManager",

  components: {
    Notifications,
    CheckCircleIcon,
    ExclamationTriangleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon
  }
};
</script>
