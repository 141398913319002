<template>
  <PopoverButton
    class="text-base text-slate-800 hover:text-slate-900 dark:text-slate-300 dark:hover:text-slate-100 hover:bg-slate-300 dark:hover:bg-slate-700 active:bg-blue-600 active:text-white group flex rounded-md items-center w-full px-2 py-2"
  >
    <component
      :is="icon"
      v-if="icon != null"
      :class="[
        'dark:text-gray-500 text-gray-400 group-hover:text-gray-500  mr-2 flex-shrink-0 h-5 w-5'
      ]"
      aria-hidden="true"
    />

    <div
      v-else-if="iconPath != null"
      :style="'mask-image: url(' + iconPath + '); mask-position: center; mask-size: contain;'"
      :class="['mr-1', 'bg-blue-600']"
    >
      <img :src="iconPath" :class="['h-5', 'opacity-0']" alt="Icon" />
    </div>

    <span v-else aria-hidden="true" class="w-5 h-5 mr-2 text-blue-400">
      {{ emoji }}
    </span>

    <span class="w-full h-full text-left block">
      <slot />
    </span>
  </PopoverButton>
</template>

<script>
import { PopoverButton } from "@headlessui/vue";

export default {
  components: { PopoverButton },
  props: ["iconPath", "emoji", "icon", "emit", "primaryColor"]
};
</script>
