<template>
  <div class="text-lg font-medium px-2 pt-2 inline-flex gap-2">
    <div class="h-8 w-8 bg-blue-600 rounded-full py-1.5">
      <component :is="icon" class="h-5 w-5 text-white m-auto" />
    </div>

    <div class="text-blue-600 my-auto">
      {{ name }}
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "HtMenuPopupTitle"
};
</script>

<script setup lang="ts">
defineProps({
  name: {
    type: String,
    required: true
  },
  icon: {
    required: true
  }
});
</script>

<style scoped></style>
