import { useNotification } from "@kyvg/vue3-notification";
import { formatDollars } from "./../../shared/composables/formatter";
import {
  OrderStatusDescriptionExplanation,
  OrderStatusDescriptionExplanations
} from "./../../shared/types/TradingExplanation";
import { Order, OrderStatusDescription } from "../types/Trading.ts";

const notificationHistory: { [key: number]: string } = {};
const notify = useNotification();

const calculateChecksumForState = function (order: Order) {
  return (order.statusDescription ?? order.status) + order.id + order.quantity + order.level;
};

const notificationWasShown = function (order: Order) {
  return notificationHistory[order.id] == calculateChecksumForState(order);
};

const rememberNotificationState = function (order: Order) {
  notificationHistory[order.id] = calculateChecksumForState(order);
};

const getOrderDescription = function (order: Order): string {
  return (
    order.side +
    " " +
    order.type +
    " " +
    order.quantity +
    "x " +
    order.symbol +
    " @ " +
    formatDollars(order.level)
  );
};

const showNotificationForOrder = function (order: Order) {
  // Überspringen, falls keine statusDescription vorhanden ist
  if (order.statusDescription === undefined) return;

  // Explanation für den Status holen
  const orderStatusDescriptionExplanation: OrderStatusDescriptionExplanation =
    OrderStatusDescriptionExplanations[order.statusDescription as OrderStatusDescription] ?? null;

  // Sonder-Handling, falls keine Explanation für den Status gefunden wurde
  if (orderStatusDescriptionExplanation === null) {
    notify.notify({
      type: "info",
      text: getOrderDescription(order),
      title: "Hinweis: " + order.statusDescription
    });

    return;
  }

  // Keine Benachrichtigung, wenn die Order in einem "Pending"-Status ist
  if (order.status.includes("Pending")) return;

  // Keine Benachrichtigung, für OTO, OCO und OTOCO
  if (order.status == "OneTriggerOther") return;
  if (order.status == "OneCancelOther") return;
  if (order.status == "OneTriggerOneCancelOther") return;

  // Keine Benachrichtigung, wenn die Order in diesem State schon einmal angezeigt wurde
  if (notificationWasShown(order)) return;

  // Prüfen, ob Benachrichtigung angezeigt werden soll
  if (!orderStatusDescriptionExplanation?.showNotification) return;

  // Benachrichtigung anzeigen
  notify.notify({
    type: orderStatusDescriptionExplanation.type,
    text: getOrderDescription(order),
    title: orderStatusDescriptionExplanation.text(order)
  });

  // Benachrichtigung merken
  rememberNotificationState(order);
};

export { showNotificationForOrder };
