import { PlCalculator } from "./../../../shared/services/TradingCalculations/index.ts";
import { Position, RawPosition } from "../../types/Trading.ts";

export const createPosition = (raw: RawPosition): Position => {
  const quantity = Number(raw.Quantity);
  const type = quantity > 0 ? "long" : "short";

  return {
    id: raw.Id,
    type,
    symbol: raw.Symbol,
    quantity,
    avgOpenPrice: Number(raw.AverageOpenPrice)
  };
};

export const updatePositionByCurrentPrice = (
  position: Position,
  currentPrice: number
): Position => {
  const plOpen = new PlCalculator()[position.type](
    currentPrice,
    position.avgOpenPrice,
    position.quantity
  );

  return {
    ...position,
    currentPrice,
    plOpen: plOpen
  };
};
