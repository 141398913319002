import {
  ComplexOrderFactoryOCOPayload,
  ComplexOrderFactoryOTOCOPayload,
  ComplexOrderFactorySlOTOPayload,
  ComplexOrderFactoryTpOTOPayload,
  ComplexOrderOco,
  ComplexOrderOto,
  ComplexOrderOtoco,
  OrderType,
  Side,
  SimpleFactory,
  SimpleLimitOrder,
  SimpleMarketOrder,
  SimpleOrder,
  SimpleStopOrder,
  SlTp
} from "../../types/Trading.ts";

type BaseLegsMap = Record<
  OrderType,
  SimpleFactory<SimpleMarketOrder | SimpleLimitOrder | SimpleStopOrder>
>;
type SideEntry = Extract<Side, "Buy" | "SellShort">;
type SideOutput = Extract<Side, "Sell" | "BuyToCover">;

export const createMarketOrder: SimpleFactory<SimpleMarketOrder> = (payload): SimpleMarketOrder =>
  mixExecutionInstructions(
    {
      Type: "Market",
      Symbol: payload.symbol,
      Side: payload.side,
      Quantity: payload.quantity,
      Price: payload.price
      // ExtendedHours: 'ALL',
    },
    payload.typeSlTp
  );

export const createLimitOrder: SimpleFactory<SimpleLimitOrder> = (payload): SimpleLimitOrder =>
  mixExecutionInstructions(
    {
      Type: "Limit",
      Symbol: payload.symbol,
      Side: payload.side,
      Quantity: payload.quantity,
      Price: payload.price
    },
    payload.typeSlTp
  );

export const createStopOrder: SimpleFactory<SimpleStopOrder> = (payload): SimpleStopOrder =>
  mixExecutionInstructions(
    {
      Type: "Stop",
      Symbol: payload.symbol,
      Side: payload.side,
      Quantity: payload.quantity,
      StopPrice: payload.price
    },
    payload.typeSlTp
  );

export const createTpOtoOrder = (payload: ComplexOrderFactoryTpOTOPayload): ComplexOrderOto => {
  const { type, symbol, side, quantity, price } = payload;
  const firstLegFactory = getFirstLegFactory(type);
  const legSide = side === "Buy" || side === "SellShort" ? getLegSide(side) : side;

  return {
    Type: "OneTriggerOther",
    Symbol: symbol,
    Legs: [
      firstLegFactory({ symbol, side, quantity, price }),
      createLimitOrder({
        symbol,
        side: legSide,
        quantity,
        price: payload.takeProfit
      })
    ]
  };
};

export const createSlOtoOrder = (payload: ComplexOrderFactorySlOTOPayload): ComplexOrderOto => {
  const { type, symbol, side, quantity, price } = payload;
  const firstLegFactory = getFirstLegFactory(type);
  const legSide = side === "Buy" || side === "SellShort" ? getLegSide(side) : side;

  return {
    Type: "OneTriggerOther",
    Symbol: symbol,
    Legs: [
      firstLegFactory({ symbol, side, quantity, price }),
      createStopOrder({
        symbol,
        side: legSide,
        quantity,
        price: payload.stopLoss
      })
    ]
  };
};

export const createOcoOrder = (payload: ComplexOrderFactoryOCOPayload): ComplexOrderOco => {
  const { symbol, side, quantity, stopLoss, takeProfit } = payload;

  return {
    Type: "OneCancelOther",
    Symbol: symbol,
    Legs: [
      createLimitOrder({
        symbol,
        side: side,
        quantity,
        price: takeProfit
      }),
      createStopOrder({ symbol, side: side, quantity, price: stopLoss })
    ]
  };
};

export const createOtocoOrder = (payload: ComplexOrderFactoryOTOCOPayload): ComplexOrderOtoco => {
  const { type, symbol, side, quantity, price } = payload;
  const firstLegFactory = getFirstLegFactory(type);
  const legSide = side === "Buy" || side === "SellShort" ? getLegSide(side) : side;

  return {
    Type: "OneTriggerOneCancelOther",
    Symbol: "",
    Legs: [
      firstLegFactory({ symbol, side, quantity, price }),
      createLimitOrder({
        symbol,
        side: legSide,
        quantity,
        price: payload.takeProfit
      }),
      createStopOrder({
        symbol,
        side: legSide,
        quantity,
        price: payload.stopLoss
      })
    ]
  };
};

function mixExecutionInstructions<T extends SimpleOrder | SimpleStopOrder>(
  order: T,
  typeSlTp?: SlTp
): T {
  return typeSlTp ? { ...order, ExecutionInstructions: { typeSlTp } } : order;
}

function getLegSide(mainOrderSide: SideEntry): SideOutput {
  const legSides: Record<SideEntry, SideOutput> = {
    Buy: "Sell",
    SellShort: "BuyToCover"
  };

  return legSides[mainOrderSide];
}

function getFirstLegFactory(
  orderType: OrderType
): SimpleFactory<SimpleMarketOrder | SimpleLimitOrder | SimpleStopOrder> {
  const factories: BaseLegsMap = {
    Market: createMarketOrder,
    Limit: createLimitOrder,
    Stop: createStopOrder
  };

  return factories[orderType];
}
