<template>
  <component
    :is="currentDialogComponent"
    v-if="currentDialogComponent != null"
    ref="dialog"
    v-bind="dataToPass"
  />
</template>

<script>
/*
 * Kommandos importieren
 */

import { markRaw, nextTick, watch } from "vue";
import { useAuth } from "../../stores/auth.ts";
import emitter from "../../shared/services/EventService.ts";
import AboutDialog from "../dialog/dialogs/AboutDialog.vue";
import SaveAs from "../dialog/dialogs/layout/SaveAs.vue";
import NameNewLayout from "../dialog/dialogs/layout/NameNewLayout.vue";
import DeletePrompt from "../dialog/dialogs/layout/DeletePrompt.vue";
import InfovideoDialog from "../dialog/dialogs/workspace/apps/CopyTrading/InfovideoDialog.vue";
import SettingsDialog from "../dialog/dialogs/workspace/apps/CopyTrading/SettingsDialog.vue";
import AppSettingsDialog from "../dialog/dialogs/AppSettingsDialog.vue";
import { useMagicKeys } from "@vueuse/core";
import ModifyOrderDialog from "../dialog/dialogs/workspace/apps/TradingView/ModifyOrderDialog.vue";

/*
 * Kommandos registrieren
 */
const dialogs = {
  informationDialog: {
    name: "Anwendungsinformationen",
    permission: null,
    component: markRaw(AboutDialog)
  },
  appSettingsDialog: {
    name: "Einstellungen",
    permission: null,
    component: markRaw(AppSettingsDialog)
  },
  "trading.layoutManager.nameNewLayout": {
    name: "[Layout-Manager] Namen für neues Layout erfragen",
    permission: null,
    component: markRaw(NameNewLayout)
  },
  "trading.layoutManager.saveAs": {
    name: "[Layout-Manager] Layout speichern unter",
    permission: null,
    component: markRaw(SaveAs)
  },
  "trading.layoutManager.deletePrompt": {
    name: "[Layout-Manager] Layout wirklich löschen?",
    permission: null,
    component: markRaw(DeletePrompt)
  },
  "workspace.windows.copyTrading.infovideo": {
    name: "[Copy Trading] Infovideo",
    permission: null,
    component: markRaw(InfovideoDialog)
  },
  "workspace.windows.copyTrading.settings": {
    name: "[Copy Trading] Einstellungen",
    permission: null,
    component: markRaw(SettingsDialog)
  },
  "workspace.windows.tradingView.modifyOrder": {
    name: "[TradingView] Order bearbeiten",
    permission: null,
    component: markRaw(ModifyOrderDialog)
  }
};

export default {
  name: "DialogManager",

  setup() {
    const auth = useAuth();
    return {
      auth
    };
  },

  data() {
    return {
      currentDialogComponent: null,
      currentDialogType: null,
      dataToPass: null
    };
  },

  created() {
    // Shortcut um Settings-Dialog zu öffnen
    const { ctrl_t_s } = useMagicKeys();
    watch(ctrl_t_s, () => {
      emitter.emit("dialog:appSettingsDialog");
    });

    // Event-Listener
    emitter.on("*", (type, e) => {
      // Nicht-"dialog"-Events ignorieren
      if (!type.startsWith("dialog:")) {
        return;
      }

      // Prüfen, ob es sich um ein Response-Event handelt
      if (type.startsWith("response")) {
        console.log(
          `[Dialog Manager] Client Response (${type}) gefunden. Kein weiteres Handling notwendig.`
        );
        return;
      }

      // dialog-Vorsatz entfernen
      type = type.split("dialog:")[1];

      // Log-Ausgabe
      console.log(`[Dialog Manager] (${type}) Request received.`);

      // Prüfen, ob Command vorhanden ist
      if (!(type in dialogs)) {
        console.warn(`[Dialog Manager] (${type}) Dialog not found!`);
        return;
      }

      // Prüfen, ob Berechtigung notwendig ist
      if (dialogs[type].permission == null) {
        console.log(`[Dialog Manager] (${type}) No permission necessary.`);
      } else {
        if (this.auth.userHasPermission(dialogs[type].permission)) {
          console.log(
            `[Dialog Manager] (${type}) OK, necessary permission ${dialogs[type].permission} available.`
          );
        } else {
          console.warn(
            `[Dialog Manager] (${type}) Necessary Permission ${dialogs[type].permission} NOT granted.`
          );
          return;
        }
      }

      // Ausführen
      try {
        this.mountDialog(type, e);

        console.log(`[Dialog Manager] (${type}) Dialog opened.`);
      } catch (e) {
        console.error(`[Dialog Manager] (${type}) Dialog open failed.`, e);
      }
    });

    console.log(`[Dialog Manager] Loaded.`);
  },

  methods: {
    close() {
      emitter.emit(`response:${this.currentDialogType}.close`);
      this.unmountDialog();
    },

    cancel() {
      emitter.emit(`response:${this.currentDialogType}.cancel`);
      this.unmountDialog();
    },

    submit() {
      emitter.emit(`response:${this.currentDialogType}.submit`, this.$refs.dialog.$data);
      this.unmountDialog();
    },

    /**
     * Dialog mounten und öffnen
     * @param type Typ des Dialogs (muss dem Dialog-Manager bekannt sein)
     * @param data Array mit durchzureichenden Daten
     */
    async mountDialog(type, data) {
      // Lokale Zuweisung updaten
      this.dataToPass = data;
      this.currentDialogType = type;
      this.currentDialogComponent = dialogs[type].component;

      // Warten auf Rendering des Dialogs
      await nextTick();

      // Entweder: Suche nach "isOpen" direkt im Dialog
      if ("isOpen" in this.$refs.dialog) {
        this.$refs.dialog.isOpen = true;
      }

      // oder: Prüfe, ob der Dialog einen AbstractDialog als Ref angegeben hat
      else if ("abstractDialog" in this.$refs.dialog.$refs) {
        this.$refs.dialog.$refs.abstractDialog.isOpen = true;
      }

      // Sonst gebe eine Fehlermeldung aus
      else {
        console.error("[Dialog Manager] Keine Methode zum Öffnen des Dialogs gefunden!");
      }
    },

    /**
     * aktuellen Dialog schließen und Unmount
     */
    unmountDialog() {
      // Entweder: Suche nach "isOpen" direkt im Dialog
      if ("isOpen" in this.$refs.dialog) {
        this.$refs.dialog.isOpen = false;
      }

      // oder: Prüfe, ob der Dialog einen AbstractDialog als Ref angegeben hat
      else if ("abstractDialog" in this.$refs.dialog.$refs) {
        this.$refs.dialog.$refs.abstractDialog.isOpen = false;
      }

      // Sonst gebe eine Fehlermeldung aus
      else {
        console.error("[Dialog Manager] Keine Methode zum Schließen des Dialogs gefunden!");
      }

      // Lokale Zuweisung updaten
      this.currentDialogType = null;
      this.currentDialogComponent = null;
    }
  }
};
</script>

<style scoped></style>
