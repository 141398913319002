<template>
  <div
    class="rounded-md my-1 whitespace-nowrap py-1.5 px-3 text-base bg-slate-50 dark:bg-slate-700 hover:bg-slate-100 text-slate-700 dark:hover:bg-slate-700 dark:text-slate-200 justify-between inline-flex"
  >
    <div class="inline-flex">
      <div>{{ name }}</div>
    </div>
    <div class="inline-flex gap-1 text-right">
      <div :class="['font-medium', valueClassNames]">
        {{ value }}
      </div>
      <HtExplanationText class="text-left">
        {{ description }}
      </HtExplanationText>
    </div>
  </div>
</template>

<script>
import HtExplanationText from "../../ui/HtExplanationText.vue";

export default {
  components: { HtExplanationText },
  props: ["name", "description", "value", "valueClassNames"]
};
</script>

<style scoped></style>
