<template>
  <a :href="url" target="_blank">
    <div
      :class="[
        'aspect-square w-14 gap-1 rounded-lg overflow-hidden flex flex-col items-center justify-center ',
        'text-sm bg-transparent hover:bg-blue-600 dark:hover:bg-blue-800 hover:text-slate-100 dark:hover:text-slate-100 text-slate-500 dark:text-slate-400 transition-all duration-150'
      ]"
    >
      <Component :is="icon" class="h-6 w-6"></Component>
      <div>{{ name }}</div>
    </div>
  </a>
</template>

<script setup lang="ts">
import { useSlots } from "vue";
import PlatformVisibility from "../../shared/types/PlatformVisibility.ts";

const slots = useSlots();

const props = defineProps<{
  name: string;
  url: string;
  visibility: PlatformVisibility;
  icon: any;
}>();
</script>

<style scoped></style>
