import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { PositionBase } from "../../shared/types/Trading.ts";
import { PlaceOrder } from "../../shared/services/Etna/PlaceOrder.ts";
import { ReplaceOrder } from "../../shared/services/Etna/ReplaceOrder.ts";
import { ClosePositionsBySymbol } from "../../shared/services/Etna/ClosePositionsBySymbol.ts";
import { ChangePositionSlTp } from "../../shared/services/Etna/ChangePositionSlTp.ts";
import { GetSlTpOrder } from "../../shared/services/Etna/GetSlTpOrder.ts";
import { CancelOrder } from "../../shared/services/Etna/CancelOrder.ts";
import { GetOpenPositionBySymbol } from "../../shared/services/Etna/GetOpenPositionBySymbol.ts";

export type OrderSlTp = { sl: number; tp: number };

export const useTradingTicketStore = defineStore("trading-place-order", () => {
  const order = ref<OrderSlTp | undefined>();
  const position = ref<PositionBase | undefined>();

  const setSlTpOrder = (data?: OrderSlTp): void => {
    order.value = data;
  };
  const setOpenPosition = (data?: PositionBase): void => {
    position.value = data;
  };

  const slTpOrder = computed(() => order.value);
  const openPosition = computed(() => position.value);

  const getSlTpOrder = new GetSlTpOrder(setSlTpOrder).exec;

  return {
    placeOrder: new PlaceOrder().exec,
    closePositionsBySymbol: new ClosePositionsBySymbol().exec,
    changePositionSlTp: new ChangePositionSlTp().exec,
    replaceOrder: new ReplaceOrder().exec,
    getSlTpOrder,
    cancelOrder: new CancelOrder().exec,
    getOpenPositionBySymbol: new GetOpenPositionBySymbol(setOpenPosition).exec,
    slTpOrder,
    openPosition
  };
});
