import { defineStore } from "pinia";
import { ref } from "vue";
import {
  Field,
  getField,
  getOperator,
  isOpen,
  Operator,
  PriceAlert,
  State
} from "../shared/types/PriceAlert.ts";
import { useEtnaSecurities } from "./etna-securities.ts";
import { PolygonSymbol } from "../shared/types/Polygon.ts";
import { useEtnaTrading } from "./etna-trading.ts";
import { useEtnaAccounts } from "./etna-accounts.ts";
import moment from "moment";
import { Module, useStatus } from "./status.ts";
import CloseAndCancelPositionService from "../shared/services/Etna/CloseAndCancelPosition.ts";
import emitter from "../shared/services/EventService.ts";
import { PositionType, SlTp } from "../shared/types/Trading.ts";
import _ from "lodash";
import { notify } from "@kyvg/vue3-notification";

export const useEtnaAlerts = defineStore("etna-alerts", () => {
  const tradingStore = useEtnaTrading();

  const securitiesStore = useEtnaSecurities();

  const accountsStore = useEtnaAccounts();

  const status = useStatus();

  /**
   * Alle für den Account gefundenen Accounts
   */
  const alerts = ref<Record<number, PriceAlert>>({});

  /**
   * Funktion die beim Start aufgerufen wird, um alle Informationen zu sammeln und im lokalen Store zu speichern
   * @param {number} userId ETNA-User-ID
   * @returns {Promise<void>}
   */
  async function boot(userId: number): Promise<void> {
    try {
      // Status-Flag setzen
      status.setLoading(Module.tradingAlerts);

      // Positions vom Server laden
      await loadAlerts();

      // Websocket abonnieren
      await tradingStore.subscribeTo("PriceAlerts", userId);

      // Status-Flag setzen
      status.setReady(Module.tradingAlerts);
    } catch (e) {
      console.error(e, "while executing trading-alerts boot");

      // Status-Flag setzen
      status.setError(Module.tradingAlerts, e);
    }
  }

  /**
   * Wandelt die ID des States (die über den Websocket kommt) in das entsprechende Format um.
   * @param id
   */
  const stateIdToState = function (id: number): State {
    if (id == 0) return State.New;
    if (id == 1) return State.Expired;
    if (id == 2) return State.Completed;
    if (id == 3) return State.Stopped;
  };

  /**
   * Verarbeitet Websocket-Nachrichten.
   * Updated den lokalen Store und achtet darauf, of Alerts ausgelöst wurden
   * @param rawAlert
   */
  async function handleMessage(rawAlert: any) {
    // Only handle PriceAlerts
    if (rawAlert.EntityType !== "PriceAlerts") return;

    // Patch state
    rawAlert.State = stateIdToState(rawAlert.State as number);

    // Delete entry if action is Deletion
    if (rawAlert.EntityAction === "Deletion") {
      rawAlert.State = "Deleted";
      emitter.emit("trading.alert.updated", rawAlert);

      delete alerts.value[rawAlert.Id];
      return;
    }

    // Update Event
    emitter.emit("trading.alert.updated", rawAlert);

    // Old state
    const oldState = alerts.value[rawAlert.Id]?.State;

    // New state
    const newState = rawAlert.State;

    // Trigger
    if (oldState !== newState && newState === State.Completed) {
      // Emit Event
      emitter.emit("trading.alert.triggered", rawAlert);

      // Close the position, cancel the order and remove the alarms
      CloseAndCancelPositionService(rawAlert.Symbol);
    }

    if (rawAlert.EntityAction === "Creation" || rawAlert.EntityAction === "Modification") {
      const state = isNaN(rawAlert.State as number)
        ? (rawAlert.State as State)
        : stateIdToState(rawAlert.State as number);

      // Store update in store
      alerts.value[rawAlert.Id] = {
        State: state,
        Id: rawAlert.Id as number,
        Operator: rawAlert.Operator as Operator,
        Field: rawAlert.Field as Field,
        Argument: rawAlert.Argument as number,
        SecurityId: rawAlert.SecurityId as number,
        ExpirationDate: rawAlert.ExpirationDate as number,
        CreatedDate: rawAlert.CreatedDate as number,
        Symbol: rawAlert?.Symbol as PolygonSymbol
      } as PriceAlert;
    }
  }

  /**
   * Funktion die beim Start aufgerufen wird, um alle Informationen zu sammeln und im lokalen Store zu speichern
   * @param {number} accountId ID des abzufragenden Accounts
   * @returns {Promise<void>}
   */
  async function shutdown(accountId: number): Promise<void> {
    console.log(accountId);
  }

  const loadAlerts = async function (pageNumber: number = 0) {
    const userId = tradingStore.getEtnaUserId();
    const result = await tradingStore
      .getAxios()
      .get(
        `/users/${userId}/pricealerts?pageSize=5&pageNumber=${pageNumber}&sortBy=State&isDesc=true`
      );

    // Code 200 check
    if (result.status !== 200) {
      throw new Error(result.data);
    }

    // Iterate over alerts
    result.data.Result.forEach((rawAlert: any) => {
      const alert = rawAlert as PriceAlert;
      alerts.value[alert.Id] = alert;
    });

    if (result.data.NextPageLink != "") {
      return loadAlerts(pageNumber + 1);
    }

    return alerts.value;
  };

  const create = async function (
    symbol: PolygonSymbol,
    price: number,
    operator: Operator = Operator.GTEQ,
    field: Field = Field.Bid
  ) {
    const security = await securitiesStore.getSecurityBySymbol(symbol);

    const payload = {
      State: State.New,
      SecurityId: security?.Id,
      Argument: price,
      Field: field,
      Operator: operator,
      ExpirationDate: moment().add("1", "day").unix()
    } as PriceAlert;

    const userId = tradingStore.getEtnaUserId();
    const result = await tradingStore.getAxios().post(`/users/${userId}/pricealerts`, payload);

    if (result.status !== 200) {
      throw new Error(result.data);
    }

    return result.data as PriceAlert;
  };

  const remove = async function (alertId: number) {
    const userId = tradingStore.getEtnaUserId();
    const result = await tradingStore.getAxios().delete(`/users/${userId}/pricealerts/${alertId}`);

    // ETNA liefert 204 im Erfolgsfall
    if (result.status <= 200 || result.status >= 300) {
      throw new Error(result.data);
    }

    // Löschen
    delete alerts.value[alertId];
  };

  const updatePrice = async function (alertId: number, price: number) {
    // Load old value
    const old = alerts.value[alertId];

    if (!old) {
      throw new Error(`No alert found with id ${alertId}`);
    }

    //
    const payload = {
      State: old.State,
      SecurityId: old.SecurityId,
      Argument: price,
      Field: old.Field,
      Operator: old.Operator,
      ExpirationDate: old.ExpirationDate
    } as PriceAlert;

    return await update(alertId, payload);
  };

  const update = async function (alertId: number, payload: PriceAlert) {
    const userId = tradingStore.getEtnaUserId();
    const result = await tradingStore
      .getAxios()
      .put(`/users/${userId}/pricealerts/${alertId}`, payload);

    // ETNA liefert 200 im Erfolgsfall
    if (result.status < 200 || result.status >= 300) {
      throw new Error(result.data);
    }

    return result.data;
  };

  const checkIfAlertsExits = function (
    symbol: PolygonSymbol,
    slTp: SlTp,
    positionType: PositionType
  ): boolean {
    // Field und Operator ermitteln
    const field = getField(slTp, positionType);
    const operator = getOperator(slTp, positionType);

    return !!_.find(
      alerts.value,
      (alert) =>
        isOpen(alert) &&
        alert.Symbol == symbol &&
        alert.Field == field &&
        alert.Operator == operator
    );
  };

  const placeSlTp = async function (
    symbol: PolygonSymbol,
    slTp: SlTp,
    positionType: PositionType,
    price: number
  ) {
    // Field und Operator ermitteln
    const field = getField(slTp, positionType);
    const operator = getOperator(slTp, positionType);

    if (checkIfAlertsExits(symbol, slTp, positionType)) {
      notify({
        type: "warn",
        title: `Stop-Loss / Take-Profit Order wurde nicht platziert`,
        text: `Für dieses Symbol besteht bereits eine entsprechende Order. Löschen Sie diese Order, bevor Sie eine neue aufgeben.`
      });
      return;
    }

    await create(symbol, price, operator, field);
  };

  return {
    boot,
    shutdown,
    handleMessage,
    create,
    remove,
    alerts,
    update,
    updatePrice,
    placeSlTp,
    checkIfAlertsExits
  };
});
