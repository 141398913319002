import { PolygonSymbol } from "../../types/Polygon.ts";
import { debug, info } from "../LoggingService.ts";
import { useEtnaOrders } from "../../../stores/etna-orders.ts";
import _, { remove } from "lodash";
import { Order } from "../../types/Trading.ts";
import { useTradingTicketStore } from "../../../stores/workspace-layouts/trading-ticket.ts";
import { useStockdata } from "../../../stores/stockdata.ts";
import { useEtnaAlerts } from "../../../stores/etna-alerts.ts";
import { PriceAlert } from "../../types/PriceAlert.ts";
import { useEtnaSecurities } from "../../../stores/etna-securities.ts";

/**
 * Zu dem gegebenen Symbol werden:
 * 1. Alle offenen Orders gecancelt
 * 2. Die Position geschlossen (falls offen)
 * 3. **Alle** Alerts zu dem Symbol entfernt
 * @param symbol Symbol
 */
export default async function (symbol: PolygonSymbol): Promise<void> {
  info(`[Close + Cancel Position Service] Request for ${symbol} received`);

  await cancelOrders(symbol);
  await closePosition(symbol);
  await removeAlerts(symbol);
}

/**
 * Alle offenen Orders canceln
 * @param symbol
 */
const cancelOrders = async function (symbol: PolygonSymbol) {
  const tradingTicketStore = useTradingTicketStore();
  const ordersStore = useEtnaOrders();

  const ordersToCancel = _.filter(
    ordersStore.orders,
    (order: Order) => order.symbol === symbol && order.isActive
  );

  debug("[Close + Cancel Position Service] Orders to cancel: ", ordersToCancel);

  const promises = ordersToCancel.map((order) => tradingTicketStore.cancelOrder(order.id));
  await Promise.all(promises);

  debug("[Close + Cancel Position Service] Orders canceled");
};

/**
 * Position zu dem Symbol schließen
 * @param symbol
 */
const closePosition = async function (symbol: PolygonSymbol) {
  const stockdata = useStockdata();
  const tradingTicketStore = useTradingTicketStore();

  await tradingTicketStore.closePositionsBySymbol(
    symbol,
    () => stockdata.snapshots[symbol]?.fmv,
    100
  );

  debug("[Close + Cancel Position Service] Position closed (if one was open)");
};

/**
 * Alerts zu dem Symbol entfernen
 * @param symbol
 */
const removeAlerts = async function (symbol: PolygonSymbol) {
  const alerts = useEtnaAlerts();
  const securities = useEtnaSecurities();

  const security = await securities.getSecurityBySymbol(symbol);

  const alertsToRemove = _.filter(
    alerts.alerts,
    (alert: PriceAlert) => alert.SecurityId == security.Id
  );

  debug("[Close + Cancel Position Service] Alerts to remove: ", alertsToRemove);

  const promises = alertsToRemove.map((alert) => alerts.remove(alert.Id));
  await Promise.all(promises);

  debug("[Close + Cancel Position Service] Alerts removed");
};
