import { defineStore } from "pinia";
import { computed, ComputedRef, ref } from "vue";
import _ from "lodash";
import { notify } from "@kyvg/vue3-notification";
import TemplateEmpty from "./../stores/workspace-layouts/TemplateEmpty";
import emitter from "./../shared/services/EventService";
import TemplateDefault from "./workspace-layouts/TemplateDefault";
import TemplateNewsScreening from "./../stores/workspace-layouts/TemplateNewsScreening";
import { useAuth } from "./auth.ts";
import {
  WorkspaceProLayout,
  WorkspaceProLayoutSave,
  WorkspaceProLayoutTemplate
} from "../shared/types/WorkspaceApp.ts";
import { useAxios } from "./axios.ts";
import TemplateMarcWillmannMentoring from "./workspace-layouts/TemplateMarcWillmannMentoring.ts";
import { TemplateHeldentalTradingTools } from "./workspace-layouts/TemplateHeldentalTradingTools.ts";
import { Module, useStatus } from "./status.ts";
import { TemplateTestTrader } from "./workspace-layouts/TemplateTestTrader.ts";

export const useWorkspaceLayoutStore = defineStore("workspace-layouts", () => {
  // Auth-Store zum Permission-Check
  const auth = useAuth();

  // Globaler App-Status
  const status = useStatus();

  // Aktuelles Layout
  const currentLayoutId = ref<number>(0);

  // Verfügbare User-Layouts
  const savedLayouts = ref<WorkspaceProLayoutSave[]>([]);

  // Serialized Layout
  const currentLayoutSerialized = ref<any>({});

  // Templates definieren
  const templates: WorkspaceProLayoutTemplate[] = [
    {
      id: -1,
      name: "News-Screening",
      permission: null,
      layout: TemplateNewsScreening
    },
    {
      id: -10,
      name: "Standard-Analyse",
      permission: "tradingPlatform.layouts.template.default",
      layout: TemplateDefault
    },
    {
      id: -20,
      name: "Heldental Trading-Tools",
      permission: "tradingPlatform.layouts.template.heldental-trading-tools",
      layout: TemplateHeldentalTradingTools
    },
    {
      id: -40,
      name: "Marc Willmann Mentoring",
      permission: "tradingPlatform.layouts.template.marc-willmann-mentoring",
      layout: TemplateMarcWillmannMentoring
    },
    {
      id: -50,
      name: "Test-Trader",
      permission: "tradingPlatform.layouts.template.test-trader",
      layout: TemplateTestTrader
    }
  ];

  const isLoading = ref(true);

  // Aktuelles Layout
  const currentLayout: ComputedRef<WorkspaceProLayoutTemplate | WorkspaceProLayoutSave> = computed(
    () => _.find(savedLayouts.value, ["id", currentLayoutId.value]) ?? findBestTemplate()
  );

  function findBestTemplate(): WorkspaceProLayoutTemplate {
    return (
      _.find(templates, ["id", currentLayoutId.value]) ??
      _.find(templates, function (template) {
        return auth.userHasPermission(template.permission);
      }) ??
      templates[0]
    );
  }

  emitter.on("response:trading.layoutManager.nameNewLayout.submit", (payload: any) => {
    createNewEmptyLayout(payload.name);
  });

  emitter.on("response:trading.layoutManager.saveAs.submit", (payload: any) => {
    saveCurrentLayoutAsNewLayout(payload.name);
  });

  emitter.on("response:trading.layoutManager.deletePrompt.submit", (payload: any) => {
    deleteSavedLayout(payload.layoutId);
  });

  /**
   * Aktuelles Layout speichern
   */
  function saveCurrentLayout() {
    emitter.emit("workspace.updateSerializedLayout");

    const { put } = useAxios();

    put(`/api/trading/layouts/${currentLayoutId.value}`, {
      layout: currentLayoutSerialized.value
    }).then((res) => {
      storeResponse(res);

      notify({
        type: "info",
        title: "Layout gespeichert",
        text: `Das aktuelle Layout wurde gespeichert.`
      });
    });
  }

  /**
   * Neues Layout erzeugen: Dialog verarbeiten
   * @param name Name des neuen Layouts
   */
  function createNewEmptyLayout(name: string) {
    isLoading.value = true;

    // API Call auslösen
    const { post } = useAxios();

    post("/api/trading/layouts/create", {
      name: name,
      layout: TemplateEmpty,
      isCurrent: true
    }).then((res) => {
      // Benachrichtigung
      notify({
        type: "info",
        title: "Layout erzeugt",
        text: `Neues, leeres Layout <span class="font-medium">${name}</span> wurde erzeugt`
      });

      // Update anstoßen
      storeResponse(res);

      // Leeres Layout laden
      loadCurrentLayout();
    });
  }

  /**
   * Layout speichern unter: Dialog verarbeiten
   */
  function saveCurrentLayoutAsNewLayout(name: string) {
    emitter.emit("workspace.updateSerializedLayout");
    isLoading.value = true;

    // API Call auslösen
    const { post } = useAxios();

    post("/api/trading/layouts/create", {
      name: name,
      layout: currentLayoutSerialized.value,
      isCurrent: true
    }).then((res) => {
      // Benachrichtigung
      notify({
        type: "success",
        title: "Layout gespeichert",
        text: `Das Layout wurde als "${name}" gespeichert.`
      });

      // Update anstoßen
      storeResponse(res);
    });
  }

  /**
   * Layout löschen: Dialog verarbeiten
   */
  function deleteSavedLayout(layoutId: number) {
    const { del } = useAxios();

    // API Call auslösen
    del(`/api/trading/layouts/${layoutId}/delete`).then((res) => {
      // Benachrichtigung
      notify({
        type: "info",
        title: "Layout gelöscht",
        text: `Das Layout wurde gelöscht.`
      });

      // Wenn das aktuelle Layout gelöscht wird, wird das Standardlayout geladen
      if (currentLayoutId.value === layoutId) {
        currentLayoutId.value = 0;
      }

      // Update anstoßen
      storeResponse(res);
    });
  }

  /**
   * Speichert Layouts lokal
   * @param res
   * @param updateCurrent
   */
  function storeResponse(res: any, updateCurrent = true) {
    savedLayouts.value = res.data.data;

    if (updateCurrent) {
      const current = _.find(res.data.data, ["isCurrent", true]);

      if (current == null) {
        currentLayoutId.value = 0;
      } else {
        currentLayoutId.value = current.id;
      }
    }

    status.setReady(Module.workspaceLayouts);
    isLoading.value = false;
  }

  /**
   * Aktuelles Layout laden
   */
  function loadCurrentLayout() {
    // Prüfen, ob es ein "Current Layout" gibt
    if (currentLayout.value == null) return;

    const layoutToLoad: WorkspaceProLayout = currentLayout.value.layout;

    emitter.emit("workspace.loadLayout", layoutToLoad);

    // Auf dem Server updaten, dass dieses Layout nun aktiv ist
    const { post } = useAxios();
    if (currentLayout.value.id > 0)
      post(`/api/trading/layouts/${currentLayout.value.id}/setCurrent`);
  }

  function loadSavedLayout(layoutId: number) {
    currentLayoutId.value = layoutId;
    loadCurrentLayout();
  }

  function loadTemplate(template: WorkspaceProLayoutTemplate) {
    currentLayoutId.value = template.id;
    loadCurrentLayout();
  }

  /**
   * Lädt die gespeicherten Layouts vom Server
   */
  async function fetchSavedLayouts() {
    // Status-Flag setzen
    status.setLoading(Module.workspaceLayouts);

    // Lade-Modus aktivieren
    isLoading.value = true;

    const { get } = useAxios();
    return await get("/api/trading/layouts")
      .then((res) => {
        storeResponse(res);
      })
      .catch((e) => {
        status.setError(Module.workspaceLayouts, e.message);
      });
  }

  return {
    templates,
    currentLayoutId,
    currentLayout,
    savedLayouts,
    currentLayoutSerialized,
    loadCurrentLayout,
    loadSavedLayout,
    fetchSavedLayouts,
    saveCurrentLayout,
    isLoading,
    loadTemplate
  };
});
