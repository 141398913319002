<template>
  <AbstractDialog ref="abstractDialog" :icon="VideoCameraIcon" cancel-text="Schließen">
    <template #title>Informationen zum Copy-Trading</template>
    <template #content>
      <div class="w-full h-full pt-4">
        <div style="padding: 56.25% 0 0; position: relative">
          <iframe
            allowfullscreen=""
            src="https://vimeo.com/showcase/9998714/embed"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          />
        </div>
      </div>
    </template>
  </AbstractDialog>
</template>

<script>
import AbstractDialog from "../../../../AbstractDialog.vue";
import { VideoCameraIcon } from "@heroicons/vue/24/outline";

export default {
  name: "HtInfovideo",

  components: {
    AbstractDialog
  },

  data() {
    return {
      name
    };
  },
  methods: { VideoCameraIcon }
};
</script>
