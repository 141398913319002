<template>
  <div :class="['h-screen w-screen', flex]">
    <!-- Navigation -->
    <HtNavigation></HtNavigation>

    <!-- Inhalt -->
    <div id="content" class="flex-grow overflow-hidden">
      <router-view />
    </div>

    <!-- Provider laden -->
    <component :is="provider" v-for="provider in providers" :key="provider" />
  </div>
</template>

<script setup>
import DialogManager from "./components/common/DialogManager.vue";
import NotificationManager from "./components/common/NotificationManager.vue";
import HtNavigation from "./components/navigation/HtMenu.vue";
import { computed } from "vue";
import { isLandscape, isMobile } from "./shared/composables/mobile";
import DebugInformation from "./components/common/DebugInformation.vue";
import PerformanceChecker from "./views/workspace/PerformanceChecker.vue";

// Provider registrieren
const providers = [DialogManager, NotificationManager, DebugInformation, PerformanceChecker];

// Flex
const flex = computed(() =>
  isMobile ? (isLandscape.value ? "flex flex-row" : "flex flex-col") : "flex flex-row"
);
</script>
