<template>
  <div class="group w-4 h-4 relative m-auto cursor-help">
    <ExclamationTriangleIcon
      v-if="isWarning"
      class="w-4 h-4 text-orange-500 dark:text-orange-500"
    />
    <QuestionMarkCircleIcon v-else class="w-4 h-4 text-slate-600 dark:text-slate-300" />
    <div
      :class="[
        toLeft ? '-left-72 -top-3 ml-4' : 'left-8 -top-3',
        'min-h-[46px] hidden group-hover:flex flex absolute shadow box-shadow w-64 text-sm bg-slate-700 dark:bg-black dark:text-slate-300 text-slate-200 px-3 py-2 rounded-md z-40'
      ]"
    >
      <div class="whitespace-normal my-auto">
        <slot />
      </div>

      <div
        :style="toLeft ? 'right: -12px;' : 'left: -12px;'"
        class="w-3 overflow-hidden inline-block absolute top-3.5"
      >
        <div
          :class="[
            toLeft ? 'rotate-45 origin-top-left' : '-rotate-45 origin-top-right',
            'h-4 bg-slate-700 dark:bg-black transform '
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationTriangleIcon, QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";

export default {
  name: "HtExplanationText",
  components: {
    QuestionMarkCircleIcon,
    ExclamationTriangleIcon
  },
  props: {
    toLeft: {
      type: Boolean,
      required: false,
      default: false
    },

    toRight: {
      type: Boolean,
      required: false,
      default: true
    },

    isWarning: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style scoped></style>
