import c from "currency.js";

type ArithmeticFn = (a: number, b: number, precision?: number) => number;
const add: ArithmeticFn = (a, b, precision = 2) => Number(c(a, { precision }).add(b));
const subtract: ArithmeticFn = (a, b, precision = 2) => Number(c(a, { precision }).subtract(b));
const multiply: ArithmeticFn = (a, b, precision = 2) => Number(c(a, { precision }).multiply(b));
const divide: ArithmeticFn = (a, b, precision = 2) => Number(c(a, { precision }).divide(b));

export default class BaseCalculator {
  private readonly precision = 6;

  public add = (a: number, b: number): number => {
    return this.canCalc(a, b) ? add(a, b, this.precision) : 0;
  };

  public subtract = (a: number, b: number): number => {
    return this.canCalc(a, b) ? subtract(a, b, this.precision) : 0;
  };

  public multiply = (a: number, b: number): number => {
    return this.canCalc(a, b) ? multiply(a, b, this.precision) : 0;
  };

  public divide = (a: number, b: number): number => {
    return this.canCalc(a, b, "and") ? divide(a, b, this.precision) : 0;
  };

  protected reduceDecimals = (value: number, fractionDigits = 0): number => {
    return Number(value.toFixed(fractionDigits));
  };

  protected canCalc(a: number, b: number, disjunction: "or" | "and" = "or"): boolean {
    return {
      or: a !== 0 || b !== 0,
      and: a !== 0 && b !== 0
    }[disjunction];
  }
}
