import { WorkspaceProLayout } from "../../shared/types/WorkspaceApp.ts";

export const TemplateHeldentalTradingTools: WorkspaceProLayout = {
  instances: [
    {
      app: {
        id: "screener",
        name: "Aktien-Screener",
        emoji: "🔍",
        visibility: 2,
        configuration: { isNeutral: true },
        vueComponentName: "Screener"
      },
      uuid: "00000000-bf81-4087-a578-d9c03a4afe4b",
      settings: {
        order: {
          parameter: "day.v",
          directionLargeToSmall: false
        },
        filter: {
          fmv: {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "day.v": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.g": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          ticker: null,
          "calc.dv": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.pc": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.atr": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.v_avg": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.v_rel": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          }
        }
      },
      selectorMenu: "#window_00000000-bf81-4087-a578-d9c03a4afe4b_menu",
      selectorContent: "#window_00000000-bf81-4087-a578-d9c03a4afe4b_content"
    },
    {
      app: {
        id: "news",
        name: "News",
        emoji: "🗞",
        visibility: 2,
        configuration: { permission: "tradingPlatform.news.view" },
        vueComponentName: "News"
      },
      uuid: "00000000-f8e7-4940-8682-3b588afe99e3",
      settings: [],
      selectorMenu: "#window_00000000-f8e7-4940-8682-3b588afe99e3_menu",
      selectorContent: "#window_00000000-f8e7-4940-8682-3b588afe99e3_content"
    },
    {
      app: {
        id: "watchlist",
        name: "Visuelle Watchlist",
        emoji: "👀",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.watchlist.view" },
        vueComponentName: "Watchlist"
      },
      uuid: "00000000-710a-4014-8861-a116206763b6",
      settings: [],
      selectorMenu: "#window_00000000-710a-4014-8861-a116206763b6_menu",
      selectorContent: "#window_00000000-710a-4014-8861-a116206763b6_content"
    },
    {
      app: {
        id: "scan",
        name: "Scan",
        emoji: "🗒️",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.scanner.view" },
        defaultSettings: { scannerType: "volume" },
        vueComponentName: "Scan"
      },
      uuid: "00000000-3eff-472c-aa64-72ed7abc487b",
      settings: { scannerType: "upMomentum" },
      selectorMenu: "#window_00000000-3eff-472c-aa64-72ed7abc487b_menu",
      selectorContent: "#window_00000000-3eff-472c-aa64-72ed7abc487b_content"
    },
    {
      app: {
        id: "scan",
        name: "Scan",
        emoji: "🗒️",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.scanner.view" },
        defaultSettings: { scannerType: "volume" },
        vueComponentName: "Scan"
      },
      uuid: "00000000-50da-45ad-b696-8210333e5e52",
      settings: { scannerType: "downMomentum" },
      selectorMenu: "#window_00000000-50da-45ad-b696-8210333e5e52_menu",
      selectorContent: "#window_00000000-50da-45ad-b696-8210333e5e52_content"
    },
    {
      app: {
        id: "scan",
        name: "Scan",
        emoji: "🗒️",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.scanner.view" },
        defaultSettings: { scannerType: "volume" },
        vueComponentName: "Scan"
      },
      uuid: "00000000-17a8-42a2-b09a-833b2b205ffe",
      settings: { scannerType: "bearish" },
      selectorMenu: "#window_00000000-17a8-42a2-b09a-833b2b205ffe_menu",
      selectorContent: "#window_00000000-17a8-42a2-b09a-833b2b205ffe_content"
    },
    {
      app: {
        id: "scan",
        name: "Scan",
        emoji: "🗒️",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.scanner.view" },
        defaultSettings: { scannerType: "volume" },
        vueComponentName: "Scan"
      },
      uuid: "00000000-4425-46f8-a188-8d59b6dd4a82",
      settings: { scannerType: "bullish" },
      selectorMenu: "#window_00000000-4425-46f8-a188-8d59b6dd4a82_menu",
      selectorContent: "#window_00000000-4425-46f8-a188-8d59b6dd4a82_content"
    },
    {
      app: {
        id: "tradingView",
        name: "TradingView",
        iconPath: "/images/icons/trading_view.svg",
        visibility: 2,
        configuration: { permission: "tradingPlatform.chart.view", hideMenuBar: true },
        vueComponentName: "TradingView",
        maximumNumberOfInstances: 1
      },
      uuid: "00000000-6499-4afd-aff0-46169e920d95",
      settings: [],
      selectorMenu: "#window_00000000-6499-4afd-aff0-46169e920d95_menu",
      selectorContent: "#window_00000000-6499-4afd-aff0-46169e920d95_content"
    }
  ],
  goldenLayout: {
    root: {
      id: null,
      size: "50%",
      type: "row",
      content: [
        {
          id: null,
          size: "50%",
          type: "column",
          content: [
            {
              id: null,
              size: "50%",
              type: "stack",
              content: [
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Visuelle Watchlist",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-710a-4014-8861-a116206763b6",
                    appId: "watchlist"
                  },
                  reorderEnabled: true
                }
              ],
              maximised: false,
              isClosable: true,
              activeItemIndex: 0
            },
            {
              id: null,
              size: "50%",
              type: "stack",
              content: [
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "TradingView: AAPL",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-6499-4afd-aff0-46169e920d95",
                    appId: "tradingView"
                  },
                  reorderEnabled: true
                }
              ],
              maximised: false,
              isClosable: true,
              activeItemIndex: 0
            }
          ],
          isClosable: true
        },
        {
          id: null,
          size: "50%",
          type: "column",
          content: [
            {
              id: null,
              size: "50%",
              type: "row",
              content: [
                {
                  id: null,
                  size: "25%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Scanner: Up-Momentum 📈",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-3eff-472c-aa64-72ed7abc487b",
                        appId: "scan"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                },
                {
                  id: null,
                  size: "25%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Scanner: Bullish 🐂",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-4425-46f8-a188-8d59b6dd4a82",
                        appId: "scan"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                },
                {
                  id: null,
                  size: "25%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Scanner: Down-Momentum 📉",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-50da-45ad-b696-8210333e5e52",
                        appId: "scan"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                },
                {
                  id: null,
                  size: "25%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Scanner: Bearish 🐻",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-17a8-42a2-b09a-833b2b205ffe",
                        appId: "scan"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                }
              ],
              isClosable: true
            },
            {
              id: null,
              size: "50%",
              type: "stack",
              content: [
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "News",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: { uuid: "00000000-f8e7-4940-8682-3b588afe99e3", appId: "news" },
                  reorderEnabled: true
                },
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Aktien-Screener",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-bf81-4087-a578-d9c03a4afe4b",
                    appId: "screener"
                  },
                  reorderEnabled: true
                }
              ],
              maximised: false,
              isClosable: true,
              activeItemIndex: 1
            }
          ],
          isClosable: true
        }
      ],
      minSize: "0px",
      isClosable: true
    },
    header: {
      dock: "dock",
      show: "top",
      close: "close",
      popout: "open in new window",
      maximise: "maximise",
      minimise: "minimise",
      tabDropdown: "additional tabs"
    },
    settings: {
      popInOnClose: false,
      reorderEnabled: true,
      responsiveMode: "none",
      popoutWholeStack: false,
      tabControlOffset: 10,
      tabOverlapAllowance: 0,
      closePopoutsOnUnload: true,
      reorderOnTabMenuClick: true,
      blockedPopoutsThrowError: true,
      constrainDragToContainer: true
    },
    dimensions: {
      borderWidth: 5,
      headerHeight: 34,
      dragProxyWidth: 300,
      borderGrabWidth: 5,
      dragProxyHeight: 200,
      defaultMinItemWidth: "10px",
      defaultMinItemHeight: "0px"
    },
    openPopouts: []
  }
};
