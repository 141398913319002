import { notify } from "@kyvg/vue3-notification";

export type MessageType = "error" | "success" | "warn" | "info";
type INotifierMethodOpts = { title?: string; text: string };

export class NotificationService {
  public error({ title = "Error", text }: INotifierMethodOpts): void {
    this.handleMessage(title, text, "error");
  }

  public success({ title = "Success", text }: INotifierMethodOpts): void {
    this.handleMessage(title, text, "success");
  }

  public warn({ title = "Warning", text }: INotifierMethodOpts): void {
    this.handleMessage(title, text, "warn");
  }

  public info({ title = "Information", text }: INotifierMethodOpts): void {
    this.handleMessage(title, text, "info");
  }

  private handleMessage(title: string, text = "", type: MessageType): void {
    notify({
      title,
      text,
      type
    });
  }
}
