<template>
  <div
    v-if="isDebug && !hidden"
    class="absolute top-1 right-1 text-white bg-black/50 font-mono z-[9999] text-xs flex flex-col text-right p-2"
    @click="hidden = true"
  >
    <!-- RAM -->
    <div>
      <span class="font-bold">RAM: </span>
      <span>
        {{ Math.round(memory?.usedJSHeapSize / 1024 / 1024) }} /
        {{ Math.round(memory?.totalJSHeapSize / 1024 / 1024) }} MB (max.
        {{ Math.round(memory?.jsHeapSizeLimit / 1024 / 1024) }} MB)</span
      >
    </div>

    <!-- Netzwerk -->
    <div>
      <span class="font-bold">Netzwerk: </span>
      <span>
        {{ isOnline ? "online" : "offline" }}, mind. {{ downlink }} MBit/s<br />
        {{ rtt }} ms RTT, {{ effectiveType }}, {{ saveData ? "Datensparmodus" : "Normal" }}
      </span>
    </div>

    <!-- FPS -->
    <div>
      <span class="font-bold">FPS: </span>
      <span> {{ fps }} 1/s</span>
    </div>
  </div>
</template>

<script setup>
import { isDebug } from "../../shared/composables/debug.ts";
import { useFps, useMemory, useNetwork } from "@vueuse/core";
import { ref } from "vue";

const hidden = ref(false);

// Leistungsdaten für Debug
const { isOnline, downlink, effectiveType, saveData, type, rtt } = useNetwork();
const { memory } = useMemory();
const fps = useFps();
</script>
