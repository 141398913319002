<template>
  <TransitionRoot :show="isOpen" as="template">
    <Dialog as="div" class="fixed z-40 inset-0 overflow-y-auto" @close="$parent.$parent.close()">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span aria-hidden="true" class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <form
            action="#"
            class="relative inline-block align-bottom bg-white dark:bg-slate-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            @submit="handleSubmit()"
          >
            <div class="sm:flex sm:items-start">
              <div
                :class="[
                  isSubmitDanger ? 'bg-red-100 dark:bg-red-900' : 'bg-blue-100 dark:bg-blue-900',
                  'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10'
                ]"
              >
                <component
                  :is="icon"
                  v-if="icon != null"
                  :class="[
                    isSubmitDanger
                      ? 'text-red-600 dark:text-red-300'
                      : 'text-blue-600 dark:text-blue-300',
                    'h-6 w-6'
                  ]"
                  aria-hidden="true"
                  stroke="currentColor"
                />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <div id="modal-title" class="h-10 flex">
                  <div class="text-lg font-medium my-auto dark:text-slate-100">
                    <slot name="title" />
                  </div>
                </div>
                <div class="mt-2 text-slate-500 dark:text-slate-300 text-base w-full">
                  <slot name="content" />
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                v-if="showSubmit"
                :class="[
                  isSubmitDanger
                    ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500 dark:bg-red-900 dark:hover:bg-red-800 dark:ring-offset-slate-900'
                    : 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 dark:bg-blue-900 dark:hover:bg-blue-800 dark:ring-offset-slate-900',
                  'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto'
                ]"
                type="submit"
              >
                {{ submitText }}
              </button>
              <button
                v-if="showCancel"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-slate-300 shadow-sm px-4 py-2 bg-white dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300 dark:hover:bg-slate-700 text-base font-medium text-slate-700 hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-offset-slate-900 sm:mt-0 sm:w-auto"
                type="button"
                @click="$parent.$parent.cancel()"
              >
                {{ cancelText }}
              </button>
            </div>
          </form>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";

export default {
  components: {
    Dialog,
    DialogOverlay,

    TransitionChild,
    TransitionRoot
  },

  props: {
    showCancel: {
      required: false,
      type: Boolean,
      default: true
    },
    showSubmit: {
      required: false,
      type: Boolean,
      default: false
    },
    cancelText: {
      required: false,
      type: String,
      default: "Abbrechen"
    },
    submitText: {
      required: false,
      type: String,
      default: "Absenden"
    },

    isSubmitDanger: {
      required: false,
      type: Boolean,
      default: false
    },

    onSubmit: {
      required: false,
      type: Function,
      default: null
    },

    icon: {
      required: false,
      default: InformationCircleIcon
    }
  },

  setup() {
    const isOpen = ref(false);

    return {
      isOpen
    };
  },

  methods: {
    handleSubmit() {
      // Submit im DialogManager auslösen und Event auslösen
      this.$parent?.$parent?.submit();

      // Falls eine eigene Funktion übergeben wurde, diese ausführen
      if (this.$props.onSubmit) {
        this.$props.onSubmit();
      }

      // Dialog schließen
      this.isOpen = false;
    }
  }
};
</script>
