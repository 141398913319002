import { defineStore } from "pinia";
import { ref, watch } from "vue";

import _ from "lodash";
import router from "./../router";
import { useStockdata } from "./stockdata";
import { debug, info } from "../shared/services/LoggingService";
import { useAxios } from "./axios.ts";
import { useStorage } from "@vueuse/core";
import { useSettings } from "./settings.ts";
import { StockdataWorkerMethod } from "../shared/types/StockdataWorker";
import { AuthInformation, AuthState } from "../shared/types/Auth.ts";
import { Module, useStatus } from "./status.ts";
import { useWebsocket } from "./websocket.ts";

export const useAuth = defineStore("auth", () => {
  /** App-Status */
  const status = useStatus();

  /** Settings-Store */
  const settings = useSettings();

  /** Aktueller Login-Status */
  const state = ref<AuthState>(AuthState.UNKNOWN);

  /** Liste der Berechtigungen des Nutzers */
  const permissions = ref(null);

  /** Meta-Daten des Nutzers */
  const user = ref(null);

  /** Token-Storage, to keep token in local storage */
  const tokenStorage = useStorage("token", null);

  /** Stockdata-Store */
  const stockdata = useStockdata();

  /*
   * Watcher, der Änderungen des States beobachtet
   */
  watch(state, (newState, oldState) => {
    // Log-Ausgabe
    info("Changed status from", stateToName(oldState), "to", stateToName(newState));

    // Status-Änderung an Status-Store weiterreichen
    if (newState === AuthState.AUTHENTICATED) {
      status.setReady(Module.auth);
    } else if (newState === AuthState.UNAUTHENTICATED) {
      status.setError(Module.auth);
    } else if (newState === AuthState.MUTATING) {
      status.setLoading(Module.auth);
    }

    // State an Stockdata Worker weiterreichen
    stockdata.callWorker(StockdataWorkerMethod.AUTH_CHANGE, getInformation());
  });

  /**
   * Liefert true zurück, falls ein User angemeldet ist. In allen anderen Fällen false.
   * Je nach aktuellem State wird versucht eine alte Session wieder zu starten.
   * @returns {Promise<boolean>}
   */
  function isAuthenticated() {
    switch (state.value) {
      case AuthState.UNAUTHENTICATED:
        router.replace("/login");
        return false;
      case AuthState.AUTHENTICATED:
        return true;
      case AuthState.MUTATING:
        return false;
      case AuthState.UNKNOWN:
        checkToken();
        return false;
    }
  }

  /**
   * Translate ID of state back to human-readable name
   * @param value ID of state
   * @returns {string} Human-readable name of state
   */
  function stateToName(value) {
    switch (value) {
      case AuthState.UNAUTHENTICATED:
        return "Unauthenticated";
      case AuthState.AUTHENTICATED:
        return "Authenticated";
      case AuthState.MUTATING:
        return "Mutating";
      case AuthState.UNKNOWN:
        return "Unknown";
    }
  }

  /**
   * Führt einen Test-Request mit dem aktuellen Token durch
   */
  async function setTestRequestWithCurrentToken() {
    const { get } = useAxios();

    return get("/api/me")
      .then((res) => {
        return { success: true, response: res?.data };
      })
      .catch(() => {
        return { success: false, response: null };
      });
  }

  /**
   * Login durchführen
   * @param payload
   * @returns {Promise<T>}
   */
  async function login(payload) {
    // Axios laden
    const { post } = useAxios();

    // Login-Request schicken
    return post("/api/token", payload)
      .then(async (result) => {
        // Log-Ausgabe
        info("Login erfolgreich. Token erhalten und gespeichert.");

        // Token im Storage speichern
        tokenStorage.value = result.data.token;

        // Token prüfen
        await checkToken();
        return true;
      })
      .catch((error) => {
        // Im Fehlerfall Login verbieten und Fehlermeldung zurückgeben
        state.value = AuthState.UNAUTHENTICATED;
        return error?.response?.data?.message;
      });
  }

  /**
   * Startet eine neue Session
   * @returns {Promise<void>}
   */
  async function checkToken() {
    // Abbrechen, falls der State gerade schon geändert wird
    if (state.value === AuthState.MUTATING) return;

    // Status auf Mutating setzen, damit dies nur einmal gleichzeitig geschieht
    state.value = AuthState.MUTATING;

    // Prüfen, ob ein Token in der Storage liegt
    if (tokenStorage.value === null) {
      info("Es wurde kein Token gefunden. Anmeldung notwendig.");
      removeToken();
      return;
    }

    // Weiteres Handling, falls ein Token gefunden wurde
    info("Es wurde ein Token im Storage gefunden. Prüfe, ob der Token noch gültig ist.");

    // Test-Request mit dem Token durchführen
    const { success, response } = await setTestRequestWithCurrentToken();

    // Falls der Test-Request erfolgreich war, die Daten speichern
    if (success) {
      info("Token erfolgreich validiert. Keine Anmeldung notwendig.");
      storeUserData(response);
    }

    // Falls der Test-Request nicht erfolgreich war, die Session beenden
    else {
      info("Der Token ist nicht (mehr gültig). Anmeldung notwendig.");
      removeToken();
    }
  }

  function storeUserData(payload) {
    if (payload === undefined) {
      state.value = AuthState.UNAUTHENTICATED;
      return;
    }

    // Userdaten speichern
    user.value = payload?.user;
    permissions.value = payload?.permissions;

    // State setzen
    state.value = AuthState.AUTHENTICATED;

    setTimeout(() => {
      // Settings laden
      const settings = useSettings();
      settings.loadSettings();

      // Verbindung zu Pusher herstellen
      const websocket = useWebsocket();
      websocket.connect();
    }, 0);

    // Weiterleitung
    router.replace("/workspace");
  }

  /**
   * Testet ob, der eingeloggte Nutzer die angegebene Berechtigung hat
   * @param permission
   * @returns {boolean}
   */
  function userHasPermission(permission) {
    // Keine Abfrage, wenn Nutzer nicht angemeldet ist
    if (!isAuthenticated()) {
      if (settings.local.logPermissionChecks)
        debug("Run permission check against " + permission + ": Not authenticated.");
      return false;
    }

    // Check gegen die gespeicherten Permissions
    const result = _.find(permissions.value, { name: permission }) !== undefined;
    if (settings.local.logPermissionChecks)
      debug("Run permission check against " + permission + ": " + (result ? "✅" : "⛔"));
    return result;
  }

  /**
   * Beendet die aktuelle Sitzung
   */
  function removeToken() {
    // Log-Ausgabe
    info("Aktuellen Token löschen und neue Anmeldung erzwingen.");

    // Token löschen
    tokenStorage.value = null;

    // State auf UNAUTHENTICATED setzen
    state.value = AuthState.UNAUTHENTICATED;

    // User und Permissions löschen
    user.value = null;
    permissions.value = null;

    // Weiterleiten zum Login-Formular
    router.replace("/login").then(() => info("Weiterleitung zur Login-Seite"));
  }

  const getInformation = function (): AuthInformation {
    return {
      state: state.value,
      token: tokenStorage.value,
      userId: user.value?.id
    };
  };

  return {
    login,
    killSession: removeToken,
    userHasPermission,
    isAuthenticated,
    checkToken,
    user,
    permissions,
    state,
    getInformation
  };
});
