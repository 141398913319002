import { defineStore } from "pinia";
import { Raw, ref } from "vue";
import { PriceAlert } from "../shared/types/PriceAlert.ts";
import { PolygonSymbol, PolygonTicker } from "../shared/types/Polygon.ts";
import { RawSecurity, Security } from "../shared/types/Trading.ts";
import { useAxios } from "./axios.ts";
import { useEtnaTrading } from "./etna-trading.ts";
import _ from "lodash";

export const useEtnaSecurities = defineStore("etna-securities", () => {
  // Axios zur Datenabfrage laden
  const trading = useEtnaTrading();

  /**
   * Zwischenspeicherung bereits geladener Ticker
   */
  const cache = ref<Record<PolygonSymbol, RawSecurity>>({});

  const getSymbolBySecurityId = async function (securityId: number): Promise<PolygonSymbol> {
    // Falls der Cache existiert, suche, ob die Security bereits im Cache existiert
    if (cache.value) {
      const searchInCache = _.find(
        cache.value,
        (security: RawSecurity) => security.Id == securityId
      );
      if (searchInCache) return searchInCache.Symbol as PolygonSymbol;
    }

    // Abfrage vom Server
    return (await requestFromServer(securityId)).Symbol as PolygonSymbol;
  };

  const getSecurityBySymbol = async function (symbol: PolygonSymbol): Promise<RawSecurity> {
    // Wert aus dem Cache liefern, falls verfügbar
    if (cache.value && symbol in cache.value) {
      return cache.value[symbol];
    }

    // Abfrage vom Server
    return await requestFromServer(symbol);
  };

  const requestFromServer = async function (symbolOrId: PolygonSymbol | number) {
    const response = await trading.getAxios().get(`/equities/${symbolOrId}`);

    if (response.status !== 200) {
      return null;
    }

    const security = response.data as RawSecurity;
    cache.value[security.Symbol] = security;

    return security;
  };

  return {
    getSecurityBySymbol,
    getSymbolBySecurityId
  };
});
