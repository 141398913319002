<template>
  <AbstractDialog
    ref="abstractDialog"
    :icon="ArrowDownOnSquareStackIcon"
    :show-submit="true"
    submit-text="Speichern als"
  >
    <template #title>Layout speichern als</template>
    <template #content>
      <p class="mb-2">
        Bitte gib den neuen Namen an, unter dem das Layout gespeichert werden soll.
      </p>
      <input
        v-model="name"
        class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full text-base border-slate-300 rounded-md"
        placeholder="Mein Layout"
        required
        type="text"
      />
    </template>
  </AbstractDialog>
</template>

<script>
import AbstractDialog from "../../AbstractDialog.vue";
import { ArrowDownOnSquareStackIcon } from "@heroicons/vue/24/outline";

export default {
  components: {
    AbstractDialog
  },

  data() {
    return {
      name
    };
  },
  methods: { ArrowDownOnSquareStackIcon }
};
</script>
