<template>
  <div
    class="h-14 w-8 rounded-full bg-slate-300 dark:bg-slate-700 p-1 pl-1 cursor-pointer transition-all"
    @click="toggleDark()"
  >
    <div
      :class="isDark ? 'mt-6' : 'mt-0'"
      class="w-6 h-6 py-0.5 px-0.5 rounded-full bg-slate-100 text-slate-700 dark:text-white dark:bg-slate-900 text-center transition-all"
    >
      <div v-if="isDark">
        <MoonIcon class="h-5 w-5" />
      </div>
      <div v-else>
        <SunIcon class="h-5 w-5" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useToggle } from "@vueuse/core";
import { MoonIcon, SunIcon } from "@heroicons/vue/20/solid";
import { isDark } from "./../../shared/composables/dark";

const toggleDark = useToggle(isDark);
</script>
