import { WorkspaceProLayout } from "../../shared/types/WorkspaceApp.ts";

const TemplateMarcWillmannMentoring: WorkspaceProLayout = {
  instances: [
    {
      app: {
        id: "tradingView",
        name: "TradingView",
        iconPath: "/images/icons/trading_view.svg",
        visibility: 2,
        configuration: { permission: "tradingPlatform.chart.view", hideMenuBar: true },
        vueComponentName: "TradingView",
        maximumNumberOfInstances: 1
      },
      uuid: "00000000-8f9a-4ebf-8602-9c6390303d24",
      settings: [],
      selectorMenu: "#window_00000000-8f9a-4ebf-8602-9c6390303d24_menu",
      selectorContent: "#window_00000000-8f9a-4ebf-8602-9c6390303d24_content"
    },
    {
      app: {
        id: "scan",
        name: "Scan",
        emoji: "🗒️",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.scanner.view" },
        defaultSettings: { scannerType: "volume" },
        vueComponentName: "Scan"
      },
      uuid: "00000000-3b62-4e90-99db-896c08a6548a",
      settings: { scannerType: "volume" },
      selectorMenu: "#window_00000000-3b62-4e90-99db-896c08a6548a_menu",
      selectorContent: "#window_00000000-3b62-4e90-99db-896c08a6548a_content"
    },
    {
      app: {
        id: "scan",
        name: "Scan",
        emoji: "🗒️",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.scanner.view" },
        defaultSettings: { scannerType: "volume" },
        vueComponentName: "Scan"
      },
      uuid: "00000000-8127-4375-b00b-ca33c440449e",
      settings: { scannerType: "upMomentum" },
      selectorMenu: "#window_00000000-8127-4375-b00b-ca33c440449e_menu",
      selectorContent: "#window_00000000-8127-4375-b00b-ca33c440449e_content"
    },
    {
      app: {
        id: "scan",
        name: "Scan",
        emoji: "🗒️",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.scanner.view" },
        defaultSettings: { scannerType: "volume" },
        vueComponentName: "Scan"
      },
      uuid: "00000000-7f2b-4f8e-958c-0cf0427a8575",
      settings: { scannerType: "downMomentum" },
      selectorMenu: "#window_00000000-7f2b-4f8e-958c-0cf0427a8575_menu",
      selectorContent: "#window_00000000-7f2b-4f8e-958c-0cf0427a8575_content"
    },
    {
      app: {
        id: "watchlist",
        name: "Visuelle Watchlist",
        emoji: "👀",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.watchlist.view" },
        vueComponentName: "Watchlist"
      },
      uuid: "00000000-27a9-477b-b99f-088697ec548b",
      settings: [],
      selectorMenu: "#window_00000000-27a9-477b-b99f-088697ec548b_menu",
      selectorContent: "#window_00000000-27a9-477b-b99f-088697ec548b_content"
    },
    {
      app: {
        id: "signals",
        name: "Trading Coach Signals",
        emoji: "🔋",
        visibility: 2,
        configuration: {
          isNeutral: true,
          permission: "tradingPlatform.signals.show",
          hideMenuBar: true
        },
        vueComponentName: "Signals"
      },
      uuid: "00000000-1139-47a5-b2ce-f8332cc01cfb",
      settings: [],
      selectorMenu: "#window_00000000-1139-47a5-b2ce-f8332cc01cfb_menu",
      selectorContent: "#window_00000000-1139-47a5-b2ce-f8332cc01cfb_content"
    },
    {
      app: {
        id: "screener",
        name: "Aktien-Screener",
        emoji: "🔍",
        visibility: 2,
        configuration: { isNeutral: true },
        vueComponentName: "Screener"
      },
      uuid: "00000000-83fd-4c2b-a6c7-d6719fd08a3a",
      settings: {
        order: {
          parameter: "day.v",
          directionLargeToSmall: false
        },
        filter: {
          fmv: {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "day.v": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.g": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          ticker: null,
          "calc.dv": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.pc": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.atr": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.v_avg": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.v_rel": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          }
        }
      },
      selectorMenu: "#window_00000000-83fd-4c2b-a6c7-d6719fd08a3a_menu",
      selectorContent: "#window_00000000-83fd-4c2b-a6c7-d6719fd08a3a_content"
    }
  ],
  goldenLayout: {
    root: {
      id: null,
      size: "1fr",
      type: "row",
      content: [
        {
          id: null,
          size: "50%",
          type: "stack",
          content: [
            {
              id: null,
              size: "1fr",
              type: "component",
              title: "TradingView: AAPL",
              maximised: false,
              isClosable: true,
              componentType: "vueComponentWindow",
              componentState: {
                uuid: "00000000-8f9a-4ebf-8602-9c6390303d24",
                appId: "tradingView"
              },
              reorderEnabled: true
            }
          ],
          maximised: false,
          isClosable: true,
          activeItemIndex: 0
        },
        {
          id: null,
          size: "50%",
          type: "column",
          content: [
            {
              id: null,
              size: "50%",
              type: "row",
              content: [
                {
                  id: null,
                  size: "20%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Scanner: Volumen 📊",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-3b62-4e90-99db-896c08a6548a",
                        appId: "scan"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                },
                {
                  id: null,
                  size: "20%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Scanner: Down-Momentum 📉",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-7f2b-4f8e-958c-0cf0427a8575",
                        appId: "scan"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                },
                {
                  id: null,
                  size: "20%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Scanner: Up-Momentum 📈",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-8127-4375-b00b-ca33c440449e",
                        appId: "scan"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                },
                {
                  id: null,
                  size: "40%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Visuelle Watchlist",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-27a9-477b-b99f-088697ec548b",
                        appId: "watchlist"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                }
              ],
              isClosable: true
            },
            {
              id: null,
              size: "50%",
              type: "stack",
              content: [
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Trading Coach Signals",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-1139-47a5-b2ce-f8332cc01cfb",
                    appId: "signals"
                  },
                  reorderEnabled: true
                },
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Aktien-Screener",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-83fd-4c2b-a6c7-d6719fd08a3a",
                    appId: "screener"
                  },
                  reorderEnabled: true
                }
              ],
              maximised: false,
              isClosable: true,
              activeItemIndex: 0
            }
          ],
          isClosable: true
        }
      ],
      isClosable: true
    },
    header: {
      dock: "dock",
      show: "top",
      close: "close",
      popout: "open in new window",
      maximise: "maximise",
      minimise: "minimise",
      tabDropdown: "additional tabs"
    },
    settings: {
      popInOnClose: false,
      reorderEnabled: true,
      responsiveMode: "none",
      popoutWholeStack: false,
      tabControlOffset: 10,
      tabOverlapAllowance: 0,
      closePopoutsOnUnload: true,
      reorderOnTabMenuClick: true,
      blockedPopoutsThrowError: true,
      constrainDragToContainer: true
    },
    dimensions: {
      borderWidth: 5,
      headerHeight: 34,
      dragProxyWidth: 300,
      borderGrabWidth: 5,
      dragProxyHeight: 200,
      defaultMinItemWidth: "10px",
      defaultMinItemHeight: "0px"
    },
    openPopouts: []
  }
};
export default TemplateMarcWillmannMentoring;
