import { Position, PositionBase, TickerETNA } from "../../types/Trading.ts";
import { useEtnaTrading } from "../../../stores/etna-trading.ts";
import { PositionService } from "../PositionService.ts";
import {
  createPosition,
  updatePositionByCurrentPrice
} from "../../factories/Trading/PositionFactory.ts";

export class GetOpenPositionBySymbol {
  constructor(private readonly setOpenPosition: (data?: PositionBase) => void) {}

  exec = async (symbol: string, ticker?: TickerETNA): Promise<PositionBase | undefined> => {
    const tradingStore = useEtnaTrading();
    const service = new PositionService(tradingStore.getAxios());

    try {
      const { data: rawPositions } = await service.getPositionBySymbol(
        tradingStore.accountId as unknown as number,
        symbol
      );
      const position = rawPositions.length ? createPosition(rawPositions[0]) : undefined;
      const openPosition =
        position && position.quantity !== 0 ? this.toOpenPosition(position, ticker) : undefined;

      this.setOpenPosition(openPosition);

      return openPosition;
    } catch (e) {
      console.error(e, "error while trying to execute getPositionBySymbol");
    }
  };

  private toOpenPosition(position: Position, ticker?: TickerETNA): PositionBase {
    const resPosition =
      "plOpen" in position
        ? position
        : ticker?.lastPrice
          ? updatePositionByCurrentPrice(position, ticker?.lastPrice)
          : position;

    return {
      type: resPosition.type,
      quantity: resPosition.quantity,
      avgOpenPrice: resPosition.avgOpenPrice,
      plOpen: resPosition.plOpen
    };
  }
}
