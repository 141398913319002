import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import _, { isEmpty, uniq } from "lodash";
import { useEtnaTrading } from "./etna-trading.ts";
import { PositionService } from "./../shared/services/PositionService";
import {
  createPosition,
  updatePositionByCurrentPrice
} from "./../shared/factories/Trading/PositionFactory.ts";
import { useStockdata } from "./stockdata";
import { Position, RawPosition } from "../shared/types/Trading.ts";
import emitter from "../shared/services/EventService.ts";
import {
  PolygonSnapshotTickerExtendedWithLocalCalculation,
  PolygonSymbol
} from "../shared/types/Polygon.ts";
import { Module, useStatus } from "./status.ts";
import { useEtnaAlerts } from "./etna-alerts.ts";

export const useEtnaPositions = defineStore("enta-positions", () => {
  const trading = useEtnaTrading();
  const stockData = useStockdata();

  /** Globaler App-Status */
  const status = useStatus();

  const isStoreInit = ref<boolean>(false);
  const isStoreHasInitError = ref<boolean>(false);
  const positions = ref<Record<string, Position>>({});

  const uniqPositionSymbols = computed<string[]>(() =>
    uniq<string>(Object.values(positions.value).map(({ symbol }) => symbol))
  );

  watch(uniqPositionSymbols, (newState, oldState) => {
    const newSub = newState.filter((x) => !oldState.includes(x));
    const newUnsub = oldState.filter((x) => !newState.includes(x));

    newSub.forEach((symbol) => {
      stockData.subscribe({
        ticker: symbol, // Current ticker of window
        fields: ["realtime.fmv"], // Fields to subscribe
        source: "trading.positions" // UUID of window to make subscription unique
      });
    });

    newUnsub.forEach((symbol) => {
      stockData.unsubscribe({
        ticker: symbol, // Current ticker of window
        fields: ["realtime.fmv"], // Fields to subscribe
        source: "trading.positions" // UUID of window to make subscription unique
      });
    });
  });

  // Merge stockData.details current-price with each position entity
  watch(
    stockData.snapshots,
    (updatedStockDataDetails: PolygonSnapshotTickerExtendedWithLocalCalculation) => {
      if (!isStoreInit.value) return;

      const pricesBySymbol = uniqPositionSymbols.value.reduce(
        (acc, symbol) => {
          const currentPrice: number | undefined =
            updatedStockDataDetails[symbol]?.realtime?.fmv ?? updatedStockDataDetails[symbol]?.fmv;
          if (currentPrice) acc[symbol] = currentPrice;

          return acc;
        },
        {} as Record<string, number>
      );

      if (!isEmpty(pricesBySymbol)) {
        for (const orderId in positions.value) {
          const position = positions.value[orderId];
          const newCurrentPrice = pricesBySymbol[position.symbol] || 0;
          const isNewPriceNotTheSameAsOld = newCurrentPrice !== position.currentPrice;

          if (newCurrentPrice && isNewPriceNotTheSameAsOld) {
            positions.value[orderId] = updatePositionByCurrentPrice(position, newCurrentPrice);

            // Event, dass die PnL der Position aktualisiert wurde
            emitter.emit(`trading.position.updated.plOpen`, positions.value[orderId]);
          }
        }
      }
    }
  );

  /**
   * Funktion die beim Start aufgerufen wird, um alle Informationen zu sammeln und im lokalen Store zu speichern
   * @param {number} accountId ID des abzufragenden Accounts
   * @returns {Promise<void>}
   */
  async function boot(accountId: number): Promise<void> {
    try {
      // Positions vom Server laden
      await loadPositions(accountId);
      // Websocket abonnieren
      await trading.subscribeTo("Position", accountId);
      isStoreInit.value = true;
    } catch (e) {
      console.error(e, "while executing trading-positions boot");
      isStoreHasInitError.value = true;
    }
  }

  /**
   * Funktion die beim Beenden aufgerufen wird, um Subscriptions zu beenden und den lokalen Store zu leeren
   * @param {number} accountId ID des zu bereinigen Accounts
   * @returns {Promise<void>}
   */
  async function shutdown(accountId: number): Promise<void> {
    // Websocket de-abonnieren
    await trading.unsubscribeFrom("Position", accountId);

    // Lokale Orders löschen
    positions.value = {};
  }

  function handleMessage(rawPosition: RawPosition): void {
    patchPosition(createPosition(rawPosition));
  }

  /**
   * Lädt alle Orders des Accounts vom Server
   */
  const loadPositions = async (accountId: number): Promise<void> => {
    // Status-Flag setzen
    status.setLoading(Module.tradingPositions);

    const positionService = new PositionService(trading.getAxios());

    try {
      const {
        data: { Result }
      } = await positionService.getPositions(accountId);

      // Alle Positionen durchlaufen und in den Store speichern
      Result.forEach((rawPosition) => {
        patchPosition(createPosition(rawPosition));
      });

      // Status-Flag setzen
      status.setReady(Module.tradingPositions);
    } catch (e) {
      console.error(e, "while executing loadPositions");

      // Status-Flag setzen
      status.setError(Module.tradingPositions, e.message);
    }
  };

  /**
   * Updated oder speichert die lokal gespeicherte Position
   * @param position
   */
  function patchPosition(position: Position) {
    const storePositionId = position.id;
    const existingPosition = positions.value[storePositionId];

    // Emit the order patch event
    emitter.emit(`trading.position.updated`, position);

    // The check below is necessary to display the latest created order as the very first one
    if (existingPosition) {
      positions.value = { ...positions.value };
      positions.value[storePositionId] = position;
    } else {
      positions.value = {
        [storePositionId]: position,
        ...positions.value
      };
    }

    // Check if position is closed after update and delete alerts
    if (position.quantity === 0) {
      const etnaAlerts = useEtnaAlerts();
      _.map(etnaAlerts.alerts, (alert) => {
        if (alert.Symbol == position.symbol) {
          etnaAlerts.remove(alert.Id);
        }
      });
    }
  }

  const getPosition = computed(() => {
    return (symbol: PolygonSymbol): Position | undefined => {
      return _.find(
        positions.value,
        (position) => position.symbol === symbol && position.quantity !== 0
      );
    };
  });

  return {
    boot,
    shutdown,
    handleMessage,
    getPosition,
    uniqPositionSymbols,
    positions,
    isStoreInit,
    isStoreHasInitError
  };
});
