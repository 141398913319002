<template>
  <AbstractDialog
    ref="abstractDialog"
    :icon="TrashIcon"
    :is-submit-danger="true"
    :show-submit="true"
    submit-text="Layout löschen"
  >
    <template #title>Layout wirklich löschen?</template>
    <template #content>
      <p class="mb-2">
        Soll das Layout wirklich gelöscht werden? Das kann nicht rückgängig gemacht werden!
      </p>
    </template>
  </AbstractDialog>
</template>

<script>
import AbstractDialog from "../../AbstractDialog.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";

export default {
  components: {
    AbstractDialog
  },

  data() {
    return {
      layoutId: this.$attrs.layoutId
    };
  },
  methods: { TrashIcon }
};
</script>
