<template>
  <HtMenuPopupTitle :icon="LifebuoyIcon" name="Hilfe"></HtMenuPopupTitle>
  <div class="grid grid-cols-1 w-max">
    <HtMenuPopupSectionTitle>Anwendung</HtMenuPopupSectionTitle>

    <HtMenuPopupLink emoji="⚡" @click="restart()">Neustart der Anwendung</HtMenuPopupLink>

    <HtMenuPopupLink emoji="ℹ️" @click="showInformationDialog()">Informationen</HtMenuPopupLink>

    <HtMenuPopupSectionTitle>Sicherheit</HtMenuPopupSectionTitle>

    <HtMenuPopupLink emoji="🔑️" @click="reloadPermissions()"
      >Berechtigungen neu laden</HtMenuPopupLink
    >
  </div>
</template>

<script>
import { useAuth } from "../../../stores/auth.ts";
import emitter from "../../../shared/services/EventService.ts";
import HtMenuPopupSectionTitle from "../../ui/HtMenuPopupSectionTitle.vue";
import HtMenuPopupLink from "../../ui/HtMenuPopupLink.vue";
import HtMenuPopupTitle from "../../ui/HtMenuPopupTitle.vue";
import { LifebuoyIcon } from "@heroicons/vue/24/outline";

export default {
  components: { HtMenuPopupLink, HtMenuPopupSectionTitle, HtMenuPopupTitle },

  methods: {
    LifebuoyIcon,

    showInformationDialog() {
      emitter.emit("dialog:informationDialog");
    },

    showAppSettingsDialog() {
      emitter.emit("dialog:appSettingsDialog");
    },

    reloadPermissions() {
      // Kill session
      const auth = useAuth();
      auth.killSession(true);

      // Reload website
      window.location.reload();
    },

    restart() {
      window.location.reload();
    }
  }
};
</script>

<style scoped></style>
