<template>
  <div>
    <HtMenuPopupTitle :icon="RectangleStackIcon" name="Layout"></HtMenuPopupTitle>
    <div class="grid grid-cols-3 gap-4 w-max">
      <div>
        <div v-if="layouts.currentLayoutId > 0">
          <HtMenuPopupSectionTitle>
            <span class="font-normal text-slate-500">aktuelles Layout</span>
            <br />
            {{ layouts.currentLayout.name }}
          </HtMenuPopupSectionTitle>

          <HtMenuPopupLink :icon="ArrowDownOnSquareIcon" @click="layouts.saveCurrentLayout()">
            Speichern
          </HtMenuPopupLink>

          <HtMenuPopupLink :icon="ArrowDownOnSquareStackIcon" @click="saveAs()">
            Speichern als
          </HtMenuPopupLink>

          <HtMenuPopupLink :icon="BackspaceIcon" @click="layouts.loadCurrentLayout()">
            Änderungen verwerfen
          </HtMenuPopupLink>
          <div class="border-slate-200 dark:border-slate-700 border-t my-2" />
        </div>

        <div v-else>
          <HtMenuPopupSectionTitle>Aktionen</HtMenuPopupSectionTitle>

          <HtMenuPopupLink :icon="ArrowDownOnSquareStackIcon" @click="saveAs()">
            Speichern als
          </HtMenuPopupLink>
        </div>

        <HtMenuPopupLink :icon="PlusIcon" @click="createNewLayout()">
          Leeres Layout erstellen
        </HtMenuPopupLink>
      </div>

      <div class="col-span-2 overflow-scroll scrollbar-hide max-h-60">
        <HtMenuPopupSectionTitle>Vorlagen</HtMenuPopupSectionTitle>

        <template v-for="template in layouts.templates">
          <HtMenuPopupLink
            v-if="template.permission == null || auth.userHasPermission(template.permission)"
            :key="template.name"
            :icon="ChevronRightIcon"
          >
            <div class="flex w-full">
              <div class="flex-grow" @click="layouts.loadTemplate(template)">
                {{ template?.name }}
              </div>
            </div>
          </HtMenuPopupLink>
        </template>

        <HtMenuPopupSectionTitle>Gespeicherte Layouts</HtMenuPopupSectionTitle>

        <div>
          <HtMenuPopupLink
            v-for="layout in layouts.savedLayouts"
            :key="layout.id"
            :icon="ChevronRightIcon"
          >
            <div class="flex w-full">
              <div class="flex-grow" @click="layouts.loadSavedLayout(layout.id)">
                {{ layout?.name }}
              </div>

              <div class="flex gap-2">
                <div
                  class="h-5 w-5 text-slate-600 hover:text-red-600"
                  @click="deleteSavedLayout(layout.id)"
                >
                  <TrashIcon class="w-5 h-5" />
                </div>
              </div>
            </div>
          </HtMenuPopupLink>
        </div>

        <HtMenuPopupSectionTitle v-if="Object.keys(layouts.savedLayouts).length === 0">
          <div class="text-slate-500 text-base font-normal">
            <p>Es wurden noch keine Layouts gespeichert.</p>
            <p class="pt-2">
              Klicken Sie auf
              <span class="font-medium">Speichern als</span>
              , um
              <br />
              das aktuelle layout zu speichern.
            </p>
          </div>
        </HtMenuPopupSectionTitle>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ArrowDownOnSquareIcon,
  ArrowDownOnSquareStackIcon,
  BackspaceIcon,
  ChevronRightIcon,
  RectangleStackIcon,
  TrashIcon
} from "@heroicons/vue/24/outline";
import { useWorkspaceLayoutStore } from "../../../stores/workspace-layouts.ts";
import { useAuth } from "../../../stores/auth.ts";
import emitter from "../../../shared/services/EventService.ts";
import HtMenuPopupTitle from "../../ui/HtMenuPopupTitle.vue";
import HtMenuPopupSectionTitle from "../../ui/HtMenuPopupSectionTitle.vue";
import HtMenuPopupLink from "../../ui/HtMenuPopupLink.vue";
import { PlusIcon } from "@heroicons/vue/24/solid";

const layouts = useWorkspaceLayoutStore();
const auth = useAuth();

/** Layout löschen: Dialog anzeigen */
function deleteSavedLayout(layoutId: number) {
  emitter.emit("dialog:trading.layoutManager.deletePrompt", {
    layoutId: layoutId
  });
}

/** Neues Layout erzeugen */
function createNewLayout() {
  emitter.emit("dialog:trading.layoutManager.nameNewLayout");
}

/** Layouts speichern unter: Dialog anzeigen */
function saveAs() {
  emitter.emit("dialog:trading.layoutManager.saveAs");
}
</script>
