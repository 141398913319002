import { PolygonSymbol } from "./Polygon.ts";
import { PositionType, SlTp } from "./Trading.ts";

export type PriceAlert = {
  Id: number;
  State: State;
  CreatedDate: number;
  Operator: Operator;
  SecurityId: number;
  Field: Field;
  Argument: number;
  ExpirationDate: number;
  Symbol?: PolygonSymbol;
};

export enum State {
  New = "New",
  Expired = "Expired",
  Completed = "Completed",
  Stopped = "Stopped"
}

export enum Operator {
  GTEQ = "GTEQ",
  LTEQ = "LTEQ"
}

export enum Field {
  Ask = "Ask",
  Bid = "Bid",
  Last = "Last"
}

export function getAlertsType(alert: PriceAlert): SlTp {
  // TP, Long
  if (alert.Operator === Operator.GTEQ && alert.Field === Field.Ask) {
    return "tp";
  }

  // SL, Long
  if (alert.Operator === Operator.LTEQ && alert.Field === Field.Last) {
    return "sl";
  }

  // SL, Short
  if (alert.Operator === Operator.GTEQ && alert.Field === Field.Last) {
    return "sl";
  }

  // TP, Short
  if (alert.Operator === Operator.LTEQ && alert.Field === Field.Bid) {
    return "tp";
  }
}

export function getOperator(type: SlTp, positionType: PositionType): Operator {
  // TP, Long
  if (type == "tp" && positionType === "long") {
    return Operator.GTEQ;
  }
  // SL, Long
  if (type == "sl" && positionType === "long") {
    return Operator.LTEQ;
  }
  // SL, Short
  if (type == "sl" && positionType === "short") {
    return Operator.GTEQ;
  }
  // TP, Short
  if (type == "tp" && positionType === "short") {
    return Operator.LTEQ;
  }
}

export function getField(type: SlTp, positionType: PositionType): Field {
  // TP, Long
  if (type == "tp" && positionType === "long") {
    return Field.Ask;
  }
  // SL, Long
  if (type == "sl" && positionType === "long") {
    return Field.Last;
  }
  // SL, Short
  if (type == "sl" && positionType === "short") {
    return Field.Last;
  }
  // TP, Short
  if (type == "tp" && positionType === "short") {
    return Field.Bid;
  }
}

export function isOpen(alert: PriceAlert) {
  return alert.State == State.New || alert.State == null;
}
