import { WorkspaceProLayout } from "../../shared/types/WorkspaceApp.ts";

const TemplateNewsScreening: WorkspaceProLayout = {
  instances: [
    {
      app: {
        id: "news",
        name: "News",
        emoji: "🗞",
        visibility: 2,
        configuration: { permission: "tradingPlatform.news.view" },
        vueComponentName: "News"
      },
      uuid: "00000000-bf47-4f18-90f1-897282656d83",
      settings: [],
      selectorMenu: "#window_00000000-bf47-4f18-90f1-897282656d83_menu",
      selectorContent: "#window_00000000-bf47-4f18-90f1-897282656d83_content"
    },
    {
      app: {
        id: "screener",
        name: "Aktien-Screener",
        emoji: "🔍",
        visibility: 2,
        configuration: { isNeutral: true },
        vueComponentName: "Screener"
      },
      uuid: "00000000-df04-4676-9007-f8c1674f11d7",
      settings: {
        order: {
          parameter: "day.v",
          directionLargeToSmall: false
        },
        filter: {
          fmv: {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "day.v": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.g": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          ticker: null,
          "calc.dv": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.pc": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.atr": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.v_avg": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.v_rel": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          }
        }
      },
      selectorMenu: "#window_00000000-df04-4676-9007-f8c1674f11d7_menu",
      selectorContent: "#window_00000000-df04-4676-9007-f8c1674f11d7_content"
    }
  ],
  goldenLayout: {
    root: {
      id: null,
      size: "50%",
      type: "row",
      content: [
        {
          id: null,
          size: "50%",
          type: "stack",
          content: [
            {
              id: null,
              size: "1fr",
              type: "component",
              title: "Aktien-Screener",
              maximised: false,
              isClosable: true,
              componentType: "vueComponentWindow",
              componentState: { uuid: "00000000-df04-4676-9007-f8c1674f11d7", appId: "screener" },
              reorderEnabled: true
            }
          ],
          maximised: false,
          isClosable: true,
          activeItemIndex: 0
        },
        {
          id: null,
          size: "50%",
          type: "stack",
          content: [
            {
              id: null,
              size: "1fr",
              type: "component",
              title: "News",
              maximised: false,
              isClosable: true,
              componentType: "vueComponentWindow",
              componentState: { uuid: "00000000-bf47-4f18-90f1-897282656d83", appId: "news" },
              reorderEnabled: true
            }
          ],
          minSize: "0px",
          maximised: false,
          isClosable: true,
          activeItemIndex: 0
        }
      ],
      minSize: "0px",
      isClosable: true
    },
    header: {
      dock: "dock",
      show: "top",
      close: "close",
      popout: "open in new window",
      maximise: "maximise",
      minimise: "minimise",
      tabDropdown: "additional tabs"
    },
    settings: {
      popInOnClose: false,
      reorderEnabled: true,
      responsiveMode: "none",
      popoutWholeStack: false,
      tabControlOffset: 10,
      tabOverlapAllowance: 0,
      closePopoutsOnUnload: true,
      reorderOnTabMenuClick: true,
      blockedPopoutsThrowError: true,
      constrainDragToContainer: true
    },
    dimensions: {
      borderWidth: 5,
      headerHeight: 34,
      dragProxyWidth: 300,
      borderGrabWidth: 5,
      dragProxyHeight: 200,
      defaultMinItemWidth: "10px",
      defaultMinItemHeight: "0px"
    },
    openPopouts: []
  }
};
export default TemplateNewsScreening;
