export default [
  /*
   * Login
   */
  {
    path: "/login",
    component: () => import("../views/auth/login.vue"),
    name: "login",
    meta: {
      requiredPermission: null,
      isLoginPage: true
    }
  },

  /*
   * Workspace
   */
  {
    path: "/trading",
    redirect: "/workspace"
  },
  {
    path: "/",
    redirect: "/workspace"
  },
  {
    path: "/workspace",
    component: () => import("../views/workspace/HtWorkspace.vue"),
    name: "workspace",
    meta: {
      requiredPermission: "tradingPlatform.access"
    }
  },

  // 403: Zugriff nicht gestattet
  {
    path: "/error/403",
    component: () => import("../views/error-pages/403.vue"),
    name: "403",
    meta: {
      requiredPermission: null,
      isErrorPage: true
    }
  },

  // 429: Zugriff nicht gestattet
  {
    path: "/error/429",
    component: () => import("../views/error-pages/429.vue"),
    name: "429",
    meta: {
      requiredPermission: null,
      isErrorPage: true
    }
  },

  // 404 bzw. Catch-All: Seite nicht gefunden
  {
    path: "/:catchAll(.*)",
    component: () => import("../views/error-pages/404.vue"),
    name: "404",
    meta: {
      requiredPermission: null,
      isErrorPage: true
    }
  }
];
