import { createRouter, createWebHashHistory } from "vue-router";

import routes from "./routes";
import { useAuth } from "./../stores/auth";

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const auth = useAuth();

  // Log-Ausgabe
  console.log(
    '[Router] 🔀 Neue Route angefordert. Die Seite "' +
      to.name +
      '" erfordert ' +
      (to.meta.requiredPermission == null
        ? "keine Berechtigung. "
        : 'die Berechtigung "' + to.meta.requiredPermission + '".')
  );

  // Benötigte Berechtigung
  const reqAuth = to.meta.requiredPermission != null;

  // Weiterleitung für Login-Seite
  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };

  // Prüfen, ob im Falle einer geschützen Seite der Benutzer angemeldet ist
  if (reqAuth) {
    if (!auth.userHasPermission(to.meta.requiredPermission)) {
      console.log("[Router] 🔀 Benutzer nicht eingeloggt. Weiterleitung zur Login-Seite.");

      // User automatisch abmelden, falls die Berechtigung zum Zugriff auf die Plattform fehlt
      if (to.meta.requiredPermission === "tradingPlatform.access") {
        auth.killSession(true);
      }

      next(loginQuery);
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
