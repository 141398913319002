<template>
  <router-link :to="routerTarget">
    <Popover v-if="shouldBeVisible(visibility)" :class="popoverPosition">
      <PopoverButton
        :class="[
          'aspect-square w-14 gap-1 rounded-lg overflow-hidden flex flex-col items-center justify-center ',
          'text-sm bg-transparent hover:bg-blue-600 dark:hover:bg-blue-800 hover:text-slate-100 dark:hover:text-slate-100 text-slate-600 dark:text-slate-400 transition-all duration-150'
        ]"
      >
        <Component :is="icon" class="h-6 w-6"></Component>
        <div>{{ name }}</div>
      </PopoverButton>
      <PopoverPanel
        v-if="slots?.default"
        :class="['absolute z-50 flex flex-col', panelStyle, panelPosition, panelSize]"
      >
        <slot></slot>
      </PopoverPanel>
    </Popover>
  </router-link>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { computed, useSlots } from "vue";
import { isLandscape, isMobile } from "./../../shared/composables/mobile";
import { shouldBeVisible } from "./../../shared/composables/visibility";
import PlatformVisibility from "../../shared/types/PlatformVisibility.ts";

const slots = useSlots();

const props = defineProps<{
  name: string;
  to?: any;
  visibility: PlatformVisibility;
  icon: any;
}>();

const panelStyleDesktop =
  "bg-white rounded-lg border-slate-100 border p-4 shadow-lg dark:bg-slate-800 dark:border-slate-700";
const panelStyleMobile = "";

const panelSizeDesktop = "";
const panelSizeMobile = "";

const popoverPosition = computed(() => (isMobile ? "" : "relative"));
const panelPosition = computed(() =>
  isMobile
    ? isLandscape.value
      ? "overflow-hidden left-16 top-0 h-screen w-[calc(100%-4rem)] bg-red-500"
      : "overflow-hidden top-0 left-0 h-[calc(100%-4rem)] w-screen bg-green-500"
    : "left-16 w-fit h-fit -mt-20 "
);
const panelSize = computed(() => (isMobile ? panelSizeMobile : panelSizeDesktop));
const panelStyle = computed(() => (isMobile ? panelStyleMobile : panelStyleDesktop));

const routerTarget = computed(() => {
  return props.to;
});
</script>

<style scoped></style>
