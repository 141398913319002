import platformVisibility from "../types/PlatformVisibility.ts";
import PlatformVisibility from "../types/PlatformVisibility.ts";
import { isMobile } from "./mobile";

export const shouldBeVisible = function (visibility: platformVisibility): boolean {
  if (isMobile) {
    return (
      visibility === PlatformVisibility.MOBILE_ONLY ||
      visibility === PlatformVisibility.MOBILE_AND_DESKTOP
    );
  } else {
    return (
      visibility === PlatformVisibility.DESKTOP_ONLY ||
      visibility === PlatformVisibility.MOBILE_AND_DESKTOP
    );
  }
};
