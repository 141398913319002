import { AxiosInstance, AxiosResponse } from "axios";

import { IPositionService, RawPosition } from "../types/Trading.ts";

export class PositionService implements IPositionService {
  constructor(private readonly httpClient: AxiosInstance) {}

  public getPositions(accountId: number): Promise<AxiosResponse<{ Result: RawPosition[] }>> {
    const endpoint = `accounts/${accountId}/positions`;
    return this.httpClient.get(endpoint, {
      params: {
        pageNumber: 0,
        pageSize: 100,
        sortField: "CreateDate",
        desc: true
      }
    });
  }

  public getPositionBySymbol(
    accountId: number,
    symbol: string
  ): Promise<AxiosResponse<RawPosition[]>> {
    const endpoint = `accounts/${accountId}/positions/${symbol}`;
    return this.httpClient.get(endpoint);
  }
}
