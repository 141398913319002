import { AxiosInstance, AxiosResponse } from "axios";
import { GeneratedOrder, IOrderService, ISecurityService, RawOrder } from "../types/Trading.ts";
import { SecurityService } from "./SecurityService";

/**
 * This method is used to check if the order placement is available.
 * If it's not available, the order will be displayed in the alert.
 * INFO: Returns true by default.
 */
export const isRealOrderPlacementAvailable = (): boolean => {
  return true;
};

export class OrderService implements IOrderService {
  private readonly securityService: ISecurityService;
  private static ACTIVE_ORDER_STATUS = {
    NEW: 0,
    PARTIALLY_FILLED: 1,
    REPLACED: 5,
    STOPPED: 7,
    SUSPENDED: 9,
    HELD: 16
  };

  constructor(private readonly httpClient: AxiosInstance) {
    this.securityService = new SecurityService(httpClient);
  }

  public async placeOrder(accountId: number, order: GeneratedOrder): Promise<AxiosResponse<any>> {
    const endpoint = `accounts/${accountId}/orders`;
    return this.httpClient.post<any>(endpoint, order);
  }

  public async replaceOrder(
    accountId: number,
    orderId: number,
    order: GeneratedOrder
  ): Promise<AxiosResponse<any>> {
    const endpoint = `accounts/${accountId}/orders/${orderId}`;
    return this.httpClient.put<Promise<AxiosResponse<any>>>(endpoint, {
      Id: orderId,
      ...order
    });
  }

  public async getOrders(accountId: number): Promise<AxiosResponse<{ Result: RawOrder[] }>> {
    const endpoint = `accounts/${accountId}/orders`;
    return this.httpClient.get(endpoint, {
      params: {
        pageNumber: 0,
        pageSize: 25,
        sortField: "CreateDate",
        desc: true
      }
    });
  }

  public async cancelOrder(accountId: number, orderId: number): Promise<void> {
    const endpoint = `accounts/${accountId}/orders/${orderId}`;
    return this.httpClient.delete(endpoint);
  }

  public async cancelOrders(accountId: number, ordersId: number[]): Promise<void> {
    try {
      await Promise.all(
        ordersId.map(async (orderId) => {
          await this.cancelOrder(accountId, orderId);
        })
      );
    } catch (e) {
      console.error(e, "while cancelOrders");
      throw e;
    }
  }

  public async cancelActiveSlTpOrdersBySymbol(accountId: number, symbol: string): Promise<void> {
    try {
      const orders = await this.getActiveSlTpOrdersBySecurityId(accountId, symbol);
      const hasOrders = orders.length;

      if (hasOrders && orders.length === 1) {
        await this.cancelOrder(accountId, orders[0].Id);
      }

      if (hasOrders && orders.length > 1) {
        await this.cancelOrders(
          accountId,
          orders.map((rawOrder) => rawOrder.Id)
        );
      }
    } catch (e) {
      console.error(e, "while cancelActiveSlTpOrders");
      throw e;
    }
  }

  public async getActiveSlTpOrdersBySecurityId(
    accountId: number,
    symbol: string
  ): Promise<RawOrder[]> {
    const { ACTIVE_ORDER_STATUS } = OrderService;
    const endpoint = `accounts/${accountId}/orders`;
    const filterStatuses = `(${ACTIVE_ORDER_STATUS.NEW},${ACTIVE_ORDER_STATUS.PARTIALLY_FILLED},${ACTIVE_ORDER_STATUS.REPLACED},${ACTIVE_ORDER_STATUS.STOPPED},${ACTIVE_ORDER_STATUS.SUSPENDED},${ACTIVE_ORDER_STATUS.HELD})`;

    try {
      const {
        data: { Id: securityId }
      } = await this.securityService.getSecurityInfo(symbol);
      const {
        data: { Result }
      } = await this.httpClient.get<{ Result: RawOrder[] }>(endpoint, {
        params: {
          pageNumber: 0,
          pageSize: 100,
          sortField: "CreateDate",
          desc: true,
          filter: `SecurityId = ${securityId} and Status in ${filterStatuses}`
        }
      });

      return Result.filter(
        (rawOrder) =>
          ["OneCancelOther", "OneTriggerOther", "OneTriggerOneCancelOther"].includes(
            rawOrder.Type
          ) || this.isSlTpOrder(rawOrder)
      );
    } catch (e) {
      console.error(e, "while getActiveSlTpOrdersBySecurityId");
      throw e;
    }
  }

  private isSlTpOrder(rawOrder: RawOrder): boolean {
    const expectedInstructionValues = ["sl", "tp"];

    return rawOrder?.ExecutionInstructions && rawOrder.ExecutionInstructions?.typeSlTp
      ? expectedInstructionValues.includes(rawOrder.ExecutionInstructions.typeSlTp)
      : false;
  }
}
