import { Order, OrderType, RawOrder } from "../../types/Trading.ts";
import { OrderSlTp } from "../../../stores/workspace-layouts/trading-ticket.ts";
import { createOrder } from "../../factories/Trading/OrderFactory.ts";
import { useEtnaTrading } from "../../../stores/etna-trading.ts";
import { OrderService } from "../OrderService.ts";

export class GetSlTpOrder {
  constructor(private setSlTpOrder: (order?: OrderSlTp) => void) {}

  public exec = async (symbol: string): Promise<void> => {
    try {
      const tradingStore = useEtnaTrading();
      const orderService = new OrderService(tradingStore.getAxios());
      const rawOrders = await orderService.getActiveSlTpOrdersBySecurityId(
        tradingStore.accountId as unknown as number,
        symbol
      );
      const order = this.parseOrders(rawOrders);

      if (order) {
        if (order.legs?.length) {
          this.setSlTpOrder(
            this.parseOrderLegs(
              this.isOtoOrOtocoOrder(order.type)
                ? order.legs.slice(1) // remove main order leg
                : order.legs // OCO order without a main leg
            )
          );
          return;
        }

        this.setSlTpOrder({
          sl: this.getOrderPrice(order, "Stop"),
          tp: this.getOrderPrice(order, "Limit")
        });
        return;
      }

      // this.setSlTpOrder(order); TODO - what to do if there is no order?
    } catch (e) {
      console.error(e, "while trying to execute GetSlTpOrder action");
    }
  };

  private parseOrders(rawOrders: RawOrder[]): Order | undefined {
    if (!rawOrders.length) return undefined;

    const slTpOrders = rawOrders.filter((rawOrder) =>
      this.isOtoOrOtocoOrder(rawOrder.Type)
        ? rawOrder.Legs?.length && rawOrder.Legs[0].Status === "Filled"
        : true
    );

    return slTpOrders.length === 1 ? createOrder(slTpOrders[0]) : undefined;
  }

  private parseOrderLegs(legs: Order[]): OrderSlTp {
    return legs.reduce(
      (acc, leg) => ({
        sl: acc.sl ? acc.sl : this.getOrderPrice(leg, "Stop"),
        tp: acc.tp ? acc.tp : this.getOrderPrice(leg, "Limit")
      }),
      { sl: 0, tp: 0 }
    );
  }

  private getOrderPrice(order: Order, type: Extract<OrderType, "Limit" | "Stop">): number {
    return order.type === type ? order.price : 0;
  }

  private isOtoOrOtocoOrder(orderType: string): boolean {
    return ["OneTriggerOther", "OneTriggerOneCancelOther"].includes(orderType);
  }
}
