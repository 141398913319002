<template>
  <AbstractDialog
    ref="abstractDialog"
    :icon="PencilIcon"
    cancel-text="Abbrechen"
    :show-submit="quantityChanged"
    submit-text="Ändern"
    :on-submit="handleSubmit"
  >
    <template #title>{{ order.symbol }}-Order bearbeiten</template>
    <template #content>
      <div class="flex flex-col gap-4 w-full">
        <!-- Position -->
        <div v-if="position">
          <div class="text-slate-500 dark:text-slate-400">Position:</div>
          <div class="text-slate-700">
            {{ position.type }} {{ formatNumberAsHumanReadableString(position.quantity, 0) }} Stück
          </div>
        </div>

        <!-- Eingabe -->
        <div class="flex flex-col gap-1">
          <label for="newQuantity" class="text-slate-500">Neue Stückzahl:</label>

          <InputNumber
            v-model="newQuantity"
            input-id="newQuantity"
            :input-class="[
              'px-3 z-10 focus:z-20 text-base block w-full border-0 py-2  ring-1 ring-inset dark:ring-slate-600 ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-600',
              'dark:bg-slate-800 bg-slate-50 dark:text-slate-200 text-slate-700 rounded text-lg'
            ]"
            autofocus
            suffix=" Stück"
            :min="1"
            :step="1"
            :max-fraction-digits="0"
            :min-fraction-digits="0"
            @focus="($event.target as HTMLInputElement)?.select()"
            @keydown.enter="handleEnterPress"
          ></InputNumber>
        </div>

        <!-- Vorschau -->
        <div class="flex flex-col gap-0.5">
          <div class="text-slate-500 dark:text-slate-400">Vorschau:</div>

          <div v-if="!quantityChanged" class="text-slate-700 dark:text-slate-300">
            keine Änderung
          </div>

          <div v-else class="text-slate-700 dark:text-slate-300 inline-flex gap-1 items-center">
            <div>
              {{ order.side }} {{ order.type }}
              <span class="font-medium line-through"
                >{{ formatNumberAsHumanReadableString(Math.abs(order.quantity), 0) }}&times;</span
              >
              {{ order.symbol }}
            </div>

            <ArrowRightIcon class="size-4"></ArrowRightIcon>
            <div>
              {{ order.side }} {{ order.type }}
              <span class="font-medium text-blue-600"
                >{{ formatNumberAsHumanReadableString(Math.abs(newQuantity), 0) }}&times;</span
              >
              {{ order.symbol }}<br />
            </div>
          </div>
        </div>
      </div>
    </template>
  </AbstractDialog>
</template>

<script setup lang="ts">
import AbstractDialog from "../../../../AbstractDialog.vue";
import { PencilIcon } from "@heroicons/vue/24/outline";
import { ArrowRightIcon } from "@heroicons/vue/16/solid";
import { useEtnaOrders } from "../../../../../../stores/etna-orders.ts";
import { useEtnaPositions } from "../../../../../../stores/etna-positions.ts";
import { OrderType, Position } from "../../../../../../shared/types/Trading.ts";
import _ from "lodash";
import InputNumber from "primevue/inputnumber";
import { computed, ref } from "vue";
import { useTradingTicketStore } from "../../../../../../stores/workspace-layouts/trading-ticket.ts";
import { formatNumberAsHumanReadableString } from "../../../../../../shared/composables/formatter.ts";
import ErrorMessage from "../../../../../../views/workspace/apps/Trading/SimpleOrderPanel/ErrorMessage.vue";
import { debug } from "../../../../../../shared/services/LoggingService.ts";

// AbstractDialog importieren
const abstractDialog = ref();

// Order-ID als Property
const props = defineProps<{
  id: any;
}>();

// Emit-Funktionen definieren
const emits = defineEmits(["submitRequested"]);

// Trading-Stores verwenden
const tradingOrdersStore = useEtnaOrders();
const tradingPositionsStore = useEtnaPositions();
const tradingTicketStore = useTradingTicketStore();

// Order aus dem Store holen
const order = tradingOrdersStore.orders[props.id];

// Position, falls vorhanden, aus dem Store holen
const position = _.find(
  tradingPositionsStore.positions,
  (position: Position) => position.quantity !== 0 && position.symbol === order.symbol
);

// Referenz für die neue Quantity der Order
const newQuantity = ref(Math.abs(position?.quantity ?? order.quantity));

// Boolean, ob sich die Quantity geändert hat
const quantityChanged = computed<boolean>(
  () => Math.abs(order.quantity) !== Math.abs(newQuantity.value)
);

// Funktion zum Behandeln von Enter
const handleEnterPress = function () {
  abstractDialog.value.handleSubmit();
};

// Funktion zum Ändern der Order
const handleSubmit = function () {
  // Order bearbeiten (replace)
  tradingTicketStore.replaceOrder(order.id, order.type as OrderType, {
    side: order.side,
    symbol: order.symbol,
    quantity: newQuantity.value,
    price: order?.level ?? order.price
  });

  // Debug-Info
  debug(
    `[TradingView Trading, ModifyOrderDialog]: Order ${order.id} bearbeitet. Alte Stückzahl: ${order.quantity}, Neue Stückzahl: ${newQuantity.value}`,
    order
  );
};
</script>
