import { WorkspaceApp } from "./WorkspaceApp.ts";
import PlatformVisibility from "./PlatformVisibility.ts";
import { ScannerType } from "./Scanner.ts";

const WorkspaceApps: { [key: string]: WorkspaceApp } = {
  screener: {
    id: "screener",
    name: "Aktien-Screener",
    emoji: "🔍",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Screener",
    configuration: {
      isNeutral: true
    }
  },
  tradingView: {
    id: "tradingView",
    name: "TradingView",
    iconPath: "/images/icons/trading_view.svg",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    maximumNumberOfInstances: 1,
    vueComponentName: "TradingView",
    configuration: {
      hideMenuBar: true,
      permission: "tradingPlatform.chart.view"
    }
  },
  signals: {
    id: "signals",
    name: "Trading Coach Signals",
    emoji: "🔋",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Signals",
    configuration: {
      hideMenuBar: true,
      permission: "tradingPlatform.signals.show",
      isNeutral: true
    }
  },
  livestream: {
    id: "livestream",
    name: "Livestream",
    emoji: "📷",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Livestream",
    configuration: {
      hideMenuBar: true,
      permission: "tradingPlatform.livestream.view"
    }
  },
  livestreamChat: {
    id: "livestreamChat",
    name: "Livestream Chat",
    emoji: "🗨️",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "LivestreamChat",
    configuration: {
      hideMenuBar: true,
      permission: "tradingPlatform.livestreamChat.view"
    }
  },
  news: {
    id: "news",
    name: "News",
    emoji: "🗞",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "News",
    configuration: {
      permission: "tradingPlatform.news.view"
    }
  },
  copyTrading: {
    id: "copyTrading",
    name: "Copy-Trading",
    emoji: "✨",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "CopyTrading",
    configuration: {
      hideMenuBar: false,
      isNeutral: true,
      permission: "tradingPlatform.copyTradingCoach.view"
    }
  },
  orders: {
    id: "orders",
    name: "Orders",
    iconPath: "/images/icons/orders.svg",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Trading/Orders",
    configuration: {
      hideMenuBar: false,
      isNeutral: true,
      permission: "tradingPlatform.trading.orders.view"
    }
  },
  positions: {
    id: "positions",
    name: "Positions",
    iconPath: "/images/icons/positions.svg",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Trading/Positions",
    configuration: {
      hideMenuBar: true,
      isNeutral: true,
      permission: "tradingPlatform.trading.positions.view"
    }
  },
  simpleOrderPanel: {
    id: "simpleOrderPanel",
    name: "Order-Panel",
    iconPath: "/images/icons/orderPanel.svg",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Trading/SimpleOrderPanel",
    configuration: {
      permission: "tradingPlatform.trading.orderPanel.view"
    }
  },
  accountDetails: {
    id: "accountDetails",
    name: "Trading-Account",
    emoji: "?",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Trading/AccountDetails",
    configuration: {
      hideMenuBar: true,
      isNeutral: true,
      permission: "tradingPlatform.trading.accounts.show"
    }
  },
  tradingTransactions: {
    id: "tradingTransactions",
    name: "Transaktionen",
    iconPath: "/images/icons/transactions.svg",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Trading/Transactions",
    configuration: {
      hideMenuBar: false,
      isNeutral: true,
      permission: "tradingPlatform.trading.tradeHistory.show"
    }
  },
  scan: {
    id: "scan",
    name: "Scan",
    emoji: "🗒️",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Scan",
    configuration: {
      permission: "tradingPlatform.scanner.view",
      isNeutral: true
    },
    defaultSettings: {
      scannerType: ScannerType.VOLUME
    }
  },
  watchlist: {
    id: "watchlist",
    name: "Visuelle Watchlist",
    emoji: "👀",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Watchlist",
    configuration: {
      permission: "tradingPlatform.watchlist.view",
      isNeutral: true
    }
  },
  education_oneMillionDollar_ebook: {
    id: "education_oneMillionDollar_ebook",
    name: "1 Mio. Dollar Strategie → E-Book",
    emoji: "📚",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Education/OneMillionDollar/Ebook",
    configuration: {
      hideMenuBar: true,
      permission: "tradingPlatform.strategies.one-million-dollar.ebook.show"
    }
  },
  education_oneMillionDollar_videoCourse: {
    id: "education_oneMillionDollar_videoCourse",
    name: "1 Mio. Dollar Strategie → Video-Kurs",
    emoji: "📼",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Education/OneMillionDollar/VideoCourse",
    configuration: {
      hideMenuBar: true,
      permission: "tradingPlatform.strategies.one-million-dollar.video-course.show"
    }
  },
  education_oneMillionDollar_liveTraining: {
    id: "education_oneMillionDollar_liveTraining",
    name: "1 Mio. Dollar Strategie → Live-Training",
    emoji: "📹",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Education/OneMillionDollar/LiveTraining",
    configuration: {
      hideMenuBar: true,
      permission: "tradingPlatform.strategies.one-million-dollar.live-training.show"
    }
  },
  education_eliteProgram2022: {
    id: "education_eliteProgram2022",
    name: "Elite Programm 2022",
    emoji: "📹",
    visibility: PlatformVisibility.DESKTOP_ONLY,
    vueComponentName: "Education/EliteProgram2022",
    configuration: {
      hideMenuBar: true,
      permission: "tradingPlatform.videoCourse.access"
    }
  }
};

export default WorkspaceApps;
