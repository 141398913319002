import { PolygonSymbol } from "../types/Polygon.ts";
import { AuthInformation } from "../types/Auth.ts";

/**
 * URL of the Cloudflare worker that will be used to fetch the data.
 */
const workerUrl = import.meta.env.VITE_STOCKDATA_HOST;

/**
 * Gets the URL of the Cloudflare worker that will be used to load the logo of a ticker.
 */
export const logoUrlForSymbol = (ticker: PolygonSymbol): [string] | [string, string] => {
  return [`${workerUrl}/c/logo/${ticker}`];
};

/**
 * Fetches data from the Polygon API.
 */
export const polygonFetch = function (
  requestedResource: PolygonResource,
  payload?: any,
  authStatus?: AuthInformation,
  fromWorker: boolean = false
) {
  // Build full URL
  const fullUrl = `${workerUrl}${getPathForResource(requestedResource, payload)}`;

  // Build fetch init
  const fetchInit = polygonFetchInit(authStatus, fromWorker);

  // Fetch data
  return fetch(fullUrl, fetchInit);
};

/**
 * Returns the path for the given resource and payload.
 *
 * @param {PolygonResource} resource - The resource for which the path is required.
 * @param {any} [payload] - Optional payload to be used in constructing the path.
 * @returns {string} The path for the given resource and payload.
 * @throws {Error} If an unknown resource is requested.
 */
function getPathForResource(resource: PolygonResource, payload?: any) {
  switch (resource) {
    case PolygonResource.TICKERS:
      return "/c/tickers";

    case PolygonResource.SNAPSHOT:
      return "/c/snapshot";

    case PolygonResource.HOLIDAYS:
      return "/v1/marketstatus/upcoming";

    case PolygonResource.NEWS:
      return `/c/news/${payload.ticker}`;

    case PolygonResource.AGGREGATE:
      // eslint-disable-next-line no-case-declarations
      const { ticker, range, from, to, adjusted, sort, limit, timespan } = payload;
      return `/v2/aggs/ticker/${ticker}/range/${range}/${timespan}/${from}/${to}?adjusted=${adjusted}&sort=${sort}&limit=${limit}`;

    default:
      throw new Error(`Unknown resource requested: ${PolygonResource[resource]}`);
  }
}

/**
 * Enumerator class representing polygon resources.
 */
export enum PolygonResource {
  TICKERS,
  AGGREGATE,
  SNAPSHOT,
  HOLIDAYS,
  NEWS
}

/**
 * Constructs the RequestInit object for fetching polygon data.
 */
export const polygonFetchInit = function (
  authInformation: AuthInformation,
  fromWorker = false
): RequestInit {
  // Build headers
  let headers: HeadersInit = {
    // Add auth header
    Authorization: `Bearer ${authInformation?.token}`,

    // Add app version header
    "X-Trading-App-Version": GIT_VERSION,

    // Add user id header to identify
    "X-Trading-App-User": authInformation?.userId,

    // Add origin header to identify, if from request is form worker
    "X-Requested-From": fromWorker ? "Worker" : "Main-Thread"
  };

  // Add staging header if in staging
  if (import.meta.env.VITE_BASE_URL.includes("staging")) {
    headers = {
      ...headers,
      "X-Pp-Is-Staging-Request": "true"
    };
  }

  return {
    method: "GET",
    keepalive: true,
    headers: headers as HeadersInit
  } as RequestInit;
};
