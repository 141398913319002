import { WorkspaceProLayout } from "../../shared/types/WorkspaceApp.ts";

const TemplateEmpty: WorkspaceProLayout = {
  name: "TemplateEmpty",
  instances: [],
  goldenLayout: {
    dimensions: {
      borderWidth: 5,
      borderGrabWidth: 5,
      headerHeight: 34
    },
    root: {
      type: "row",
      content: [],
      width: 50,
      minWidth: 0,
      height: 50,
      minHeight: 0,
      id: "",
      isClosable: true
    },
    resolved: true
  }
};
export default TemplateEmpty;
