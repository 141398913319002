import moment from "moment";

const formatNumberAsHumanReadableString = (
  value: any | null | undefined,
  digits = 2,
  decimalSeparator = ",",
  thousandsSeparator = "."
) => {
  // If value is undefined, return a dash
  if (!value || isNaN(value)) return "-";

  // Andernfalls den Wert formatieren
  const val = (value / 1).toFixed(digits).replace(".", decimalSeparator);
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
};

const formatDollars = (value: any | null | undefined, withDollarSign = false) => {
  // If value is undefined, return a dash
  if (value === undefined || value === null) {
    return "-";
  }

  if (value === 0) return "$0,00";

  return `${withDollarSign ? "$" : ""}${formatNumberAsHumanReadableString(value)}`;
};

const getColorForNumberGreenOrRed = (value: any | null | undefined) => {
  if (value === null || value === undefined || value === 0) {
    return "";
  } else if (value > 0) {
    return "text-green-600 dark:text-green-500";
  } else {
    return "text-red-600 dark:text-red-500";
  }
};

const formatDate = (date: string, format = "L") => {
  return moment(date).locale("de").format(format);
};

// Example: 07.06.2023 15:41:49
const formatDateWithTimeAndSeconds = (date: string) => {
  return formatDate(date, "L LTS");
};

// Example: 15:41
const formatDateToTime = (date: string) => {
  return formatDate(date, "LT");
};

// Example: 2 days ago
const formatDateRelativeFromNow = function (date: string) {
  return moment(date).fromNow();
};

export {
  formatDollars,
  getColorForNumberGreenOrRed,
  formatDateRelativeFromNow,
  formatDate,
  formatDateWithTimeAndSeconds,
  formatDateToTime,
  formatNumberAsHumanReadableString
};
