import { AxiosInstance, AxiosResponse } from "axios";
import {
  ITransactionService,
  RawTransaction,
  RawTransactionRpl,
  TransactionsResp
} from "../types/Trading.ts";

export class TransactionService implements ITransactionService {
  constructor(private readonly httpClient: AxiosInstance) {}

  public async getTransactions(
    accountId: number
  ): Promise<AxiosResponse<TransactionsResp<RawTransaction>>> {
    const endpoint = `accounts/${accountId}/transactions`;
    const orderExecutionTransactions = "2";

    try {
      const transactionRpl = await this.getAllTransactionsRpl(accountId);
      const transactionsOrderExecution = await this.httpClient.get<
        TransactionsResp<RawTransaction>
      >(endpoint, {
        params: {
          pageSize: 9,
          pageNumber: 0,
          sortBy: "id",
          isDesc: true,
          filter: `Type = ${orderExecutionTransactions}`
        }
      });

      const rplValueByOrderStateId = transactionRpl.reduce(
        (acc, rpl) => ({
          ...acc,
          [rpl.OrderStateId]: rpl.Value
        }),
        {} as Record<number, number>
      );

      transactionsOrderExecution.data.Result = transactionsOrderExecution.data.Result.map(
        (rawTransaction) => ({
          ...rawTransaction,
          Pl: rplValueByOrderStateId[rawTransaction.OrderStateId] || 0
        })
      );

      return transactionsOrderExecution;
    } catch (e) {
      console.error(e, "while getTransactions execution");
      throw e;
    }
  }

  private async getAllTransactionsRpl(accountId: number): Promise<RawTransactionRpl[]> {
    const endpoint = `accounts/${accountId}/transactions`;
    const pageSize = 100;
    const rplTransactions = "7";

    const request = (pageNumber = 0): Promise<AxiosResponse<TransactionsResp<RawTransactionRpl>>> =>
      this.httpClient.get<TransactionsResp<RawTransactionRpl>>(endpoint, {
        params: {
          pageSize,
          pageNumber,
          sortBy: "id",
          isDesc: true,
          filter: `Type = ${rplTransactions}`
        }
      });

    try {
      // load the very first page with RPL transactions
      const { data } = await request();

      const quantityOfAlreadyLoadedPage = 1;
      const pagesLeftQuantity = Math.ceil(data.TotalCount / pageSize) - quantityOfAlreadyLoadedPage;

      if (pagesLeftQuantity > 0) {
        const pageNumbersToFetch = [...Array(pagesLeftQuantity)].map((_, idx) => idx + 1);

        const restTransactionsResponse = await Promise.all(
          pageNumbersToFetch.map(async (pageNumber) => request(pageNumber))
        );

        return [
          ...data.Result,
          ...restTransactionsResponse.reduce(
            (acc, resp) => [...acc, ...resp.data.Result],
            [] as RawTransactionRpl[]
          )
        ];
      }

      return data.Result;
    } catch (e) {
      console.error(e, "getAllTransactionsRpl");
      throw e;
    }
  }
}
