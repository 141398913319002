import { defineStore } from "pinia";
import { ref } from "vue";
import { notify } from "@kyvg/vue3-notification";
import * as _ from "lodash";
import {
  PolygonHoliday,
  PolygonSnapshotTicker,
  PolygonSnapshotTickerExtendedWithLocalCalculation,
  PolygonSymbol,
  PolygonTicker
} from "../shared/types/Polygon.ts";
import { ScannerType } from "../shared/types/Scanner.ts";
import { PolygonSubscriptionMessage } from "../shared/types/PolygonSubscription.ts";
import {
  StockdataWorkerEvent,
  StockdataWorkerEventResponse,
  StockdataWorkerMethod,
  StockdataWorkerMethodCall,
  StockdataWorkerScannerResults
} from "../shared/types/StockdataWorker.ts";
import { polygonFetch, PolygonResource } from "../shared/services/PolygonService.ts";
import { useWebsocket } from "./websocket.ts";
import { useSettings } from "./settings.ts";
import { Module, useStatus } from "./status.ts";
import { AxiosError } from "axios";

export const useStockdata = defineStore(
  "stockdata",
  () => {
    /** App-Status **/
    const status = useStatus();

    /** Settings **/
    const settings = useSettings();

    /** Websocket-Store */
    const websocket = useWebsocket();

    /** Stockdata-Worker, um aufwändige Berechnungen in einem anderen Thread ausführen zu können */
    const worker = new Worker(new URL("./stockdata-worker.ts", import.meta.url), {
      type: "module"
    });

    /** Liste aller Ticker, die von Polygon unterstützt werden*/
    const tickers = ref<PolygonTicker[]>([]);

    /** Zeitstempel der letzten Aktualisierung der Ticker-Liste */
    const tickersLastUpdated = ref<Date | null>(null);

    /** Liste aller Börsen-Feiertage */
    const holidays = ref<PolygonHoliday[]>([]);

    /** Zeitstempel der letzten Aktualisierung der Börsen-Feiertage */
    const holidaysLastUpdated = ref<Date | null>(null);

    /** Resultate der verschiedenen Scanner */
    const scanner = ref<StockdataWorkerScannerResults>({
      [ScannerType.VOLUME]: [],
      [ScannerType.BEARISH]: [],
      [ScannerType.BULLISH]: [],
      [ScannerType.UP_GAP]: [],
      [ScannerType.DOWN_GAP]: [],
      [ScannerType.UP_MOMENTUM]: [],
      [ScannerType.DOWN_MOMENTUM]: []
    });

    /** Aktueller Snapshot abonnierter Ticker */
    const snapshots = ref<{
      [key: PolygonSymbol]: PolygonSnapshotTickerExtendedWithLocalCalculation;
    }>({});

    /** Resultate des Screeners */
    const screener = ref<PolygonSnapshotTicker[]>([]);

    /* Aktiviere die Verarbeitung der Events aus dem Worker */
    worker.onmessage = (e: MessageEvent) =>
      workerHandleMessage(e.data as StockdataWorkerEventResponse);

    /* Aktiviere die Verarbeitung der Events aus dem Worker */
    worker.onerror = (event) => console.error("[Stockdata v2.0] Fehler im Worker", event);
    worker.onmessageerror = (event) =>
      console.warn("[Stockdata v2.0] Fehler bei der Übermittlung zum Worker", event);

    /* Timer aktivieren */
    setInterval(() => callWorker(StockdataWorkerMethod.SNAPSHOT_UPDATE), 20000);

    /** Führt einen Befehl im Stockdata-Worker aus */
    const callWorker = function (method: StockdataWorkerMethod, payload?: any) {
      // Weiterleiten an den Worker
      worker.postMessage({
        method: _.cloneDeep(method),
        payload: _.cloneDeep(payload)
      } as StockdataWorkerMethodCall);
    };

    /** Aktualisiert die Details eines Tickers */
    const patchSnapshot = function (
      symbol: PolygonSymbol,
      payload: PolygonSnapshotTickerExtendedWithLocalCalculation
    ) {
      // Sicherstellen, dass es den Konten bereits gibt
      if (snapshots.value[symbol] === undefined) {
        snapshots.value[symbol] = {};
      }

      // Objekte zusammenführen
      snapshots.value[symbol] = Object.assign(snapshots.value[symbol], payload);
    };

    /** Abonniert einen Ticker, damit in Zukunft die Details gestreamt werden */
    const subscribe = function (subscriptionMessage: PolygonSubscriptionMessage) {
      // Weiterleiten an den Worker
      callWorker(StockdataWorkerMethod.SUBSCRIBE, subscriptionMessage);

      // realtime.fmv abonnieren, wenn gewünscht
      if (subscriptionMessage.fields.includes("realtime.fmv")) {
        // Pusher Channel binden
        websocket
          .getChannel(websocket.getChannelNameForSymbol(subscriptionMessage.ticker))
          .then((channel) => channel.bind("FMV", handleWebsocketFmvMessage));
      }
    };

    /**
     * Verarbeitet eine Nachricht vom Pusher, die den FMV eines Tickers enthält
     * @param data
     */
    const handleWebsocketFmvMessage = function (data: any) {
      data.forEach((entry: any) => {
        // Lade Snapshot
        let snapshot = snapshots.value[entry.sym];

        if (!snapshot) {
          snapshot = {};
        }

        // Stelle sicher, dass approximated und fmvHistory existieren
        if (!snapshot.approximated) {
          snapshot.approximated = {
            bid: null,
            ask: null,
            spread: null,
            fmvHistory: []
          };
        }

        // Get fmv history
        const fmvHistory = snapshot.approximated.fmvHistory;

        // Only run, if it is different from the last one
        if (fmvHistory.length === 0 || fmvHistory[fmvHistory.length - 1] !== entry.fmv) {
          // Add new FMV to history
          fmvHistory.push(entry.fmv);

          // Remove first element if more than 20
          if (fmvHistory.length > 20) {
            fmvHistory.shift();
          }

          // Extremwerte berechnen
          const min = Math.min(...fmvHistory);
          const max = Math.max(...fmvHistory);
          let halfOfRange = Math.round(((max - min) / 2) * 200) / 200;
          if (halfOfRange < 0.005) halfOfRange = 0.005;

          const spread = halfOfRange * 2;

          // Bid/Ask berechnen
          const bid = entry.fmv - halfOfRange;
          const ask = entry.fmv + halfOfRange;

          // Update Snapshot (FMV) nur, wenn nötig
          if (!snapshot?.realtime) {
            snapshot.realtime = { fmv: entry.fmv };
          } else if (snapshot.realtime.fmv !== entry.fmv) {
            snapshot.realtime.fmv = entry.fmv;
          }

          // Update Snapshot (approximated)
          snapshot.approximated.bid = bid;
          snapshot.approximated.ask = ask;
          snapshot.approximated.spread = spread;
        }
      });
    };

    /** De-abonniert einen Ticker, damit in Zukunft die Details nicht mehr gestreamt werden */
    const unsubscribe = function (subscriptionMessage: PolygonSubscriptionMessage) {
      // Weiterleiten an den Worker
      callWorker(StockdataWorkerMethod.UNSUBSCRIBE, subscriptionMessage);
    };

    /** De-abonniert einen Ticker, damit in Zukunft die Details nicht mehr gestreamt werden */
    const unsubscribeBySource = function (source: string) {
      // Weiterleiten an den Worker
      callWorker(StockdataWorkerMethod.UNSUBSCRIBE_BY_SOURCE, source);
    };

    /** Handelt Antwort-Nachrichten vom Stockdata-Worker */
    function workerHandleMessage(message: StockdataWorkerEventResponse) {
      // Log-Ausgabe
      if (settings.local.logWorkerMessages)
        console.log(
          `[Stockdata v2.0] Nachricht vom Worker empfangen (Event: ${
            StockdataWorkerEvent[message.event]
          })`
        );

      switch (message.event) {
        // Ergebnisse eines Scanners speichern
        case StockdataWorkerEvent.SCANNER_UPDATED:
          scanner.value[message.payload.scannerType] = message.payload.results;
          return;

        case StockdataWorkerEvent.SNAPSHOT_READY:
          status.setReady(Module.stockdataSnapshot);
          return;

        case StockdataWorkerEvent.SNAPSHOT_UPDATED:
          patchSnapshot(message.payload.symbol, message.payload.details);
          return;

        case StockdataWorkerEvent.SCREENER_UPDATED:
          screener.value = message.payload;
          return;

        case StockdataWorkerEvent.SCANNER_COUNTER_RESET_DONE:
          notify({
            type: "info",
            title: "Scanner-Reset",
            text: "Die Counter für die Scanner wurden erfolgreich zurückgesetzt. Neue Daten werden gesammelt: dies kann einen Moment dauern…"
          });
          return;

        case StockdataWorkerEvent.STOCKDATA_REALTIME_FMV_UNSUBSCRIBED:
          websocket.unsubscribeFromChannel(
            websocket.getChannelNameForSymbol(message.payload.symbol)
          );
          return;

        // Fallback
        default:
          console.warn("[Stockdata v2.0] Unbekanntes Event vom Worker empfangen:", message.event);
      }
    }

    /**
     * Lädt die Liste aller Ticker von Polygon
     */
    const updateTickers = async () => {
      // Log-Ausgabe
      console.log("[Stockdata v2.0] Ticker werden aktualisiert...");

      // Status-Flag setzen
      status.setLoading(Module.stockdataTickers);

      // Wenn die Ticker verfügbar sind und in der letzten Stunde aktualisiert wurden, dann nicht erneut aktualisieren
      if (
        tickers.value.length > 0 &&
        new Date(tickersLastUpdated.value).getTime() > new Date().getTime() - 1000 * 60 * 60
      ) {
        console.log(
          "[Stockdata v2.0] Ticker nicht erneut laden, da diese bereits in der letzten Stunde aktualisiert wurden."
        );

        // Status-Flag setzen
        status.setReady(Module.stockdataTickers, "Ticker waren im Browser-Cache gespeichert.");
        return true;
      }

      // Serverabfrage
      return polygonFetch(PolygonResource.TICKERS)
        .then((res) => {
          // JSON-Verarbeitung
          return res.json().then((data) => {
            // Log-Ausgabe
            console.log("[Stockdata v2.0] Ticker wurden aktualisiert.");

            // Zuweisung
            tickers.value = data.results as PolygonTicker[];

            // Zeitstempel aktualisieren
            tickersLastUpdated.value = new Date();

            // Status-Flag setzen
            status.setReady(Module.stockdataTickers);

            // Erfolg zurückgeben
            return true;
          });
        })
        .catch((err) => {
          // Log-Ausgabe
          console.error("[Stockdata v2.0] Ticker konnten nicht aktualisiert werden:", err);

          // Status-Flag setzen
          status.setError(Module.stockdataTickers, err.message);

          // Misserfolg zurückgeben
          return false;
        });
    };

    /**
     * Lädt die Liste aller Börsen-Feiertage von Polygon
     */
    const updateHolidays = async () => {
      // Log-Ausgabe
      console.log("[Stockdata v2.0] Börsen-Feiertage werden aktualisiert...");

      // Status-Flag setzen
      status.setLoading(Module.stockdataHolidays);

      // Wenn die Ticker verfügbar sind und in der letzten Stunde aktualisiert wurden, dann nicht erneut aktualisieren
      if (
        tickers.value.length > 0 &&
        new Date(holidaysLastUpdated.value).getTime() >
          new Date().getTime() - 1000 * 60 * 60 * 24 * 7
      ) {
        console.log(
          "[Stockdata v2.0] Börsen-Feiertage nicht erneut laden, da diese bereits in der letzten Woche aktualisiert wurden."
        );

        // Status-Flag setzen
        status.setReady(
          Module.stockdataHolidays,
          "Börsen-Feiertage waren im Browser-Cache gespeichert."
        );

        return true;
      }

      // Serverabfrage
      return polygonFetch(PolygonResource.HOLIDAYS)
        .then((res) => {
          // JSON-Verarbeitung
          return res.json().then((data) => {
            // Zuweisung
            holidays.value = data as PolygonHoliday[];

            // Zeitstempel aktualisieren
            holidaysLastUpdated.value = new Date();

            // Log-Ausgabe
            console.log("[Stockdata v2.0] Börsen-Feiertage wurden aktualisiert.");

            // Status-Flag setzen
            status.setReady(
              Module.stockdataHolidays,
              "Börsen-Feiertage wurden erfolgreich aktualisiert."
            );

            // Erfolg zurückgeben
            return true;
          });
        })
        .catch((err: AxiosError) => {
          // Log-Ausgabe
          console.error(
            "[Stockdata v2.0] Börsen-Feiertage konnten nicht aktualisiert werden:",
            err
          );

          // Status-Flag setzen
          status.setError(Module.stockdataHolidays, err.message);

          // Misserfolg zurückgeben
          return false;
        });
    };

    return {
      subscribe,
      unsubscribe,
      unsubscribeBySource,
      tickers,
      scanner,
      screener,
      snapshots,
      holidays,
      callWorker,
      updateTickers,
      updateHolidays
    };
  },

  {
    // persist: { TODO Aktivieren, reduziert aber die Performance erheblich
    //   paths: ["tickers", "tickersLastUpdated", "holidays", "holidaysLastUpdated"]
    // }
  }
);
