import { useEtnaTrading } from "../../../stores/etna-trading.ts";
import { NotificationService } from "../NotificationService.ts";
import { PositionService } from "../PositionService.ts";
import { Position, Side } from "../../types/Trading.ts";
import { PercentageCalculator } from "../TradingCalculations";
import { OrderService } from "../OrderService.ts";
import { createPosition } from "../../factories/Trading/PositionFactory.ts";
import { createMarketOrder } from "../../factories/Trading/SpecificOrderFactories.ts";
import { useEtnaAlerts } from "../../../stores/etna-alerts.ts";
import _ from "lodash";

export class ClosePositionsBySymbol {
  private readonly notifier: NotificationService;
  constructor() {
    this.notifier = new NotificationService();
  }

  /**
   * When the percentageScaling argument is not defined,
   * close **ALL** positions for the selected symbol
   */
  public exec = async (
    symbol: string,
    getLastPrice: () => number,
    percentageScaling?: number
  ): Promise<void> => {
    const tradingStore = useEtnaTrading();
    const alerts = useEtnaAlerts();
    const accountId = tradingStore.accountId as unknown as number;
    const orderService = new OrderService(tradingStore.getAxios());
    const positionService = new PositionService(tradingStore.getAxios());

    try {
      const { data: positions } = await positionService.getPositionBySymbol(accountId, symbol);
      const position = positions.length ? createPosition(positions[0]) : undefined;

      if (!position || position.quantity === 0) {
        this.notifier.warn({
          text: `No open position for ${symbol} symbol`
        });
        return;
      }

      /**
       * When percentageScaling is undefined that means that we want to close all positions by symbol
       * and close all active OCO/OTO/OTOCO orders
       */
      if (this.isAllPositionsHasToBeClosed(position, percentageScaling)) {
        await orderService.cancelActiveSlTpOrdersBySymbol(accountId, symbol);
      }

      const isShortPosition = position.type === "short";
      const orderSide: Extract<Side, "Sell" | "BuyToCover"> = isShortPosition
        ? "BuyToCover"
        : "Sell";
      const quantity = this.getOrderQuantity(position.quantity, isShortPosition, percentageScaling);
      const marketFactoryPayload = {
        symbol,
        quantity,
        side: orderSide,
        price: getLastPrice()
      };
      const marketOrder = createMarketOrder(marketFactoryPayload);

      await orderService.placeOrder(accountId, marketOrder);
    } catch (e) {
      console.error(e, "while trying to execute closeAllPositionsBySymbol action");
      this.notifier.error({
        text: "Oops, something went wrong, please try again later"
      });
    }
  };

  private isAllPositionsHasToBeClosed(position?: Position, percentageScaling?: number): boolean {
    return Boolean(position && !percentageScaling);
  }

  private getOrderQuantity(
    positionQuantity: number,
    isShortPosition: boolean,
    percentageScaling?: number
  ): number {
    const quantity = isShortPosition ? positionQuantity * -1 : positionQuantity;

    if (percentageScaling) {
      const scaleOutQuantity = new PercentageCalculator().fromNumber(quantity, percentageScaling);
      return scaleOutQuantity < 1 ? 1 : Math.floor(scaleOutQuantity);
    }

    return quantity;
  }
}
