import { defineStore } from "pinia";
import { useEtnaTrading } from "./etna-trading.js";
import { computed, ref } from "vue";
import { Module, useStatus } from "./status.ts";
import { useAxios } from "./axios.ts";

export const useEtnaAccounts = defineStore("etna-accounts", () => {
  const trading = useEtnaTrading();
  const axios = useAxios();

  // Alle geladenen Accounts
  const accounts = ref({});
  const accountsTradeSecure = ref({});

  /**
   * Liefert den aktuellen Account
   * @type {Object} Account
   * @property {string} AccessType
   * @property {string} Alias
   * @property {string} ClearingAccount
   * @property {boolean} Enabled
   * @property {number} Id
   * @property {string} MarginType
   * @property {string} OwnerType
   */
  const current = computed(() => accounts.value[trading.accountId] ?? null);

  /** Globaler App-Status */
  const status = useStatus();

  /**
   * Funktion die beim Start aufgerufen wird, um alle Informationen zu sammeln und im lokalen Store zu speichern
   * @returns {Promise<void>}
   */
  async function boot() {
    // Accounts vom Server laden
    await loadAccounts();
  }

  /**
   * Funktion die beim Beenden aufgerufen wird, um Subscriptions zu beenden und den lokalen Store zu leeren
   * @returns {Promise<void>}
   */
  async function shutdown() {
    // gespeicherte Accounts löschen
    accounts.value = {};
  }

  /**
   * Lädt alle Accounts vom Server
   */
  async function loadAccounts() {
    // Status-Flag setzen
    status.setLoading(Module.tradingAccounts);
    // Accounts vom ETNA-Server laden
    await trading
      .getAxios()
      .get("/users/" + trading.getEtnaUserId() + "/accounts")
      .then((res) => {
        // Alle Accounts durchgehen und speichern
        res.data.forEach((account) => {
          if (account.Enabled === true) patchAccount(account.Id, account);
        });

        // Status-Flag setzen
        status.setReady(Module.tradingAccounts);
      });

    // Account-Infos aus dem Backend holen
    await axios.get("/api/trading/accounts").then((res) => {
      // Alle Accounts durchlaufen und TradeSecure Knoten zusammenführen
      res.data.forEach((accountTradeSecure) => {
        if (accounts.value[accountTradeSecure.etnaId]) {
          accounts.value[accountTradeSecure.etnaId] = {
            ...accounts.value[accountTradeSecure.etnaId],
            TradeSecure: accountTradeSecure
          };
        }
      });
    });
  }

  /**
   * Updated oder speichert den lokal gespeicherten Account
   * @param accountId
   * @param account
   */
  function patchAccount(accountId, account) {
    accounts.value[accountId] = account;
  }

  return {
    boot,
    shutdown,
    accounts,
    current
  };
});
