import { defineStore } from "pinia";

import axios, { AxiosHeaders } from "axios";

import { computed, ref, watch } from "vue";

import { useEtnaAccounts } from "./etna-accounts.ts";
import { useEtnaOrders } from "./etna-orders.ts";
import { useEtnaAccountBalances } from "./etna-account-balances.ts";
import { useEtnaPositions } from "./etna-positions.ts";
import { useAxios } from "./axios.ts";
import { useEtnaTransactions } from "./etna-transactions.ts";
import { Module, useStatus } from "./status.ts";
import { useEtnaAlerts } from "./etna-alerts.ts";

export const useEtnaTrading = defineStore("etna-trading", () => {
  // ETNA-Axios-Instanz
  let etnaAxiosInstance = null;

  /**
   * Befindet sich das Trading-System im Ladeprozess?
   * @type {boolean} isLoading
   */
  const isLoading = computed(() => loading.value);
  const loading = ref(true);

  /**
   * Ist das Trading-System aktiv?
   * @type {boolean} isEnabled
   */
  const isEnabled = computed(() => enabled.value);
  const enabled = ref(false);

  /**
   * Ist das Trading-System deaktiviert?
   * @type {boolean} isDisabled
   */
  const isDisabled = computed(() => !enabled.value);

  /**
   * Ist das Trading-System bereit, Befehle anzunehmen?
   * 1. Nicht laden
   * 2. Aktiv
   * 3. Account hinterlegt und Daten für den Account geladen
   * @type {boolean} isReady
   */
  const isReady = computed(() => !loading.value && enabled.value && account.value != null);

  /**
   * Enthält Informationen zum Login der aktuellen Session
   */
  let auth = {
    et_user_id: null,
    et_app_key: null,
    et_token: null
  };

  /**
   * Enthält Informationen zur Verbindung mit den Websockets
   * @type {{quote: {connection: null, sessionId: null, url: null}, data: {connection: null, sessionId: null, url: null}}}
   */
  const streaming = {
    quote: {
      url: null,
      sessionId: null,
      connection: null
    },
    data: {
      url: null,
      sessionId: null,
      connection: null
    }
  };

  // Timestamp des letzten Pings, der per Websocket empfangen wurde
  const pingTimestamp = ref(null);

  const accountId = ref(null);
  const account = computed(() => accounts.accounts[accountId.value] ?? null);
  watch(accountId, (value, oldValue) => changeAccount(value, oldValue));

  const orders = useEtnaOrders();
  const accounts = useEtnaAccounts();
  const accountBalances = useEtnaAccountBalances();
  const positions = useEtnaPositions();
  const history = useEtnaTransactions();
  const alerts = useEtnaAlerts();

  /**
   * Deaktiviert das Trading-System für diese Sitzung
   */
  function disableTradingSystem() {
    enabled.value = false;
    loading.value = false;

    console.log("Disable Trading System");

    const status = useStatus();
    status.setDisabled(Module.tradingOrders);
    status.setDisabled(Module.tradingHistory);
    status.setDisabled(Module.tradingAccounts);
    status.setDisabled(Module.tradingPositions);
    status.setDisabled(Module.tradingAlerts);
  }

  /**
   * Startet das Trading-System
   */
  async function start() {
    // 1. Auth Token-Laden
    console.log("[Trading-System] 💹 Auth-Token abfragen…");
    try {
      await loadAuthToken();

      // Fehler, wenn keine User-ID vorhanden
      if (auth.et_user_id == null) {
        throw new Error("No user ID");
      }
    } catch (e) {
      console.warn("[Trading-System] 💹 Auth-Token abfragen → Fehler. → Trading deaktiviert.");
      disableTradingSystem();
      return;
    }

    // Axios-Instanz mit eben abgefragtem Token anlegen
    createAxiosInstance();

    console.log("[Trading-System] 💹 Auth-Token abfragen → OK");

    // 2. Streaming Daten laden
    console.log("[Trading-System] 💹 Streaming Daten laden…");
    await loadStreamingData();
    console.log("[Trading-System] 💹 Streaming Daten laden → OK");

    // 3. Websocket-Verbindung herstellen
    console.log("[Trading-System] 💹 Verbindung zu Websockets…");
    await connectToWebsockets();
    console.log("[Trading-System] 💹 Verbindung zu Websockets → OK");

    // 4. Accounts laden und ersten Account zuweisen, sofern noch nicht geschehen
    console.log("[Trading-System] 💹 Accounts laden…");
    await accounts.boot();

    if (accounts.accounts.length === 0) {
      disableTradingSystem();
      console.warn(
        "[Trading-System] 💹 Account-Abfrage nicht möglich oder keine Accounts. → Trading deaktiviert."
      );

      return;
    }

    accountId.value = (Object.values(accounts.accounts)[0] as any)?.Id;
    console.log("[Trading-System] 💹 Accounts laden → OK");

    // 4. Alerts laden
    console.log("[Trading-System] 💹 » Alerts laden…");
    await alerts.boot(getEtnaUserId());
    console.log("[Trading-System] 💹 » Alerts laden → OK");

    // Status anpassen abgeschlossen
    loading.value = false;
    enabled.value = true;
  }

  /**
   * Wechselt den aktiven Account
   * @param newAccountId
   * @param oldAccountId
   * @returns {Promise<void>}
   */
  async function changeAccount(newAccountId, oldAccountId) {
    // Laden aktivieren
    loading.value = true;

    // Log-Ausgabe
    console.log(
      "[Trading-System] 💹 Account wechseln. Alter Account: " +
        oldAccountId +
        ". Neuer Account: " +
        newAccountId +
        "."
    );

    // Shutdown für alten Account
    if (oldAccountId != null) {
      // Account-Balances herunterfahren
      await accountBalances.shutdown(oldAccountId);

      // Orders herunterfahren
      await orders.shutdown(oldAccountId);

      // Positions herunterfahren
      await positions.shutdown(oldAccountId);
    }

    // Boot für neuen Account
    if (newAccountId != null) {
      // Account-Balances laden
      console.log("[Trading-System] 💹 » Account-Balances laden…");
      await accountBalances.boot(newAccountId);
      console.log("[Trading-System] 💹 » Account-Balances laden → OK");

      // Orders laden
      console.log("[Trading-System] 💹 » Orders laden…");
      await orders.boot(newAccountId);
      console.log("[Trading-System] 💹 » Orders laden → OK");

      // Positions laden
      console.log("[Trading-System] 💹 » Positions laden…");
      await positions.boot(newAccountId);
      console.log("[Trading-System] 💹 » Positions laden → OK");

      // Transactions laden
      console.log("[Trading-System] 💹 Historie laden…");
      await history.loadTransactions(newAccountId);
      console.log("[Trading-System] 💹 Historie laden → OK");
    }

    console.log("[Trading-System] 💹 Account wechseln. → OK");

    // Laden deaktivieren
    loading.value = false;
  }

  /**
   * Erstellt eine Axios-Instanz mit ENTA-Endpoint
   */
  function createAxiosInstance() {
    etnaAxiosInstance = axios.create({
      baseURL: "https://pub-api-heldental-demo-prod.etnasoft.us/api/v1.0/",
      timeout: 100000,
      headers: {
        "Et-App-Key": auth.et_app_key,
        Authorization: "Bearer " + auth.et_token
      } as unknown as AxiosHeaders
    });

    etnaAxiosInstance.interceptors.response.use(
      // Im Erfolgsfall
      (response) => {
        return response;
      },

      // Im Fehlerfall
      async function (error) {
        // Log-Ausgabe
        console.warn("[Trading-System] 💹 Netzwerkfehler: ", error);
        return Promise.reject(error.response);
      }
    );
  }

  /**
   * Returns a configured and ready to use Axios instance
   * @returns {AxiosInstance}
   */
  function getAxios() {
    if (etnaAxiosInstance == null) {
      createAxiosInstance();
    }

    return etnaAxiosInstance;
  }

  /**
   * Lädt den Auth-Token von ETNA
   */
  async function loadAuthToken() {
    const { post } = useAxios();
    await post("/api/trading/token").then((res) => {
      auth = res.data;
    });
  }

  /**
   * Liefert die ETNA-User-UD
   * @returns {null}7
   */
  function getEtnaUserId() {
    return auth.et_user_id;
  }

  /**
   * Lädt die Streaming-Daten: URLs und Session-IDs
   */
  async function loadStreamingData() {
    await etnaAxiosInstance.get("streamers").then((res) => {
      streaming.data.url = res.data.DataAddresses[0].Url;
      streaming.quote.url = res.data.QuoteAddresses[0].Url;
    });

    await etnaAxiosInstance.put("streamers/session/recover?sessionType=0").then((res) => {
      streaming.data.sessionId = res.data.Id;
    });

    await etnaAxiosInstance.put("streamers/session/recover?sessionType=1").then((res) => {
      streaming.quote.sessionId = res.data.Id;
    });
  }

  /**
   * Stellt die Verbindung zu den Websockets her
   */
  async function connectToWebsockets() {
    streaming.data.connection = new WebSocket(
      streaming.data.url +
        "/CreateSession.txt" +
        "?User=" +
        auth.et_user_id +
        ":" +
        streaming.data.sessionId +
        "&Password=" +
        streaming.data.sessionId +
        "&HttpClientType=WebSocket"
    );

    streaming.data.connection.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.Cmd === "CreateSession.txt") {
        streaming.data.sessionId = data.SessionId;
      } else if (data.Cmd === "Ping") {
        if (data.StatusCode === "Ok") {
          pingTimestamp.value = Date.now();
        }
      } else if (data.Cmd === "Subscribe.txt" || data.Cmd === "Unsubscribe.txt") {
        // Abo geändert
      } else if (data.EntityType === "AccountBalance") {
        accountBalances.handleMessage(data);
      } else if (data.EntityType === "Order") {
        orders.handleMessage(data);
      } else if (data.EntityType === "Position") {
        positions.handleMessage(data);
      } else if (data.EntityType === "PriceAlerts") {
        alerts.handleMessage(data);
      }
    };

    streaming.quote.connection = new WebSocket(
      streaming.quote.url +
        "/CreateSession.txt" +
        "?User=" +
        auth.et_user_id +
        ":" +
        streaming.quote.sessionId +
        "&Password=" +
        streaming.quote.sessionId +
        "&HttpClientType=WebSocket"
    );
  }

  /**
   * Wartet, bis der angegebene Server bereit ist
   * @param connection WebSocket
   * @returns {Promise<boolean>}
   */
  async function websocketIsReady(connection) {
    while (connection?.readyState !== 1) {
      await new Promise((r) => setTimeout(r, 200));
    }

    return true;
  }

  /**
   * Abonniert den Stream des gegebenen Typs
   * @param entityTypeToSubscribe
   * @param idToSubscribe Die ID des Kontexts, der abonniert werden soll. Dies kann je nach EntityType entweder der User
   * oder der Account sein.
   * @returns {Promise<void>}
   */
  async function subscribeTo(entityTypeToSubscribe, idToSubscribe) {
    // Warten, dass der Websocket bereit ist
    await websocketIsReady(streaming.data.connection);

    // Nachricht zum Abonnieren schicken
    return streaming.data.connection.send(
      JSON.stringify({
        Cmd: "Subscribe.txt",
        SessionId: streaming.data.sessionId,
        Keys: idToSubscribe.toString(),
        EntityType: entityTypeToSubscribe,
        HttpClientType: "WebSocket"
      })
    );
  }

  /**
   * De-Abonniert den Stream des gegebenen Typs
   * @param entityType
   * @param accountIdToUnsubscribeFrom
   * @returns {Promise<void>}
   */
  async function unsubscribeFrom(entityType, accountIdToUnsubscribeFrom) {
    // Warten, dass der Websocket bereit ist
    await websocketIsReady(streaming.data.connection);

    // Nachricht zum Abonnieren schicken
    return streaming.data.connection.send(
      JSON.stringify({
        Cmd: "Unsubscribe.txt",
        SessionId: streaming.data.sessionId,
        Keys: accountIdToUnsubscribeFrom,
        EntityType: entityType,
        HttpClientType: "WebSocket"
      })
    );
  }

  return {
    start,
    subscribeTo,
    unsubscribeFrom,
    getAxios,
    getEtnaUserId,
    disable: disableTradingSystem,

    accountId,
    account,
    pingTimestamp,

    isReady,
    isLoading,
    isEnabled,
    isDisabled
  };
});
