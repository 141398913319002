import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import { createPinia } from "pinia";
import { useAuth } from "./stores/auth.js";
import router from "./router";
import moment from "moment/moment";
import Notifications from "@kyvg/vue3-notification";
import PrimeVue from "primevue/config";
import * as Sentry from "@sentry/vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { AxiosError } from "axios";

// Begrüßung
console.log("[Main] ✨ Willkommen zu der Version " + GIT_VERSION + " der Heldental Plattform");

// Debug-Modus Log-Ausgabe
if (import.meta.env.VITE_DEBUG) {
    console.log("[Main] ✨ Debug-Modus aktiv");
}

// App erstellen
const app = createApp(App);

// Vue3-Notifications
app.use(Notifications);

// Moment auf Deutsch stellen
moment.locale("de");

// PrimeVue
app.use(PrimeVue);

// Pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

// Auth
const auth = useAuth();
await auth.checkToken();

// Router verwenden
app.use(router);

// Sentry
if (import.meta.env.VITE_SENTRY_DSN !== null) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],

        tracesSampleRate: 0.1,
        tracePropagationTargets: ["localhost", /^https:\/\/tradesecure\.app\/api/],

        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        ignoreErrors: [
            'AxiosError',
            'Network Error',
            'Failed to fetch dynamically imported module',
            'error loading dynamically imported module',
            'Unable to preload CSS',
        ],

        beforeSend(event, hint) {
            // Spezielle Behandlung für Axios-Fehler
            if(hint && hint.originalException && (hint.originalException as any).name === "AxiosError") {
                // Exception als AxiosError casten
                const axiosError = hint.originalException as AxiosError;

                // Herausfiltern von Error 429
                if (axiosError.response && axiosError.response.status === 429) {
                    return null;
                }

                // Herausfiltern von Fehlern mit den Auth-Endpunkten
                if (axiosError.request && axiosError.request.url && (axiosError.request.url?.contains("/api/token") || axiosError.request.url?.contains("/api/trading/token") || axiosError.request.url?.contains("/api/me")) ) {
                    return null;
                }

                // Herausfiltern von Error 401 und 403
                if (axiosError.response && axiosError.response?.status === 401 || axiosError.response?.status === 403) {
                    return null;
                }

                // Add context information
                if (axiosError.response && axiosError.response.data) {
                    event.contexts = {
                        ...event.contexts,
                        url: axiosError.request.url,
                        auth: auth?.user?.id,
                        errorResponse: {
                            data: axiosError.response.data,
                        }
                    };
                }
            }

            return event;
        },

    });
}

// Log-Ausgabe
console.log("[Main] ✨ Beginne Mounting.");

// App mounten
app.mount("#app");

// Log-Ausgabe
console.log("[Main] ✨ Ladevorgang abgeschlossen.");