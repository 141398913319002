import { WorkspaceProLayout } from "../../shared/types/WorkspaceApp.ts";

const TemplateDefault: WorkspaceProLayout = {
  instances: [
    {
      app: {
        id: "tradingView",
        name: "TradingView",
        iconPath: "/images/icons/trading_view.svg",
        visibility: 2,
        configuration: { permission: "tradingPlatform.chart.view", hideMenuBar: true },
        vueComponentName: "TradingView",
        maximumNumberOfInstances: 1
      },
      uuid: "00000000-31f3-41f0-a585-21ab08b82de4",
      settings: [],
      selectorMenu: "#window_00000000-31f3-41f0-a585-21ab08b82de4_menu",
      selectorContent: "#window_00000000-31f3-41f0-a585-21ab08b82de4_content"
    },
    {
      app: {
        id: "signals",
        name: "Trading Coach Signals",
        emoji: "🔋",
        visibility: 2,
        configuration: {
          isNeutral: true,
          permission: "tradingPlatform.signals.show",
          hideMenuBar: true
        },
        vueComponentName: "Signals"
      },
      uuid: "00000000-9b85-4576-9579-8e73fb72562b",
      settings: [],
      selectorMenu: "#window_00000000-9b85-4576-9579-8e73fb72562b_menu",
      selectorContent: "#window_00000000-9b85-4576-9579-8e73fb72562b_content"
    },
    {
      app: {
        id: "scan",
        name: "Scan",
        emoji: "🗒️",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.scanner.view" },
        defaultSettings: { scannerType: "volume" },
        vueComponentName: "Scan"
      },
      uuid: "00000000-44e4-4cf2-984a-2d48b7ca64e2",
      settings: { scannerType: "upMomentum" },
      selectorMenu: "#window_00000000-44e4-4cf2-984a-2d48b7ca64e2_menu",
      selectorContent: "#window_00000000-44e4-4cf2-984a-2d48b7ca64e2_content"
    },
    {
      app: {
        id: "scan",
        name: "Scan",
        emoji: "🗒️",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.scanner.view" },
        defaultSettings: { scannerType: "volume" },
        vueComponentName: "Scan"
      },
      uuid: "00000000-77f2-493c-8b05-9a255ef36bec",
      settings: { scannerType: "downMomentum" },
      selectorMenu: "#window_00000000-77f2-493c-8b05-9a255ef36bec_menu",
      selectorContent: "#window_00000000-77f2-493c-8b05-9a255ef36bec_content"
    },
    {
      app: {
        id: "watchlist",
        name: "Visuelle Watchlist",
        emoji: "👀",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.watchlist.view" },
        vueComponentName: "Watchlist"
      },
      uuid: "00000000-5037-4589-b762-61e8e3e22bf4",
      settings: [],
      selectorMenu: "#window_00000000-5037-4589-b762-61e8e3e22bf4_menu",
      selectorContent: "#window_00000000-5037-4589-b762-61e8e3e22bf4_content"
    },
    {
      app: {
        id: "copyTrading",
        name: "Copy-Trading",
        emoji: "✨",
        visibility: 2,
        configuration: {
          isNeutral: true,
          permission: "tradingPlatform.copyTradingCoach.view",
          hideMenuBar: false
        },
        vueComponentName: "CopyTrading"
      },
      uuid: "00000000-b0dd-488a-b3f2-b1552e5c4ac3",
      settings: [],
      selectorMenu: "#window_00000000-b0dd-488a-b3f2-b1552e5c4ac3_menu",
      selectorContent: "#window_00000000-b0dd-488a-b3f2-b1552e5c4ac3_content"
    },
    {
      app: {
        id: "news",
        name: "News",
        emoji: "🗞",
        visibility: 2,
        configuration: { permission: "tradingPlatform.news.view" },
        vueComponentName: "News"
      },
      uuid: "00000000-27fa-47e2-a50c-45ccd5810d6c",
      settings: [],
      selectorMenu: "#window_00000000-27fa-47e2-a50c-45ccd5810d6c_menu",
      selectorContent: "#window_00000000-27fa-47e2-a50c-45ccd5810d6c_content"
    }
  ],
  goldenLayout: {
    root: {
      id: null,
      size: "50%",
      type: "row",
      content: [
        {
          id: null,
          size: "50%",
          type: "stack",
          content: [
            {
              id: null,
              size: "1fr",
              type: "component",
              title: "TradingView: AAPL",
              maximised: false,
              isClosable: true,
              componentType: "vueComponentWindow",
              componentState: {
                uuid: "00000000-31f3-41f0-a585-21ab08b82de4",
                appId: "tradingView"
              },
              reorderEnabled: true
            }
          ],
          maximised: false,
          isClosable: true,
          activeItemIndex: 0
        },
        {
          id: null,
          size: "50%",
          type: "column",
          content: [
            {
              id: null,
              size: "33.33333333333333%",
              type: "stack",
              content: [
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Trading Coach Signals",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-9b85-4576-9579-8e73fb72562b",
                    appId: "signals"
                  },
                  reorderEnabled: true
                }
              ],
              maximised: false,
              isClosable: true,
              activeItemIndex: 0
            },
            {
              id: null,
              size: "33.33333333333333%",
              type: "row",
              content: [
                {
                  id: null,
                  size: "25%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Scanner: Up-Momentum 📈",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-44e4-4cf2-984a-2d48b7ca64e2",
                        appId: "scan"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                },
                {
                  id: null,
                  size: "25%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Scanner: Down-Momentum 📉",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-77f2-493c-8b05-9a255ef36bec",
                        appId: "scan"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                },
                {
                  id: null,
                  size: "50%",
                  type: "stack",
                  content: [
                    {
                      id: null,
                      size: "1fr",
                      type: "component",
                      title: "Visuelle Watchlist",
                      maximised: false,
                      isClosable: true,
                      componentType: "vueComponentWindow",
                      componentState: {
                        uuid: "00000000-5037-4589-b762-61e8e3e22bf4",
                        appId: "watchlist"
                      },
                      reorderEnabled: true
                    }
                  ],
                  maximised: false,
                  isClosable: true,
                  activeItemIndex: 0
                }
              ],
              isClosable: true
            },
            {
              id: null,
              size: "33.33333333333333%",
              type: "stack",
              content: [
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Copy-Trading",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-b0dd-488a-b3f2-b1552e5c4ac3",
                    appId: "copyTrading"
                  },
                  reorderEnabled: true
                },
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "News",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: { uuid: "00000000-27fa-47e2-a50c-45ccd5810d6c", appId: "news" },
                  reorderEnabled: true
                }
              ],
              maximised: false,
              isClosable: true,
              activeItemIndex: 0
            }
          ],
          isClosable: true
        }
      ],
      minSize: "0px",
      isClosable: true
    },
    header: {
      dock: "dock",
      show: "top",
      close: "close",
      popout: "open in new window",
      maximise: "maximise",
      minimise: "minimise",
      tabDropdown: "additional tabs"
    },
    settings: {
      popInOnClose: false,
      reorderEnabled: true,
      responsiveMode: "none",
      popoutWholeStack: false,
      tabControlOffset: 10,
      tabOverlapAllowance: 0,
      closePopoutsOnUnload: true,
      reorderOnTabMenuClick: true,
      blockedPopoutsThrowError: true,
      constrainDragToContainer: true
    },
    dimensions: {
      borderWidth: 5,
      headerHeight: 34,
      dragProxyWidth: 300,
      borderGrabWidth: 5,
      dragProxyHeight: 200,
      defaultMinItemWidth: "10px",
      defaultMinItemHeight: "0px"
    },
    openPopouts: []
  }
};
export default TemplateDefault;
