<template>
  <TransitionRoot :show="isOpen" as="template">
    <Dialog as="div" class="fixed z-40 inset-0 overflow-y-auto" @close="isOpen = false">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span aria-hidden="true" class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
          >
            <div>
              <div
                class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100"
              >
                <CogIcon aria-hidden="true" class="h-6 w-6 text-blue-600" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-slate-900">
                  Einstellungen
                </DialogTitle>
                <div class="mt-2 text-left flex flex-col gap-2 text-base">
                  <h3 class="text-base font-medium pt-4 text-blue-600">Debug-Features</h3>
                  <HtCheckbox v-model="isDebug" :disabled="true"
                    >Debug Modus ist {{ isDebug ? "aktiv" : "inaktiv" }}</HtCheckbox
                  >
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <button
                class="mt-3 w-full inline-flex justify-center rounded-md border border-slate-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-slate-700 hover:bg-slate-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                type="button"
                @click="isOpen = false"
              >
                Schließen
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { CogIcon } from "@heroicons/vue/24/outline";
import HtCheckbox from "../../ui/HtCheckbox.vue";
import { isDebug } from "../../../shared/composables/debug.ts";

export default {
  components: {
    HtCheckbox,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CogIcon
  },
  setup() {
    const isOpen = ref(false);

    return {
      isOpen,
      isDebug
    };
  }
};
</script>
