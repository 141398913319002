import BaseCalculator from "./BaseCalculator";

export default class TransactionPlCalculator extends BaseCalculator {
  // value / quantity
  entryPriceForOpeningTransaction(value: number, quantity: number): number {
    return this.divide(value, quantity);
  }

  // pl / quantity
  closingPrice(value: number, quantity: number): number {
    return this.divide(value, quantity);
  }

  // closingPrice - (pl / quantity)
  entryPriceForClosingTransaction(closingPrice: number, pl: number, quantity: number): number {
    return this.subtract(closingPrice, this.divide(pl, quantity));
  }
}
