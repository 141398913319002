import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useEtnaTrading } from "./etna-trading.ts";
import { TransactionService } from "./../shared/services/TransactionService";
import { createTransaction } from "./../shared/factories/Trading/TransactionFactory";
import { Transaction } from "../shared/types/Trading.ts";
import { Module, useStatus } from "./status.ts";

export const useEtnaTransactions = defineStore("etna-transactions", () => {
  const trading = useEtnaTrading();

  /** Globaler App-Status */
  const status = useStatus();

  const transactions = ref<Record<string, Transaction>>({});
  const loading = ref<boolean>(true);
  const error = ref<boolean>(false);

  const allTransactions = computed(() => transactions.value);
  const isLoading = computed(() => loading.value);
  const hasError = computed(() => error.value);

  const loadTransactions = async (accountId: number): Promise<void> => {
    // Status-Flag setzen
    status.setLoading(Module.tradingHistory);

    if (!loading.value) loading.value = true;
    if (error.value) loading.value = false;

    const transactionsService = new TransactionService(trading.getAxios());

    try {
      const {
        data: { Result }
      } = await transactionsService.getTransactions(accountId);

      transactions.value = Result.reduce(
        (acc, rawTransaction) => ({
          ...acc,
          [rawTransaction.Id]: createTransaction(rawTransaction)
        }),
        {}
      );

      // Status-Flag setzen
      status.setReady(Module.tradingHistory);
    } catch (e) {
      error.value = true;
      console.error(e, "while executing loadTransactions");

      // Status-Flag setzen
      status.setError(Module.tradingHistory, e.message);
    } finally {
      loading.value = false;
    }
  };

  return {
    loadTransactions,
    allTransactions,
    isLoading,
    hasError
  };
});
