export enum AuthState {
  UNAUTHENTICATED = 1000,
  AUTHENTICATED = 2000,
  MUTATING = 3000,
  UNKNOWN = 4000
}

export type AuthInformation = {
  state: AuthState;
  token: string;
  userId: string;
};
