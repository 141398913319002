import { useEtnaTrading } from "../../../stores/etna-trading.ts";
import { OrderService } from "../OrderService.ts";
import { NotificationService } from "../NotificationService.ts";

export class CancelOrder {
  private readonly notifier: NotificationService;

  public constructor() {
    this.notifier = new NotificationService();
  }

  public exec = async (orderId: number): Promise<void> => {
    try {
      const tradingStore = useEtnaTrading();
      const orderService = new OrderService(tradingStore.getAxios());
      await orderService.cancelOrder(tradingStore.accountId as unknown as number, orderId);
    } catch (e) {
      console.error(e, "while trying to execute CancelOrder");
      this.notifier.error({
        title: "Error",
        text: "Cannot cancel the order, please try again later"
      });
    }
  };
}
