import { defineStore } from "pinia";
import { ref } from "vue";

export enum Status {
  Ready = "ready",
  Uninitialized = "uninitialized",
  Loading = "loading",
  Error = "error",
  Disabled = "disabled"
}

export enum Module {
  websocket = "websocket",
  auth = "auth",
  tradingAccounts = "tradingAccounts",
  tradingOrders = "tradingOrders",
  tradingPositions = "tradingPositions",
  tradingHistory = "tradingHistory",
  tradingAlerts = "tradingAlerts",
  stockdataSnapshot = "stockdataSnapshot",
  stockdataHolidays = "stockdataHolidays",
  stockdataTickers = "stockdataTickers",
  userSettings = "userSettings",
  workspaceLayouts = "workspaceLayouts"
}

export const ModuleNames: Record<Module, string> = {
  websocket: "Echtzeitdaten",
  auth: "Benutzerdaten",
  tradingAccounts: "Trading-Konten",
  tradingOrders: "Orders",
  tradingPositions: "Positionen",
  tradingHistory: "Transaktionen",
  tradingAlerts: "Trigger",
  stockdataSnapshot: "Börsendaten",
  stockdataHolidays: "Börsenfeiertage",
  stockdataTickers: "Börsen-Ticker",
  userSettings: "Benutzereinstellungen",
  workspaceLayouts: "gespeicherte Layouts"
};

export const useStatus = defineStore("appStatus", () => {
  const messages = ref<{ [key in Module]: string }>({
    websocket: null,
    auth: null,
    tradingAccounts: null,
    tradingOrders: null,
    tradingPositions: null,
    tradingHistory: null,
    tradingAlerts: null,
    stockdataSnapshot: null,
    stockdataHolidays: null,
    stockdataTickers: null,
    userSettings: null,
    workspaceLayouts: null
  });

  const websocket = ref<Status>(Status.Uninitialized);
  const auth = ref<Status>(Status.Uninitialized);
  const tradingAccounts = ref<Status>(Status.Uninitialized);
  const tradingOrders = ref<Status>(Status.Uninitialized);
  const tradingPositions = ref<Status>(Status.Uninitialized);
  const tradingHistory = ref<Status>(Status.Uninitialized);
  const tradingAlerts = ref<Status>(Status.Uninitialized);
  const stockdataSnapshot = ref<Status>(Status.Uninitialized);
  const stockdataHolidays = ref<Status>(Status.Uninitialized);
  const stockdataTickers = ref<Status>(Status.Uninitialized);
  const userSettings = ref<Status>(Status.Uninitialized);
  const workspaceLayouts = ref<Status>(Status.Uninitialized);

  function setLoading(module: Module) {
    this[module] = Status.Loading;
  }

  function setReady(module: Module, message?: string | undefined) {
    this[module] = Status.Ready;
    messages.value[module] = message;
  }

  function setError(module: Module, errorMessage?: string | undefined) {
    this[module] = Status.Error;
    messages.value[module] = errorMessage;
  }

  function setDisabled(module: Module, message?: string | undefined) {
    this[module] = Status.Disabled;
    messages.value[module] = message;
  }

  return {
    setError,
    setLoading,
    setReady,
    setDisabled,
    websocket,
    auth,
    tradingAccounts,
    tradingOrders,
    tradingHistory,
    tradingPositions,
    tradingAlerts,
    stockdataSnapshot,
    stockdataTickers,
    stockdataHolidays,
    userSettings,
    workspaceLayouts
  };
});
