import {
  ComplexOrderOco,
  Side,
  SimpleLimitOrder,
  SimpleStopOrder,
  SlTpChangeOrderPayload
} from "../../types/Trading.ts";
import { createLimitOrder, createOcoOrder, createStopOrder } from "./SpecificOrderFactories.ts";

export class OrderWithSlTpFactory {
  constructor(private readonly payload: SlTpChangeOrderPayload) {}

  create(): ComplexOrderOco | SimpleLimitOrder | SimpleStopOrder | undefined {
    const { symbol, quantity, stopLoss, takeProfit } = this.payload;
    const side = this.getOrderSide();

    if (stopLoss && takeProfit)
      return createOcoOrder({
        symbol,
        side,
        quantity,
        stopLoss,
        takeProfit
      });
    if (stopLoss)
      return createStopOrder({
        symbol,
        quantity,
        side,
        price: stopLoss,
        typeSlTp: "sl"
      });
    if (takeProfit)
      return createLimitOrder({
        symbol,
        quantity,
        side,
        price: takeProfit,
        typeSlTp: "tp"
      });
  }

  private getOrderSide(): Extract<Side, "BuyToCover" | "Sell"> {
    return this.payload.position === "short" ? "BuyToCover" : "Sell";
  }
}
