import { TransactionPlCalculator } from "./../../../shared/services/TradingCalculations/index.ts";
import { formatDateWithTimeAndSeconds } from "../../composables/formatter.ts";
import { RawTransaction, Transaction } from "../../types/Trading.ts";

export const createTransaction = (raw: RawTransaction): Transaction => {
  const price = Math.abs(
    new TransactionPlCalculator().entryPriceForOpeningTransaction(raw.Fee.Value, raw.Quantity)
  );

  return {
    id: raw.Id,
    date: raw.Date,
    quantity: raw.Quantity,
    symbol: raw.Symbol,
    pl: raw.Pl,
    price
  };
};
