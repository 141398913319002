<template>
  <AbstractDialog
    ref="abstractDialog"
    :icon="CogIcon"
    :show-cancel="false"
    :show-submit="true"
    submit-text="Speichern und schließen"
  >
    <template #title>Einstellungen</template>
    <template #content>
      <div class="flex flex-col gap-4 items-start">
        <!-- Instanz -->
        <div v-if="copyTradingStore.hasMoreThanOneInstance ?? false" class="flex items-start">
          <div class="flex h-5 items-center">
            <SpinningLoader v-if="copyTradingStore.isLoading" class="h-5 w-5 mr-1.5" />
            <div v-else class="h-5 w-5 mr-1.5" />
          </div>
          <div class="ml-3 text-base">
            <!-- Titel -->
            <label
              for="instance"
              class="font-medium text-blue-600 cursor-pointer block mb-1 text-plus"
            >
              Ausgewählte Instanz
            </label>

            <p class="text-slate-500">
              Du hast mehrere Produkte, die dir Zugriff zum Copy-Trading Coach geben.
            </p>

            <br />

            <p class="text-slate-500">
              Für jedes Produkt kannst du einzelne Wetten setzen. Wähle hier aus, welche Instanz
              angezeigt werden soll.
            </p>

            <Listbox v-model="copyTradingStore.instanceId" as="div">
              <div class="mt-3">
                <ListboxButton
                  class="relative w-80 cursor-default rounded-md border border-slate-300 text-slate-700 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                >
                  <span class="block truncate">{{ copyTradingStore.instance?.name }}</span>
                  <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronUpDownIcon class="h-5 w-5 text-slate-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      v-for="(instance, id) in copyTradingStore.instances"
                      :key="id"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="id"
                    >
                      <li
                        :class="[
                          active ? 'text-white bg-blue-600' : 'text-slate-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9 group'
                        ]"
                      >
                        <p :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          {{ instance.name }}
                        </p>

                        <p
                          :class="[
                            active ? 'text-white' : 'text-slate-500',
                            'group-hover:text-white'
                          ]"
                        >
                          {{ formatDollars(instance.pnl.realized, true) }}
                          von
                          {{ formatDollars(instance.pnl.allowed, true) }}
                          erreicht
                        </p>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-blue-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
        </div>

        <!-- Autobet -->
        <div class="flex items-start">
          <div class="flex h-5 items-center">
            <SpinningLoader v-if="copyTradingStore.isLoading" class="h-5 w-5 mr-1.5" />
            <HtCheckbox v-else v-model="copyTradingStore.instance.autobet" inputid="autobet" />
          </div>
          <div class="ml-3 text-base">
            <!-- Titel -->
            <label
              for="autobet"
              class="font-medium text-blue-600 cursor-pointer block mb-1 text-plus"
            >
              Automatischer Einsatz
            </label>

            <!-- Aktiv -->
            <template v-if="copyTradingStore.instance.autobet">
              <p class="text-slate-500">Der Auto-Einsatz ist aktuell aktiv.</p>
              <br />
            </template>

            <!-- Nicht aktiv -->
            <template v-if="!copyTradingStore.instance.autobet">
              <p class="text-slate-500">Der Auto-Einsatz ist aktuell deaktiviert.</p>
              <p class="text-slate-500">Es finden keine automatischen Einsätze statt.</p>
            </template>

            <!-- Erklärung -->
            <p class="text-slate-700 font-medium mt-2">Was macht der automatische Einsatz?</p>
            <p class="text-slate-500">
              Sollten bis zum Sonntag Abend keine Einsätze getätigt worden sein, werden automatisch
              die Einsätze der Vorwoche verwendet. Sobald manuell Einsätze in der Folgewoche
              verteilt werden, wird der Auto-Einsatz überschieben und die manuell verteilten
              Einsätze werden angewendet.
            </p>
          </div>
        </div>
      </div>
    </template>
  </AbstractDialog>
</template>

<script>
import AbstractDialog from "../../../../AbstractDialog.vue";
import HtCheckbox from "./../../../../../../components/ui/HtCheckbox.vue";
import { useCopyTradingStore } from "./../../../../../../stores/copyTrading";
import SpinningLoader from "./../../../../../../components/ui/HtSpinningLoader.vue";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { CogIcon } from "@heroicons/vue/24/outline";
import { formatDollars } from "./../../../../../../shared/composables/formatter";

export default {
  name: "HtCopyTradingSettings",

  components: {
    SpinningLoader,
    AbstractDialog,
    HtCheckbox,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon
  },

  setup() {
    // Trading-Store laden
    const copyTradingStore = useCopyTradingStore();

    return {
      copyTradingStore
    };
  },
  methods: { CogIcon, formatDollars }
};
</script>
