import { OrderWithSlTpFactory } from "../../factories/Trading/OrderWithSlTpFactory.ts";
import { RawOrder, SlTpChangeOrderPayload } from "../../types/Trading.ts";
import { isRealOrderPlacementAvailable, OrderService } from "../OrderService.ts";
import { useEtnaTrading } from "../../../stores/etna-trading.ts";
import { NotificationService } from "../NotificationService.ts";
import { AxiosResponse } from "axios";

export class ChangePositionSlTp {
  private readonly notifier: NotificationService;

  public constructor() {
    this.notifier = new NotificationService();
  }

  public exec = async (payload: SlTpChangeOrderPayload): Promise<AxiosResponse<RawOrder>> => {
    if (!payload.stopLoss && !payload.takeProfit) {
      this.notifier.warn({
        title: "Warning",
        text: "Please enter the value for Stop Loss or Take Profit"
      });
      return;
    }

    const trading = useEtnaTrading();
    const accountId = trading.accountId as unknown as number;
    const orderService = new OrderService(trading.getAxios());
    const order = new OrderWithSlTpFactory(payload).create();

    try {
      if (order && isRealOrderPlacementAvailable()) {
        await orderService.cancelActiveSlTpOrdersBySymbol(accountId, payload.symbol);
        return await orderService.placeOrder(accountId, order);
      } else {
        alert(`\n\nPayload: ${JSON.stringify(order)}`);
      }
    } catch (e) {
      console.error(e, "while trying to execute ChangePositionSlTp action");
    }
  };
}
