import { WorkspaceProLayout } from "../../shared/types/WorkspaceApp.ts";

export const TemplateTestTrader: WorkspaceProLayout = {
  instances: [
    {
      app: {
        id: "tradingView",
        name: "TradingView",
        iconPath: "/images/icons/trading_view.svg",
        visibility: 2,
        configuration: { permission: "tradingPlatform.chart.view", hideMenuBar: true },
        vueComponentName: "TradingView",
        maximumNumberOfInstances: 1
      },
      uuid: "00000000-2486-40ad-9907-58e137e755c5",
      settings: [],
      selectorMenu: "#window_00000000-2486-40ad-9907-58e137e755c5_menu",
      selectorContent: "#window_00000000-2486-40ad-9907-58e137e755c5_content"
    },
    {
      app: {
        id: "simpleOrderPanel",
        name: "Intelligentes Order-Panel",
        emoji: "?",
        visibility: 2,
        configuration: { permission: "tradingPlatform.trading.orderPanel.view" },
        vueComponentName: "Trading/SimpleOrderPanel"
      },
      uuid: "00000000-5061-4ba1-80d5-d8834aac54e7",
      settings: [],
      selectorMenu: "#window_00000000-5061-4ba1-80d5-d8834aac54e7_menu",
      selectorContent: "#window_00000000-5061-4ba1-80d5-d8834aac54e7_content"
    },
    {
      app: {
        id: "watchlist",
        name: "Visuelle Watchlist",
        emoji: "👀",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.watchlist.view" },
        vueComponentName: "Watchlist"
      },
      uuid: "00000000-7c73-4671-a354-c22df423b0cc",
      settings: [],
      selectorMenu: "#window_00000000-7c73-4671-a354-c22df423b0cc_menu",
      selectorContent: "#window_00000000-7c73-4671-a354-c22df423b0cc_content"
    },
    {
      app: {
        id: "positions",
        name: "Positions",
        emoji: "?",
        visibility: 2,
        configuration: {
          hideMenuBar: true,
          isNeutral: true,
          permission: "tradingPlatform.trading.positions.view"
        },
        vueComponentName: "Trading/Positions"
      },
      uuid: "00000000-be43-4008-97ff-33cb16eb9dbc",
      settings: [],
      selectorMenu: "#window_00000000-be43-4008-97ff-33cb16eb9dbc_menu",
      selectorContent: "#window_00000000-be43-4008-97ff-33cb16eb9dbc_content"
    },
    {
      app: {
        id: "orders",
        name: "Orders",
        emoji: "?",
        visibility: 2,
        configuration: {
          isNeutral: true,
          permission: "tradingPlatform.trading.orders.view",
          hideMenuBar: false
        },
        vueComponentName: "Trading/Orders"
      },
      uuid: "00000000-b117-4f12-b762-f4a70ae818df",
      settings: [],
      selectorMenu: "#window_00000000-b117-4f12-b762-f4a70ae818df_menu",
      selectorContent: "#window_00000000-b117-4f12-b762-f4a70ae818df_content"
    },
    {
      app: {
        id: "tradingTransactions",
        name: "Trading Transaktionen",
        emoji: "?",
        visibility: 2,
        configuration: {
          isNeutral: true,
          permission: "tradingPlatform.trading.tradeHistory.show",
          hideMenuBar: false
        },
        vueComponentName: "Trading/Transactions"
      },
      uuid: "00000000-ce0e-4f3d-a2e0-32661457fc3c",
      settings: [],
      selectorMenu: "#window_00000000-ce0e-4f3d-a2e0-32661457fc3c_menu",
      selectorContent: "#window_00000000-ce0e-4f3d-a2e0-32661457fc3c_content"
    },
    {
      app: {
        id: "screener",
        name: "Aktien-Screener",
        emoji: "🔍",
        visibility: 2,
        configuration: { isNeutral: true },
        vueComponentName: "Screener"
      },
      uuid: "00000000-7cbf-4ff1-a688-8aa3c6ef43a0",
      settings: {
        order: {
          parameter: "day.v",
          directionLargeToSmall: false
        },
        filter: {
          fmv: {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "day.v": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.g": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          ticker: null,
          "calc.dv": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.pc": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.atr": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.v_avg": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          },
          "calc.v_rel": {
            max: null,
            min: null,
            type: "between_min_and_max",
            active: false,
            modalOpen: false
          }
        }
      },
      selectorMenu: "#window_00000000-7cbf-4ff1-a688-8aa3c6ef43a0_menu",
      selectorContent: "#window_00000000-7cbf-4ff1-a688-8aa3c6ef43a0_content"
    },
    {
      app: {
        id: "news",
        name: "News",
        emoji: "🗞",
        visibility: 2,
        configuration: { permission: "tradingPlatform.news.view" },
        vueComponentName: "News"
      },
      uuid: "00000000-33c7-47cf-8806-9a363584eb59",
      settings: [],
      selectorMenu: "#window_00000000-33c7-47cf-8806-9a363584eb59_menu",
      selectorContent: "#window_00000000-33c7-47cf-8806-9a363584eb59_content"
    },
    {
      app: {
        id: "scan",
        name: "Scan",
        emoji: "🗒️",
        visibility: 2,
        configuration: { isNeutral: true, permission: "tradingPlatform.scanner.view" },
        defaultSettings: { scannerType: "volume" },
        vueComponentName: "Scan"
      },
      uuid: "00000000-c1fd-4cf3-84e3-d5d3ddb52932",
      settings: { scannerType: "volume" },
      selectorMenu: "#window_00000000-c1fd-4cf3-84e3-d5d3ddb52932_menu",
      selectorContent: "#window_00000000-c1fd-4cf3-84e3-d5d3ddb52932_content"
    }
  ],
  goldenLayout: {
    root: {
      id: null,
      size: "50%",
      type: "row",
      content: [
        {
          id: null,
          size: "80%",
          type: "column",
          content: [
            {
              id: null,
              size: "33.33333333333333%",
              type: "stack",
              content: [
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Positions",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-be43-4008-97ff-33cb16eb9dbc",
                    appId: "positions"
                  },
                  reorderEnabled: true
                },
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Orders",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: { uuid: "00000000-b117-4f12-b762-f4a70ae818df", appId: "orders" },
                  reorderEnabled: true
                },
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Trading Transaktionen",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-ce0e-4f3d-a2e0-32661457fc3c",
                    appId: "tradingTransactions"
                  },
                  reorderEnabled: true
                },
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Aktien-Screener",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-7cbf-4ff1-a688-8aa3c6ef43a0",
                    appId: "screener"
                  },
                  reorderEnabled: true
                },
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "Visuelle Watchlist",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-7c73-4671-a354-c22df423b0cc",
                    appId: "watchlist"
                  },
                  reorderEnabled: true
                },
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "News",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: { uuid: "00000000-33c7-47cf-8806-9a363584eb59", appId: "news" },
                  reorderEnabled: true
                }
              ],
              maximised: false,
              isClosable: true,
              activeItemIndex: 0
            },
            {
              id: null,
              size: "66.66666666666666%",
              type: "stack",
              content: [
                {
                  id: null,
                  size: "1fr",
                  type: "component",
                  title: "TradingView: ZIM",
                  maximised: false,
                  isClosable: true,
                  componentType: "vueComponentWindow",
                  componentState: {
                    uuid: "00000000-2486-40ad-9907-58e137e755c5",
                    appId: "tradingView"
                  },
                  reorderEnabled: true
                }
              ],
              maximised: false,
              isClosable: true,
              activeItemIndex: 0
            }
          ],
          isClosable: true
        },
        {
          id: null,
          size: "20%",
          type: "stack",
          content: [
            {
              id: null,
              size: "1fr",
              type: "component",
              title: "Intelligentes Order-Panel",
              maximised: false,
              isClosable: true,
              componentType: "vueComponentWindow",
              componentState: {
                uuid: "00000000-5061-4ba1-80d5-d8834aac54e7",
                appId: "simpleOrderPanel"
              },
              reorderEnabled: true
            },
            {
              id: null,
              size: "1fr",
              type: "component",
              title: "Scanner: Volumen 📊",
              maximised: false,
              isClosable: true,
              componentType: "vueComponentWindow",
              componentState: { uuid: "00000000-c1fd-4cf3-84e3-d5d3ddb52932", appId: "scan" },
              reorderEnabled: true
            }
          ],
          maximised: false,
          isClosable: true,
          activeItemIndex: 0
        }
      ],
      minSize: "0px",
      isClosable: true
    },
    header: {
      dock: "dock",
      show: "top",
      close: "close",
      popout: "open in new window",
      maximise: "maximise",
      minimise: "minimise",
      tabDropdown: "additional tabs"
    },
    settings: {
      popInOnClose: false,
      reorderEnabled: true,
      responsiveMode: "none",
      popoutWholeStack: false,
      tabControlOffset: 10,
      tabOverlapAllowance: 0,
      closePopoutsOnUnload: true,
      reorderOnTabMenuClick: true,
      blockedPopoutsThrowError: true,
      constrainDragToContainer: true
    },
    dimensions: {
      borderWidth: 5,
      headerHeight: 34,
      dragProxyWidth: 300,
      borderGrabWidth: 5,
      dragProxyHeight: 200,
      defaultMinItemWidth: "10px",
      defaultMinItemHeight: "0px"
    },
    openPopouts: []
  }
};
