// @ts-nocheck
import { Order, OrderStatus, OrderStatusDescription } from "./Trading.ts";
import { MessageType } from "./../services/NotificationService";

type OrderStatusExplanation = {
  text: string;
  word: string;
};

const OrderStatusExplanations: Record<OrderStatus, OrderStatusExplanation> = {
  Canceled: {
    text: "Die Order wurde storniert.",
    word: "Storniert"
  },
  Filled: {
    text: "Die Order wurde ausgeführt.",
    word: "Ausgeführt"
  },
  New: {
    text: "Die Order wurde erstellt.",
    word: "Erstellt"
  },
  PartiallyFilled: {
    text: "Die Order wurde teilweise ausgeführt.",
    word: "Teilweise ausgeführt"
  },
  Rejected: {
    text: "Die Order wurde abgelehnt.",
    word: "Abgelehnt"
  },
  Suspended: {
    text: "Die Order wurde zurückgewiesen.",
    word: "Zurückgewiesen"
  },
  PendingNew: {
    text: "Die Order wird erstellt.",
    word: "Erstellen…"
  },
  PendingReplace: {
    text: "Die Order wird ersetzt.",
    word: "Ersetzen…"
  },
  Stopped: {
    text: "Die Order wurde gestoppt.",
    word: "Gestoppt"
  },
  Hold: {
    text: "Die Order wird gehalten.",
    word: "Gehalten"
  },
  Replaced: {
    text: "Die Order wurde ersetzt.",
    word: "Ersetzt"
  }
};

type OrderStatusDescriptionExplanation = {
  type?: MessageType;
  showNotification: boolean;
  text: (order: Order) => string;
};

const OrderStatusDescriptionExplanations: Record<
  OrderStatusDescription,
  OrderStatusDescriptionExplanation
> = {
  Canceled: {
    type: "info",
    showNotification: true,
    text: (order: Order) => "Order wurde storniert"
  },
  New: {
    type: "info",
    showNotification: true,
    text: (order: Order) =>
      order.target === "Modify" ? "Order wurde erfolgreich bearbeitet." : "Order wurde platziert"
  },
  Held: {
    type: "info",
    showNotification: true,
    text: (order: Order) =>
      (order.type === "Stop" ? "Stop-Loss" : "Take-Profit") +
      " Order " +
      (order.target === "Modify" ? "bearbeitet." : "platziert.")
  },
  InitialMargin: {
    showNotification: true,
    type: "error",
    text: (order: Order) => "You do not have enough buying power for this trade."
  },
  FatFingersValueExceedsMaximum: {
    showNotification: true,
    type: "error",
    text: (order: Order) => "Order value exceeds maximum allowed value."
  },
  Filled: {
    showNotification: true,
    type: "success",
    text: (order: Order) => "Order wurde erfolgreich ausgeführt."
  },
  PendingNew: {
    showNotification: false,
    type: "info",
    text: (order: Order) => "Order wird platziert."
  },
  PendingReplace: {
    showNotification: false,
    type: "info",
    text: (order: Order) => "Order wird bearbeitet."
  },
  PendingCancel: {
    showNotification: false,
    type: "info",
    text: (order: Order) => "Order wird storniert."
  },
  Replaced: {
    showNotification: false,
    type: "info",
    text: (order: Order) => "Order wurde ersetzt."
  },
  ShortOrderIsGtc: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "You cannot place short stock orders with GTC, only day orders are allowed."
  },
  LongOptionTradingDeniedForAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Account is restricted from long option trading."
  },
  SecurityUndefined: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Symbol does not exist."
  },
  IndexOptionsOneExparyDate: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "Multi Leg Orders with Index options must have all legs within 1 expiration date. Time spreads are not allowed on Index Options."
  },
  ContingentOrderExecution: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Placement condition: Necessary certain criteria are not met."
    //text: (order: Order) => "Placement Condition: when {0} {1} is {2} {3} "
  },
  SellStopOrderStopPriceGreaterBid: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Sell Stop order must have a Stop price less than the Bid price."
  },
  TradeOptionAfter1pmAtExpirationDay: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "Orders for options that expire today are limited to closing transactions only after 1 p.m. EST"
  },
  ShortTradingDeniedForSecurity: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "This symbol is not available for shorting. Please contact our trade desk."
  },
  StockTradingDeniedForAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Account is restricted from long stock trading."
  },
  BuyStopOrderStopPriceLessAsk: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Buy Stop order must have a Stop price greater than the Ask price."
  },
  StagingBlotterAlreadyCompleted: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Staging blotter already completed."
  },
  OcoExpirationTypeNotTheSame: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Expiration type of OCO orders must be the same."
  },
  TradingIsNotAllowedWithoutAnAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Trading is not allowed without an account"
  },
  TooSmallEquityForDayTrading: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Pattern Day Trader Rule violation: Equity balance fell below $25,000."
  },
  OtoFirstLegIsMarketNotAllowed: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "First market order in OTO is not allowed."
  },
  IncorrectTimeInForce: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Time In Force (Day or GTC) is not defined."
  },
  TradeOTC: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "You cannot place order on OTCBB or Pinks Securities."
  },
  OrderQuantity: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "You cannot place orders with quantity less than 1."
  },
  RatioSpreadRestriction: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "Ratio spreads are not allowed. Please contact the Trade Desk for further assistance."
  },
  OrderPriceIsInvalid: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "The price of the order is invalid. It should be closer to the market price."
    //text: (order: Order) => "Price of {0} {1} order is {2} than market price."
  },
  TickSizePilotDivisionLimitPrice: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "The specified limit price does not conform to the Tick Size Pilot Program rules."
    //text: (order: Order) =>
    //  "The limit price {0} is not a multiple of {1} US-Finra Tick Size Pilot Program."
  },
  FatFingersStockSizeExceedsMaximum: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Order Size exceeds the maximum number of shares allowed."
  },
  OpeningDeniedForAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Account is restricted from position opening."
  },
  StrikePriceUndefined: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Strike price for option leg is not defined."
  },
  SpreadTradingDeniedForAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Account is restricted for spread trading."
  },
  UnexpectedSellOrder: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Sell order cannot be placed for short or flat position."
  },
  AccountIsNotApproved: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Account is not approved for trading. Please contact our support team."
  },
  OmsUnavailable: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Sorry, the order service is not available, please try later."
  },
  OcoPriceDifferenceIsLessThanDelta: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "The OCO price difference is not big enough."
    //text: (order: Order) => "OCO price difference should be at least {0}$."
  },
  TickSizePilotDivisionStopPrice: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "The specified stop price does not conform to the rules of the Tick Size Pilot Program."
    //text: (order: Order) =>
    //  "The stop price {0} is not a multiple of {1} US-Finra Tick Size Pilot Program."
  },
  OrderWithDifferentSide: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "You cannot have pending orders with different sides for selected symbol where one is a MARKET order. You must close another pending order in order to place a SHORT order, or try a limit order instead."
  },
  OtoOcoMarketNotAllowed: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "OCO market orders are not allowed."
  },
  PositionLocked: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Position is locked."
  },
  WashTradeAttempt: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "You cannot place orders on the same security and on the same price but in different directions."
  },
  RejectRecommendationByThresHold: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "There are too many ideas with the such description in the system. Please contact to your supervisor to solve this issue."
  },
  OtoOcoForex_NonForexAreNotAllowed: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "OTO/OCO orders with combination of Forex and non-Forex securities are not allowed"
  },
  MarketOrderIsGtc: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "You cannot place market orders with GTC, only day orders are allowed."
  },
  ShortOptionTradingDeniedForAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Account is restricted from short option trading."
  },
  StagingBlotterWrongPrice: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Staging blotter wrong price."
  },
  FuturesDeniedForMlegOrders: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Multi-leg option strategy can\u0027t have a Futures leg"
  },
  PennyPilotDivisionPrice: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "The price must be divisible by the tick size."
    //text: (order: Order) => "The price must be divisible by {0}."
  },

  UserDisabled: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Account is disabled for trading. Please contact our support team."
  },
  QuantityConsistency: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "The precision of the maximum quantity is not correct."
    //text: (order: Order) => "Max quantity precision is {0}."
  },
  CommissionCalculationFailed: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Unable to calculate the commission."
  },
  TradingIsNotAllowedForAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Trading using this account is not allowed."
  },
  FatFingersTradeBands: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "The placed Order deviates by more than a specified percentage from the reference price."
    //text: (order: Order) => "The Order you have placed is more than {0}% away from the {1} price."
  },
  StagingBlotterWrongType: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Wrong Order type."
  },
  DayTraderPatternRestriction: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Your day trade limit exceeded."
  },
  TradingDeniedForAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Account is restricted for trading."
  },
  DayTradingBuyingPowerExceeded: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "There is not enough day trading buying power."
  },
  OptionLevelRestriction: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "The required minimum equity balance is not met or you may not have permissions to place this trade. Please contact the Trade Desk for further assistance."
  },
  InvalidOrderExpiration: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Expiration date must be greater than the current date."
  },
  StopPriceUndefined: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Stop price is not defined."
  },
  IncorrectOrderQuantity: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Quantity should be between 1 and 10,000,000."
  },
  DuplicateSecuritiesMulilegOrder: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Some legs of the order have an identical security."
  },
  OtoOcoTrailingNotAllowed: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "OTO/OCO trailing orders are not allowed."
  },
  UnexpectedBuyOrder: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "Buy order cannot be placed for short position or if there are pending Sell Short orders."
  },
  AccountDisabled: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Account is disabled for trading. Please contact our support team."
  },
  UnexpectedBuyToCoverOrder: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Buy To Cover order cannot be placed for long or flat positions."
  },
  LongPositionCrossZero: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "You cannot sell more shares than your effective long position (effective long position = long position less open sell orders) using one order ticket. Industry regulations require that two order tickets be submitted: one to sell your effective long position and one to sell your desired short position."
  },
  TradeNonStandartOptions: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "You cannot place an order with non standard options."
  },
  TradingDeniedForSecurity: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "This asset class is restricted for trading."
  },
  SnapQuoteIsInvalid: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Failed to get snap quote."
  },
  OrderContingentChangeNotAllowed: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Change of order\u0027s contingent is not allowed."
  },
  TickSizePilotTrailingAmount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "The Trailing Amount value must be a multiple of the tick size."
    //text: (order: Order) => "Trailing Amount value should be a multiple of {0}."
  },

  AonOrderQuantity: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "You cannot place AON orders with quantity less than 2."
  },
  SellShortOrderLastPriceBelow5: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Sell Short order cannot be placed for stock priced below $5."
  },
  ExpirationDateUndefined: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Expiration date for option is not defined."
  },
  QuotePriceIsInvalid: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "There is no price. Please try your order again."
  },
  OmsInternalError: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "The order service could not process the order (unknown error)."
  },
  AccountDoesNotBelongToUser: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "You cannot trade from this account."
  },
  LimitPriceUndefined: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Limit price is not defined."
  },
  OptionTypeUndefined: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Type of option (call or put) is not defined."
  },
  OrderIsNotAllowedForAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "This order is not allowed for this account."
  },
  ShortStockTradingDeniedForAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Account is restricted from short stock trading."
  },
  AssetTradingNotConfiguredForAccount: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "You cannot trade this type of asset."
  },
  PricePrecisionIsOutOfRange: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "Increment of the Price can not be smaller than $0.01 if Price is equal to or greater than $1.00 per share and smaller than $0.0001 if Price is less than $1.00 per share."
  },
  ShortPositionCrossZero: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "You cannot buy (to cover) more shares than your effective short position (effective short position = short position plus open buy orders) using one order ticket. Industry regulations require that two order tickets be submitted: one to cover your effective short position and one to buy your desired long position."
  },
  TotalInitialMargin: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "You do not have enough buying power for this trade."
  },
  OcoOrderWithOppositeLegs: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "You cannot place OCO order with orders for the same security and with opposite trade directions"
  },
  UnexpectedSellOrderOption: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Sell To Close order cannot be placed for short and flat position."
  },
  FatFingersOptionsSizeExceedsMaximum: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Order Size exceeds the maximum number of contracts allowed."
  },
  UnexpectedSellShortOrder: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "Sell Short order cannot be placed for long position or if there are pending Buy orders."
  },
  UnsupportedOrderSide: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Specified order side is not supported."
  },
  AccountMarginRuleViolation: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Margin rules prohibit this transaction. Please contact our trade desk."
  },
  UnexpectedBuyToCoverOrderOption: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Buy To Close order cannot be placed for long or flat positions."
  },
  StagingBlotterMaxFloorWrongQty: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Wrong Max Floor quantity."
  },
  UnexpectedBuyOrderOption: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "Buy To Open order cannot be placed for short position if there are pending Sell To Open orders."
  },
  MaintenanceMargin: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "You do not have enough buying power for this trade."
  },
  UnexpectedSellShortOrderOption: {
    showNotification: true,
    type: "warn",
    text: (order: Order) =>
      "Sell To Open order cannot be placed for long position if there are pending Buy To Open orders."
  },
  PriceOutOfMarket: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "The price of the placed order is not within the current market range."
    //text: (order: Order) => "The limit {0} order you have placed is {1} than the current price."
  },
  StagingBlotterExceedQuantity: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "Staging blotter exceed quantity."
  },
  TickSizePilotLimitOffset: {
    showNotification: true,
    type: "warn",
    text: (order: Order) => "The Limit Offset value must be a multiple of the tick size."
    //text: (order: Order) => "Limit Offset value should be a multiple of {0}."
  }
};

export {
  OrderStatusExplanation,
  OrderStatusDescriptionExplanation,
  OrderStatusExplanations,
  OrderStatusDescriptionExplanations
};
