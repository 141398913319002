import BaseCalculator from "./BaseCalculator";

export default class PlCalculator extends BaseCalculator {
  // (currentPrice - avgOpenPrice) * openSize
  long(currentPrice: number, avgOpenPrice: number, openSize: number): number {
    return this.canCalc(currentPrice, avgOpenPrice, "and")
      ? this.reduceDecimals(this.multiply(this.subtract(currentPrice, avgOpenPrice), openSize), 6)
      : 0;
  }

  // (avgOpenPrice - currentPrice) * openSize
  short(currentPrice: number, avgOpenPrice: number, openSize: number): number {
    const openSizePositive = this.multiply(openSize, -1);
    return this.canCalc(currentPrice, avgOpenPrice, "and")
      ? this.reduceDecimals(
          this.multiply(this.subtract(avgOpenPrice, currentPrice), openSizePositive),
          6
        )
      : 0;
  }
}
