import { defineStore } from "pinia";
import axios from "axios";
import { useStorage } from "@vueuse/core";
import { debug, info } from "../shared/services/LoggingService.ts";
import { notify } from "@kyvg/vue3-notification";

import * as Sentry from "@sentry/vue";
import { useAuth } from "./auth.ts";

export const useAxios = defineStore("axios", () => {
  // Axios-Instanz erstellen und Base-URL setzen
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL
  });

  // Token aus dem Storage holen
  const tokenStorage = useStorage("token", null);

  // Aktuelle Version mitsenden
  axiosInstance.defaults.headers.common["X-Trading-App-Version"] = GIT_VERSION;

  // Add a request interceptor
  axiosInstance.interceptors.request.use(function (config) {
    // Bearer-Token setzen
    if (tokenStorage.value !== null) {
      config.headers["Authorization"] = `Bearer ${tokenStorage.value}`;
    }

    return config;
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // Bei 429-Error Notification zum Rate Limit anzeigen
      if (error?.response?.status === 429) {
        notify({
          title: "Zu viele Anfragen!",
          text: "Von deiner IP-Adresse wurden zu viele Anfragen gestellt. Bitte warte einen Augenblick, bevor du weitere Anfragen stellst.",
          type: "error"
        });
      }

      // Bei 401-Error und 403-Error den Token prüfen
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        console.log(error.config.url);

        // Kein Handling für /api/token und /api/me Endpunkt
        if (
          error.config.url === "/api/token" ||
          error.config.url === "/api/me" ||
          error.config.url === "/api/trading/token"
        ) {
          // Log-Ausgabe
          debug(
            "Fehler 401 oder 403 bei Auth-Request. Das ist im Rahmen der Token-Gültigkeit-Prüfung in Ordnung."
          );
        } else {
          // Log-Ausgabe
          info(
            "Request mit Fehler 401 oder 403 fehlgeschlagen: Prüfe nun, ob der Token noch gültig ist."
          );

          // Auth-Check durchlaufen lassen
          const auth = useAuth();
          auth.checkToken();
        }
      }

      // Sentry-Error-Handling
      Sentry.captureException(error);

      // Promise mit Fehler zurückgeben
      return Promise.reject(error);
    }
  );

  return {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    delete: axiosInstance.delete,
    del: axiosInstance.delete
  };
});
