import { defineStore } from "pinia";
import { useEtnaTrading } from "./etna-trading.js";
import { computed, ref } from "vue";

export const useEtnaAccountBalances = defineStore("etna-account-balances", () => {
  const trading = useEtnaTrading();

  /**
   * Liefert die Kontostand-Informationen für den aktuellen Account
   * @type {Object} AccountBalance
   * @property {number} cash
   * @property {number} changeAbsolute
   * @property {number} changePercent
   * @property {number} closePL
   * @property {number} dayTrades
   * @property {number} dayTradingBuyingPower
   * @property {number} equityTotal
   * @property {number} excess
   * @property {number} maintenanceMargin
   * @property {number} marketValue
   * @property {number} netCash
   * @property {number} netLiquidity
   * @property {number} openPL
   * @property {number} openPLDay
   * @property {number} openPLPercent
   * @property {number} optionBuyingPower
   * @property {number} optionLongMarketValue
   * @property {number} optionMaintenanceMargin
   * @property {number} optionShortMarketValue
   * @property {number} pendingCash
   * @property {number} pendingOrdersCount
   * @property {number} stockBuyingPower
   * @property {number} stockLongMarketValue
   * @property {number} stockShortMarketValue
   * @property {number} totalPL
   */
  const current = computed(() => accountBalances.value[trading.accountId] ?? null);

  // Die Kontostand-Informationen für alle geladenen Accounts
  const accountBalances = ref({});

  /**
   * Available Buying Power für den aktuellen Account berechnen
   */
  const currentBuyingPower = computed(() => {
    // Nur berechnen, wenn es einen aktiven Account gibt
    if (!current.value) return null;

    // equityTotal + totalPL - (| stockLongMarketValue/2 | + | stockShortMarketValue/2 | )
    return (
      current.value.equityTotal +
      current.value.totalPL -
      (Math.abs(current.value.stockLongMarketValue * 0.5) +
        Math.abs(current.value.stockShortMarketValue * 0.5))
    );
  });

  /**
   * Funktion die beim Start aufgerufen wird, um alle Informationen zu sammeln und im lokalen Store zu speichern
   * @returns {Promise<void>}
   */
  async function boot(accountId) {
    // Websocket abonnieren
    await trading.subscribeTo("AccountBalance", accountId);

    // Daten direkt vom Server laden
    loadAccountBalance(accountId);
  }

  /**
   * Funktion die beim Beenden aufgerufen wird, um Subscriptions zu beenden und den lokalen Store zu leeren
   * @returns {Promise<void>}
   */
  async function shutdown(accountId) {
    // Websocket de-abonnieren
    await trading.unsubscribeFrom("AccountBalance", accountId);
  }

  /**
   * Lädt die Account-Balance Informationen für einen Account vom Server
   * @param accountId
   */
  function loadAccountBalance(accountId) {
    // Verfügbarkeit des Objekts sicherstellen
    ensureObjectIsAvailable(accountId);

    // Orders vom Server laden
    trading
      .getAxios()
      .get("/accounts/" + accountId + "/info")
      .then((res) => {
        for (const [name, value] of Object.entries(res.data)) {
          accountBalances.value[accountId][name] = value;
        }
      });
  }

  /**
   * Bearbeitet eine Websocket-Nachricht
   * @param message
   */
  function handleMessage(message) {
    // Verfügbarkeit des Objekts sicherstellen
    ensureObjectIsAvailable(message.AccountId);

    // Alle Items durchlaufen
    JSON.parse(message.Items).forEach((item) => {
      accountBalances.value[message.AccountId][item.Name] = item.Value;
    });
  }

  /**
   * Prüft, dass im Store ein Objekt für die entsprechende Account-ID vorhanden ist
   * @param accountId
   */
  function ensureObjectIsAvailable(accountId) {
    // Neues Objekt anlegen, falls für diesen Account noch keine Daten erhoben wurden
    if (accountBalances.value[accountId] == null) {
      accountBalances.value[accountId] = {};
    }
  }

  return {
    boot,
    shutdown,
    accountBalances,
    handleMessage,
    currentBuyingPower,
    current
  };
});
