import Pusher, { Channel } from "pusher-js";
import { defineStore } from "pinia";
import { useAxios } from "./axios.ts";
import _ from "lodash";
import { PolygonSymbol } from "../shared/types/Polygon.ts";
import { useSettings } from "./settings.ts";
import { Module, useStatus } from "./status.ts";

export const useWebsocket = defineStore("websocket", () => {
  /** Speichert die Pusher-Instanz */
  let pusherInstance: Pusher = null;

  /** App-Status */
  const status = useStatus();

  /** Aktive Channels */
  const channels: { [key: string]: Channel } = {};

  const getChannel = async (channelName: string) => {
    // Prüfen, ob Channel bereits abonniert ist
    if (channels[channelName] == null) {
      console.info(
        `[Websocket] Channel ${channelName} wurde angefordert, aber Channel ist nicht abonniert. Channel wird abonniert…`
      );
      await subscribeToChannel(channelName);
    }

    return channels[channelName];
  };

  /**
   * Abonniert einen Channel im Websocket
   * @param channel
   */
  async function subscribeToChannel(channelName: string) {
    // Prüfen, ob Pusher-Instanz vorhanden ist
    if (pusherInstance === null) {
      console.info(
        `[Websocket] Subscribe für ${channelName} wurde angefordert, aber Verbindung ist nicht vorhanden. Fordere Verbindung an…`
      );
      await connect();
    }

    // Channel abonnieren und speichern
    channels[channelName] = pusherInstance.subscribe("private-" + channelName);

    // Event, wenn Subscription erfolgreich war, für Logging binden
    channels[channelName].bind("pusher:subscription_succeeded", function () {
      console.info(`[Websocket] Subscription für ${channelName} erfolgreich.`);
    });

    // Event, falls Subscription NICHT erfolgreich war, für Logging binden
    channels[channelName].bind("pusher:subscription_error", function () {
      console.error(`[Websocket] Subscription für ${channelName} fehlgeschlagen.`);
    });

    // Channel zurückgeben
    return channels[channelName];
  }

  /**
   * Abonniert einen Channel im Websocket
   * @param channel
   */
  async function unsubscribeFromChannel(channel: string) {
    // Prüfen, ob Pusher-Instanz vorhanden ist
    if (pusherInstance === null) {
      console.warn(
        "[Websocket] Unsubscribe wurde angefordert, aber Verbindung ist nicht vorhanden. Verbindung wird aufgebaut..."
      );
      await connect();
    }

    // Channel de-abonnieren und speichern
    pusherInstance.unsubscribe("private-" + channel);
    channels[channel] = null;

    return true;
  }

  function getChannelNameForSymbol(symbol: PolygonSymbol): string {
    return `TradingApp.Stockdata.FMV.${symbol}`;
  }

  /**
   * Verbindet den Store mit dem Websocket und speichert die Instanz
   */
  async function connect() {
    // Log-Ausgabe
    console.log("[Websocket] Verbindung wird aufgebaut...");

    // Status-Flag setzen
    status.setLoading(Module.websocket);

    // Log-To-Console aktivieren, wenn Debug-Modus aktiv ist
    const settings = useSettings();
    Pusher.logToConsole = settings.local.enablePusherDebugMode;

    // Pusher-Instanz erstellen

    pusherInstance = new Pusher(
      import.meta.env.VITE_PUSHER_KEY,

      {
        authEndpoint: import.meta.env.VITE_BASE_URL + "/broadcasting/auth",
        cluster: "eu",

        wsHost: _.sample(import.meta.env.VITE_PUSHER_HOSTS?.split(",") ?? null),
        wsPort: 6002,
        wssPort: 6002,
        forceTLS: true,
        enableStats: false,
        enabledTransports: ["ws", "wss"],

        authorizer: websocketAuthorizer
      }
    );

    // Status überwachen
    pusherInstance.connection.bind("state_change", function (states) {
      const state = states.current;

      if (state === "connecting") {
        status.setLoading(Module.websocket);
      } else if (state === "connected") {
        status.setReady(Module.websocket, pusherInstance.connection.state);
      } else {
        status.setError(Module.websocket, pusherInstance.connection.state);
      }
    });
  }

  /**
   * Diese Methode prüft, ob der Websocket sich mit dem übergebenen Channel verbinden darf
   */
  const websocketAuthorizer = function (channel) {
    return {
      authorize: (socketId, callback) => {
        const { post } = useAxios();
        post("/broadcasting/auth", {
          socket_id: socketId,
          channel_name: channel.name
        })
          .then((response) => {
            callback(false as unknown as Error, response.data);
          })
          .catch((error) => {
            status.setError(Module.websocket, error.message);
            callback(true as unknown as Error, error);
          });
      }
    };
  };

  return {
    connect,
    getChannel,
    unsubscribeFromChannel,
    getChannelNameForSymbol
  };
});
